import { Box, IconButton, Grid, Table, TableCell, TableBody, TableHead, TableRow, Switch, FormControlLabel, Button, Select, MenuItem, Stack } from '@mui/material'
import { useEffect, useState, useRef, Fragment } from 'react'
import NavHeader from '@/components/NavHeader'
import { updateSession, findOneSession } from '@/utils/Sessions'
import { useSelector } from 'react-redux'
import { useToasterContext } from '@/utils/ToasterContext'

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import { findManyUsers } from '@/utils/Users'
import { findManyClasses } from '@/utils/Classes'
import { LoadingButton } from '@mui/lab'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import validate from 'validate.js'
import PostPicker from '@/components/PostPicker'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import dayjs from 'dayjs'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import PageLoading from '@/components/PageLoading'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import NotifySession from './Components/NotifySession'
import Info from '@/components/Info'

dayjs.extend(customParseFormat);

const CreateSession = () => {

    const { token } = useSelector((state) => state.auth);
    const { id } = useParams();
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [defaultValue, setDefaultValue] = useState(false);
    const [pageInit, setPageInit] = useState(false);
    const [trainer, setTrainer] = useState(false);
    const [AcademicClass, setClass] = useState([]);
    const [users, setUsers] = useState([]);
    const [notify, setNotify] = useState(false);
    const [classTypes, setClassTypes] = useState([]);
    const [trainerOptions, setTrainerOptions] = useState([]);
    const [selectedTrainer, setSelectedTrainer] = useState(false);
    const [step, setStep] = useState(0);

    const formRef = useRef();
    
    const navigate = useNavigate();

    useEffect(() => {
        const step = searchParams.get("step");
        console.log('step', step)
        if(step){
            setStep(step);
        }
    }, [])

    async function handleFindOne() {
        try {
            const response = await findOneSession(token, id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const {
                        capacity,
                        trainer,
                        start_time,
                        end_time,
                        users,
                        is_private,
                        is_free_session,
                        class_types,
                        trainer_id
                    } = data;
                    const academicClass = data?.class;
                    if(Array.isArray(academicClass?.trainers) && academicClass?.trainers?.length){
                        setTrainerOptions(academicClass?.trainers || []);
                        setSelectedTrainer(trainer_id);
                    }
                    setClassTypes(JSON.parse(academicClass?.class_types) || []);
                    setClass(academicClass);
                    setTrainer(trainer);
                    setUsers(users);
                    setDefaultValue({
                        capacity,
                        start_time,
                        end_time,
                        is_private,
                        is_free_session,
                        class_types
                    });
                }
            }
        } catch (error) {
            console.error('error', error);
        } finally {
            setPageInit(true)
        }
    }

    async function handleUpdate(token, id, data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await updateSession(token, id, data);
            const sent_notify = data?.sent_notify;
            if (response && !response.error) {
                const { data } = response;
                if(data){
                    showMessage('success', 'Data updated successfully!');
                    if(sent_notify){
                        showMessage('success', 'The email has been sent successfully to all users and trainers!');
                    }
                } else {
                    showMessage('error', 'An error occurred, please try again later!');
                }
            } else {
                showMessage('error', 'An error occurred, please try again later!');
            }
        } catch (error) {
            showMessage('error', 'An error occurred, please try again later!');
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    function handleChangeClass(value){
        const { trainers, class_types } = value;
        setClassTypes(JSON.parse(class_types) || []);
        setTrainerOptions(trainers);
        setClass(value)
    }

    function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const user_ids = users.map((val) => val.id);
        const schedule_date = dayjs(data.get('schedule'), "DD/MM/YYYY").format('YYYY-MM-DD');
        
        const start_time = dayjs(data.get('start_time'), 'HH:mm').format("HH:mm:ss");
        const end_time = dayjs(data.get('end_time'), 'HH:mm').format("HH:mm:ss");
        const duration_minutes  = dayjs(data.get('end_time'), 'hh:mm').diff(dayjs(data.get('start_time'), 'hh:mm'), 'minute');

        const notify = data.get('sent_notify');
        const is_free_session = data.get('is_free_session');
        const capacity = data.get('capacity');
        const is_private = data.get('is_private');
        const class_types = data.get('class_types');
        const schedule = data.get('schedule');
        
        const validateForm = {
            trainer_id: selectedTrainer,
            academic_class_id: AcademicClass?.id,
            capacity: capacity,
            schedule: schedule_date,
            start_time: start_time,
            end_time: end_time,
        };

        const dataForm = {
            trainer_id: selectedTrainer,
            academic_class_id: AcademicClass?.id,
            capacity: capacity,
            schedule: schedule_date,
            start_time: start_time,
            end_time: end_time,
            user_ids: user_ids,
            class_types: class_types,
            duration_minutes: duration_minutes,
            is_private: is_private == 'on' ? 1 : 0,
            sent_notify: notify == 'on' ? 1 : 0,
            is_free_session: is_free_session == 'on' ? 1 : 0
        }

        try {
            const error = validate(validateForm, constraints);
            if (!error) {
                handleUpdate(token, id, dataForm);
            } else {
                showMessage("error", "One or more input fields are invalid!")
            }
            setFormError(error);
        } catch (error) {

        }
    }

    function handleChangeClassType(e) {
        const form = formRef.current;
        const selectedClassType = classTypes.find((val) => val.title == e.target.value);
        form.elements['capacity'].value = selectedClassType?.capacity;
    }

    // Custom validator to ensure end_time is after start_time
    validate.validators.timeAfter = function (value, options, key, attributes) {
        const startTime = parseTime(attributes.start_time);
        const endTime = parseTime(value);
        if (startTime && endTime && endTime <= startTime) {
            return options.message || "must be after start time";
        }
    };

    // Helper function to parse time in hh:mm AM/PM format to Date object
    function parseTime(timeString) {
        const [time, modifier] = timeString.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        if (modifier === 'PM' && hours < 12) {
            hours += 12;
        }
        if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }

        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
    }


    useEffect(() => {
        if(AcademicClass){
            const { is_private, class_types, capacity } = AcademicClass;
            setClassTypes(class_types ? JSON.parse(class_types) : []);
            setDefaultValue((defaultValue) => ({
                ...defaultValue,
                is_private: is_private,
                capacity: capacity}))
        }
    }, [AcademicClass])

    useEffect(() => {
        if(token){
            handleFindOne();
        }
    }, [token])

    const constraints = {
        // trainer_id: {
        //     presence: { allowEmpty: false, message: "is required" }
        // },
        // academic_class_id: {
        //     presence: { allowEmpty: false, message: "is required" }
        // },
        // schedule: {
        //     presence: { allowEmpty: false, message: "is required" }
        // },
        // capacity: {
        //     presence: { allowEmpty: false, message: "is required" }
        // },
        // start_time: {
        //     presence: { allowEmpty: false, message: "is required" },
        //     format: {
        //         pattern: /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/,
        //         message: "must be in the format hh:mm AM/PM"
        //     }
        // },
        // end_time: {
        //     presence: { allowEmpty: false, message: "is required" },
        //     format: {
        //         pattern: /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/,
        //         message: "must be in the format hh:mm AM/PM"
        //     },
        //     timeAfter: {
        //         message: "must be after start time"
        //     }
        // }
    };

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    if (!pageInit) {
        return (
            <PageLoading />
        );
    }

    return (
        <Fragment>
            <Box sx={{ width: '100%' }}>
                <NavHeader
                    title="Update Session"
                    breadcrumb={[
                        {
                            title: 'Sessions',
                            slug: '/admin/session'
                        },
                        {
                            title: 'Update Session'
                        }
                    ]}
                    endAction={
                       <Stack direction="row" spacing={1}>
                         <Button 
                            variant='outlined'
                            onClick={() => navigate(`/admin/sessions/create?schedule=${dayjs(defaultValue?.start_time).format("DD/MM")}`)}>
                            Create another
                        </Button>
                        <Button 
                            variant='contained'
                            onClick={() => navigate(`/admin/sessions/${id}/checkin`)}>
                            Start to checkin
                        </Button>
                       </Stack>
                    }
                    backLink={"/admin/sessions?step=" + step}
                />
                <Box ref={formRef} component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={8}>
                            <PostPicker
                                label="Class"
                                name="academic_class_id"
                                defaultData={AcademicClass}
                                getApi={findManyClasses}
                                value={AcademicClass}
                                onChange={(value) => handleChangeClass(value)}
                                error={formError?.academic_class_id}
                                optionKey="title"
                                required
                            />
                            {
                                Array.isArray(classTypes) && classTypes.length ? 
                                    <FormControl fullWidth sx={{mb: 3}}>
                                        <FormLabel>Class Type</FormLabel>
                                        <Select
                                            name="class_types"
                                            defaultValue={defaultValue?.class_types}
                                            onChange={(e) => handleChangeClassType(e)}>
                                            {
                                                classTypes.map((val, index) => {
                                                    return (
                                                        <MenuItem key={index} value={val.title}>
                                                            {val.title}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                            : ""
                            }
                                          {
                                Array.isArray(trainerOptions) && trainerOptions.length ? 
                                (
                                    <FormControl sx={{ width: "100%", mb: 3 }}>
                                        <FormLabel component="legend">Trainer</FormLabel>
                                        <Select
                                            name="trainer"
                                            value={selectedTrainer}
                                            onChange={(e) => setSelectedTrainer(e.target.value)}
                                            fullWidth
                                        >
                                            {
                                                trainerOptions.map((val) => {
                                                    return (
                                                        <MenuItem key={val.id} value={val.id}>{val.full_name}</MenuItem>
                                                    )
                                                })
                                            }
                                    </Select>
                                </FormControl>
                                ) : ""
                            }
                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                <FormControlLabel
                                    control={<Switch />}
                                    label="Private session"
                                    checked={defaultValue?.is_private}
                                    onChange={() => setDefaultValue((defaultValue) => ({...defaultValue, is_private: !defaultValue.is_private}))}
                                    name="is_private"
                                />
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.schedule} required>
                                <FormLabel>Schedule</FormLabel>
                                <DatePicker
                                    name="schedule"
                                    value={dayjs(defaultValue?.start_time, "YYYY-MM-DD HH:mm:ss")}
                                />
                                {formError?.schedule ? <FormHelperText>{formError?.schedule[0]}</FormHelperText> : ""}
                            </FormControl>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.start_time} required>
                                        <FormLabel>Start hour</FormLabel>
                                        <TimePicker
                                            name="start_time"
                                            value={dayjs(defaultValue?.start_time, "YYYY-MM-DD HH:mm:ss")}
                                        />
                                        {formError?.start_time ? <FormHelperText>{formError?.start_time[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.end_time} required>
                                        <FormLabel>End hour</FormLabel>
                                        <TimePicker
                                            name="end_time"
                                            value={dayjs(defaultValue?.end_time, "YYYY-MM-DD HH:mm:ss")}
                                        />
                                        {formError?.end_time ? <FormHelperText>{formError?.end_time[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.title} required>
                                <FormLabel>Capacity</FormLabel>
                                <TextField
                                    name="capacity"
                                    type="number"
                                    defaultValue={defaultValue?.capacity}
                                    error={formError?.capacity}
                                />
                                {formError?.capacity ? <FormHelperText>{formError?.capacity[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                <FormControlLabel
                                    control={<Switch />}
                                    label={
                                        <Box>
                                            This session is provided free of charge to registered users <Info title="Users who do not need a subscription can still register" />
                                        </Box>
                                    }
                                    checked={defaultValue?.is_free_session}
                                    onChange={() => setDefaultValue((defaultValue) => ({...defaultValue, is_free_session: !defaultValue.is_free_session}))}
                                    name="is_free_session"
                                />
                            </FormControl>
                            <Box sx={{ mb: 1 }}>
                                <PostPicker
                                    label="Select Subcription"
                                    name="user"
                                    defaultData
                                    getApi={findManyUsers}
                                    values={users}
                                    onChange={(value) => {
                                        if (value) {
                                            setUsers((users) => {
                                                let filterUsers = users.filter((_v) => _v?.id !== value.id);
                                                filterUsers = [...filterUsers, value];
                                                return filterUsers;
                                            })
                                        }
                                    }}
                                    optionKey="email"
                                    previewKey="id"
                                />
                            </Box>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.title}>
                                <FormLabel>Select Users</FormLabel>
                                <Paper>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ width: 60 }}>No</TableCell>
                                                <TableCell>Users / Shared users</TableCell>
                                                <TableCell align="right">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Array.isArray(users) && users.length ?
                                                users.map((row, index) => {
                                                    const { pivot } = row;
                                                    return (
                                                        <StyledTableRow
                                                            key={row.id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {(index + 1)}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {pivot?.is_shared ? pivot?.name_shared : row.display_name || row?.email}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <IconButton onClick={() => setUsers((users) => users.filter((_v, _i) => _i !== index))}>
                                                                    <DeleteOutlineIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                        </StyledTableRow>
                                                    )
                                                }) : ""}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                <FormControlLabel
                                    control={<Switch />}
                                    label="Send an email to notify all users and trainers"
                                    name="sent_notify"
                                />
                            </FormControl>
                            <FormControl>
                                <LoadingButton
                                    variant='contained'
                                    color='primary'
                                    size='lg'
                                    type='submit'
                                    loading={isLoading}
                                >
                                    Update session
                                </LoadingButton>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <NotifySession open={notify} setOpen={setNotify} />
        </Fragment>
    )
}

export default CreateSession
