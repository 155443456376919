import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToasterContext } from '@/utils/ToasterContext';
import { findManyUserSessions } from '@/utils/UserInfomations';
import { useSelector } from 'react-redux';
import { Button, Stack } from '@mui/material';

import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import PageLoading from '@/components/PageLoading';
import WeekView from './WeekView';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';

const UserSessions = () => {

    const navigate = useNavigate();

    const { token, user, status } = useSelector((state) => state.auth);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const { t } = useTranslation();

    const [pageStatus, setPageStatus] = useState('idle');
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [step, setStep] = useState(0);

    const [filter, setFilter] = useState({
        search: "",
        order: "desc",
        paged: 1,
        per_page: 12,
        start_time: false,
        end_time: false,
    });

    const handleFindManyData = async (token, filter) => {
        try {
            startProgress();
            setPageStatus("idle");
            const response = await findManyUserSessions(token, filter);
            if (response && !response.error) {
                const { data, meta } = response;
                setData(data || []);
                setTotal(meta?.total || 0);
            }
            setPageStatus("complete");
        } catch (error) {
            console.error('error', error);
        } finally {
            completeProgress();
            setPageStatus("complete")
        }
    }

    useEffect(() => {
        if (filter?.start_time) {
            handleFindManyData(token, filter);
        }
    }, [filter])

    useEffect(() => {
        const start_time = dayjs().add(step, "week").startOf('week').format('YYYY-MM-DD HH:mm:ss');
        const end_time = dayjs().add(step, "week").endOf('week').format('YYYY-MM-DD HH:mm:ss');
        setFilter({
            ...filter,
            start_time: start_time,
            end_time: end_time
        });
    }, [step])


    if(!token){
        navigate("/login")
    }

    if (pageStatus != 'complete') {
        return (
            <PageLoading height={320} />
        )
    }

    return (
        <Box>
            <Box sx={{ py: 2 }}>
                <h1 className='text-center font-bold uppercase text-3xl mb-3'>
                    {t("Your Sessions")}
                </h1>
                <Stack direction="row" justifyContent="space-between" spacing={3}>
                    <Button
                        onClick={() => setStep((step) => step - 1)}
                        variant='outlined'
                        sx={{
                            borderRadius: 0,
                            letterSpacing: 2,
                            alignItems: "center",
                            fontWeight: 600,
                            display: "inline-flex"
                        }}
                        color="secondary">
                        {t("Prev week")}
                    </Button>
                    <Button
                        onClick={() => setStep((step) => step + 1)}
                        variant='outlined'
                        sx={{
                            borderRadius: 0,
                            fontWeight: 600,
                            letterSpacing: 2
                        }}
                        color="secondary">
                        {t("Next week")}
                    </Button>
                </Stack>
            </Box>
            <WeekView data={data} step={step}/>
        </Box>
    )
}

export default UserSessions
