import { Box, Typography, TableCell, Stack, Paper, TableContainer, Table, TableBody, TableRow, Avatar, TableHead, Button, Collapse, Card } from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import { findOneSession, TrainerCheckInUser, TrainerCheckOutUser } from '@/utils/TrainerSessions'
import { useSelector } from 'react-redux'
import { useToasterContext } from '@/utils/ToasterContext'
import { Link, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab'

import PageLoading from '@/components/PageLoading'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import NavHeader from '@/components/NavHeader'
import { getInitials } from '@/utils/Hooks'

dayjs.extend(customParseFormat);

const CheckinSession = () => {

    const { token } = useSelector((state) => state.auth);
    const { id } = useParams();

    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);

    const [defaultValue, setDefaultValue] = useState(false);
    const [trainer, setTrainer] = useState([]);
    const [AcademicClass, setClass] = useState([]);
    const [users, setUsers] = useState([]);

    async function handleFindOneSession() {
        try {
            startProgress();
            const response = await findOneSession(token, id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const {
                        title,
                        description,
                        capacity,
                        trainer,
                        start_time,
                        end_time,
                        users
                    } = data;
                    const academicClass = data?.class;
                    setClass(academicClass);
                    setTrainer(trainer);
                    setUsers(users);
                    setDefaultValue({
                        title: title,
                        description: description,
                        capacity: capacity,
                        start_time: start_time,
                        end_time: end_time,
                        class: academicClass
                    });
                }
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            completeProgress();
        }
    }

    const handleCheckInUser = async (id) => {
        try {
            setIsLoading(true);
            const response = await TrainerCheckInUser(token, id);
            if (response && !response.error) {
                showMessage('success', 'Successfully checkin the session!');
                handleFindOneSession();
            }
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    }

    const handleCheckOutUser = async (id) => {
        try {
            setIsLoading(true);
            const response = await TrainerCheckOutUser(token, id);
            if (response && !response.error) {
                showMessage('success', 'Successfully checkout the session!');
                handleFindOneSession();
            }
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (token) {
            handleFindOneSession();
        }
    }, [token])

    if (!defaultValue) {
        return (
            <PageLoading />
        );
    }

    const headCells = [
        {
            id: 'user_info',
            numeric: false,
            disablePadding: false,
            label: 'User Info',
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: false,
            label: 'Email',
        },
        {
            id: 'phone_number',
            numeric: false,
            disablePadding: false,
            label: 'Phone number',
        },
        {
            id: 'check_in',
            numeric: false,
            disablePadding: false,
            label: 'Check in',
        },
        {
            id: 'check_out',
            numeric: false,
            disablePadding: false,
            label: 'Check out',
        },
        {
            id: 'action',
            numeric: false,
            disablePadding: false,
            label: 'Action',
        },
    ];

    function EnhancedTableHead(props) {
        const { order, orderBy } = props;
        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <Typography sx={{ fontWeight: 600 }}>
                                {headCell.label}
                            </Typography>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    const RowData = ({ rowData }) => {
        const [open, setOpen] = useState(false);
        const { email, subscription, pivot, display_name, membership } = rowData;
        return (
            <Fragment>
                <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    sx={{ cursor: 'pointer' }}>
                    <TableCell>
                        <Link to={"/admin/users/" + rowData?.id}>
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center">
                                <Avatar       
                                    sx={{
                                        bgcolor: "#000",
                                    }} 
                                    children={getInitials(pivot?.is_shared ? pivot?.name_shared : display_name || rowData?.email)} />
                                <Typography>
                                    {pivot?.is_shared ? pivot?.name_shared : display_name || rowData?.email}
                                </Typography>
                            </Stack>
                        </Link>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {rowData?.email || "-"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <a href={`tel:${rowData?.phone}`}>
                            <Typography>
                                {rowData?.phone || "-"}
                            </Typography>
                        </a>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {pivot?.check_in_time ? dayjs(pivot.check_in_time).format("hh:mm:ss, DD/MM/YYYY") : "-"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {pivot?.check_out_time ? dayjs(pivot.check_out_time).format("hh:mm:ss, DD/MM/YYYY") : "-"}
                        </Typography>
                    </TableCell>
                    <TableCell>
                       <Button 
                            sx={{borderRadius: 0}}
                            variant={open ? "contained" : "outlined"} 
                            onClick={() => setOpen(!open)}>
                            View
                       </Button>
                    </TableCell>
                </TableRow>
                <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography
                                    gutterBottom
                                    sx={{ fontWeight: 600 }}>
                                    Subscription detail
                                </Typography>
                                <Card variant='outlined' sx={{ mb: 3 }}>
                                    <Table
                                        size="small"
                                        aria-label="purchases"
                                        sx={{
                                            ".MuiTableCell-head": {
                                                background: "whitesmoke"
                                            },
                                            ".MuiTableCell-root": {
                                                border: "unset",

                                            }
                                        }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography sx={{ fontWeight: 600 }}>
                                                       Membership
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography sx={{ fontWeight: 600 }}>
                                                       Sessions
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography sx={{ fontWeight: 600 }}>
                                                        Status
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{textAlign: "right"}}>
                                                    <Typography sx={{ fontWeight: 600 }}>
                                                        Action
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={{ border: "unset" }} component="th" scope="row">
                                                    {
                                                        membership ? (
                                                            <Link to={"/admin/memberships/" + membership?.id}>
                                                                <Typography sx={{fontWeight: 600}}>
                                                                    {membership?.title}
                                                                </Typography>
                                                            </Link>
                                                        ): ("-")
                                                    }
                                                </TableCell>
                                                <TableCell sx={{ border: "unset" }} component="th" scope="row">
                                                    <Typography>
                                                        {subscription ? subscription?.max_sessions == "-1" ? "Unlimited" : `${subscription?.max_sessions - subscription?.sessions_used}/${subscription?.max_sessions}` : "-"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {subscription?.status}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell sx={{textAlign: "right"}}>
                                                    <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                                        <LoadingButton
                                                            disabled={pivot?.check_in_time}
                                                            loading={isLoading == `check_in_${rowData.id}`}
                                                            sx={{borderRadius: 0}}
                                                            onClick={() => {
                                                                if (pivot?.check_in_time) {
                                                                    'This user has checked out!'
                                                                } else {
                                                                    handleCheckInUser(pivot.id)
                                                                }
                                                            }} variant='contained' color="secondary">
                                                            Check-in
                                                        </LoadingButton>
                                                        <LoadingButton
                                                            disabled={pivot?.check_out_time}
                                                            loading={isLoading == `check_out_${rowData.id}`}
                                                            sx={{borderRadius: 0}}
                                                            onClick={() => {
                                                                if (pivot?.check_out_time) {
                                                                    showMessage('error', 'This user has checked out!')
                                                                } else {
                                                                    handleCheckOutUser(pivot.id)
                                                                }
                                                            }} variant='outlined' color="secondary">
                                                            Check-Out
                                                        </LoadingButton>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Card>
                            </Box>
                    </Collapse>
                </TableCell>
                </TableRow>
            </Fragment>
        )
    }

    return (
        <Box sx={{ width: '100%' }}>
            <NavHeader
                title="Session checkin"
                breadcrumb={[
                    {
                        title: 'Session checkin'
                    }
                ]}
                backLink={`/admin/sessions/${id}`}
            />
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size='medium'>
                        <EnhancedTableHead
                            rowCount={users?.length}
                        />
                        <TableBody>
                            {users.map((row) => {
                                return (
                                    <RowData rowData={row}/>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    )
}

export default CheckinSession