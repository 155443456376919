import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';
import NavHeader from '@/components/NavHeader'
import MediaCard from '@/components/MediaCard';

import { deleteMedia, findManyMedia } from '@/utils/Media';
import { Grid, TablePagination } from '@mui/material';
import ConfirmDelete from '@/components/ConfirmDelete';
import { useSelector } from 'react-redux';
import { useToasterContext } from '@/utils/ToasterContext';

const index = () => {

    const [data, setData] = useState([]);
    const [deleteFile, setDeleteMedia] = useState(false);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const { showMessage} = useToasterContext();
    const [filter, setFilter] = useState({
        search: "",
        order: "desc",
        paged: 1,
        per_page: 12,
        start_date: null,
        end_date: null
    })
    
    const { token} = useSelector((state) => state.auth);

    const handleFindManyData = async (filter) => {
        try {
            const response = await findManyMedia(filter);
            if (response && !response.error) {
                const { data } = response;
                setData(data);
                setTotal(meta?.total);
            }
        } catch (error) {

        }
    }

    async function handleDeleteFile(fileId) {
        try {
            setIsLoading("delete");
            const response = await deleteMedia(token, fileId);
            if (response && !response.error) {
                showMessage("success", response?.message || "File deleted successfully");
            } else {
                showMessage("error", deleteFile?.message || "An error occurred. Please try again later!");
            }
        } catch (error) {
            showMessage("error", "An error occurred. Please try again later!");
        } finally {
            setDeleteMedia(false);
            setIsLoading(false);
            handleFindManyData(filter);
        }
    }

    const handleChangePage = (event, newPage) => {
        setFilter({
            ...filter,
            paged: newPage ? (newPage + 1) : 1
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setFilter({
            ...filter,
            per_page: parseInt(event.target.value),
            paged: 1
        });
    };

    useEffect(() => {
        handleFindManyData(filter);
    }, [])

    return (
        <Box sx={{ width: '100%' }}>
            <NavHeader
                title="Media"
                breadcrumb={[
                    {
                        title: 'Media'
                    }
                ]}
                endAction={(
                    <Link to="/admin/media/upload">
                        <Button
                            startIcon={<AddIcon />}
                            variant='contained'
                            color='primary'>
                            Upload media
                        </Button>
                    </Link>
                )}
            />

            <Box>
                <Grid container spacing={3}>
                    {
                        Array.isArray(data) && data.length ?
                            data.map((val) => {
                                return (
                                    <Grid item key={val.id} xs={6} lg={3}>
                                        <MediaCard data={val} setDeleteMedia={setDeleteMedia}/>
                                    </Grid>
                                )
                            })
                            : ""
                    }
                </Grid>
                <TablePagination
                        rowsPerPageOptions={[12, 24, 36]}
                        component="div"
                        count={total || 0}
                        rowsPerPage={parseInt(filter?.per_page) || 12}
                        page={parseInt(filter?.paged - 1)}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
            </Box>
            <ConfirmDelete 
                open={deleteFile} 
                setOpen={setDeleteMedia} 
                isLoading={isLoading} 
                onSubmit={() => handleDeleteFile(deleteFile)}
            />
        </Box>
    )
}

export default index
