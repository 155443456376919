import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const PageLoading = ({ height }) => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: "center",
            alignItems: "center",
            height: height ? height : "100vh"
        }}>
            <CircularProgress />
        </Box>
    )
}

export default PageLoading
