import { Box, Grid } from '@mui/material'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useToasterContext } from '@/utils/ToasterContext'

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete'
import validate from 'validate.js'
import PageLoading from '@/components/PageLoading'

import { LoadingButton } from '@mui/lab'
import { findUserInformation, updateUserInformation } from '@/utils/UserInfomations';
import { Countries } from '@/utils/Countries';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const UpdateAccountInfomation = () => {

    const { t } = useTranslation();
    const { token } = useSelector((state) => state.auth);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [defaultValue, setDefaultValue] = useState(false);
    const [pageInit, setPageInit] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [pageStatus, setPageStatus] = useState(false);

    async function handleFindUserInfo() {
        try {
            startProgress()
            const response = await findUserInformation(token);
            console.log('response', response);
            if (response && !response.error) {
                const { data } = response;
                setDefaultValue(data) 
            }
        } catch (error) {
            console.error('error', error);
        } finally {
            completeProgress();
            setPageInit("complete")
        }
    }

    async function handleUpdateUser(data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await updateUserInformation(token, data);
            if (response && !response.error) {
                showMessage('success', 'Data updated successfully!');
                location.reload();
            }
        } catch (error) {
            console.error("error", error);
            showMessage('error', 'An error occurred, please try again later');
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const dataForm = {
            first_name: data.get('first_name'),
            last_name: data.get('last_name'),
            date_of_birth: dayjs(data.get('date_of_birth'), 'DD/MM/YYYY').format("YYYY-MM-DD"),
            billing_phone: data.get('billing_phone'),
            billing_email: data.get('billing_email'),
            billing_country: data.get('billing_country'),
            billing_city: data.get('billing_city'),
            billing_district: data.get('billing_district'),
            billing_ward: data.get('billing_ward'),
            billing_address: data.get('billing_address'),
        }

        const error = validate(dataForm, constraints);

        if (!error) {
            handleUpdateUser(dataForm);
        }
        setFormError(error);
    }

    useEffect(() => {
        async function initData() {
            await handleFindUserInfo();
            setPageStatus("complete")
        }
        if (token) {
            initData();
        }
    }, [token])

    const constraints = {
        first_name: {
            presence: { 
                allowEmpty: false,
                message: t('^Your first name cannot be blank')
            },
        },
        last_name: {
            presence: { 
                allowEmpty: false,
                message: t('^Your last name cannot be blank')
            },
        },
        date_of_birth: {
            presence: { 
                allowEmpty: false
            },
        },
        billing_phone: {
            presence: { 
                allowEmpty: false,
                message: t('^Your phone number cannot be blank')
            },
        },
        billing_email: {
            presence: { 
                allowEmpty: false, 
                message: t('^Your email address cannot be blank')
            },
            email: true
        },
    };

    if (pageInit != "complete") {
        return (
            <PageLoading />
        );
    }

    return (
            <Box sx={{ width: '100%' }}>
                <Box component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={8}>
                            <Typography sx={{ textTransform: "uppercase", fontWeight: 600, mb: 2 }}>
                                {t("Billing information")}
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.first_name} required>
                                        <FormLabel>{t("First name")}</FormLabel>
                                        <TextField
                                            name="first_name"
                                            error={formError?.first_name}
                                            defaultValue={defaultValue?.first_name}
                                        />
                                        {formError?.first_name ? <FormHelperText>{formError?.first_name[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.last_name} required>
                                        <FormLabel>{t("Last name")}</FormLabel>
                                        <TextField
                                            name="last_name"
                                            error={formError?.last_name}
                                            defaultValue={defaultValue?.last_name}
                                        />
                                        {formError?.last_name ? <FormHelperText>{formError?.last_name[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.date_of_birth}>
                                <FormLabel>{t("Date of birth")}</FormLabel>
                                <DatePicker
                                    name="date_of_birth"
                                    value={defaultValue?.date_of_birth ? dayjs(defaultValue?.date_of_birth): dayjs()}
                                />
                                {formError?.date_of_birth ? <FormHelperText>{formError?.date_of_birth[0]}</FormHelperText> : ""}
                            </FormControl>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_phone} required>
                                        <FormLabel>{t("Phone number")}</FormLabel>
                                        <TextField
                                            name="billing_phone"
                                            defaultValue={defaultValue?.billing_phone}
                                        />
                                        {formError?.billing_phone ? <FormHelperText>{formError?.billing_phone[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_email} required>
                                        <FormLabel>{t("Email address")}</FormLabel>
                                        <TextField
                                            name="billing_email"
                                            defaultValue={defaultValue?.billing_email}
                                        />
                                        {formError?.billing_email ? <FormHelperText>{formError?.billing_email[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_country}>
                                <FormLabel>{t("Country")}</FormLabel>
                                <Autocomplete
                                    options={Countries}
                                    name="billing_country"
                                    defaultValue={defaultValue?.billing_country}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                {...params}
                                                name="billing_country"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )
                                    }}
                                />
                                {formError?.billing_country ? <FormHelperText>{formError?.billing_country[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_city}>
                                <FormLabel>{t("City")}</FormLabel>
                                <TextField
                                    name="billing_city"
                                    defaultValue={defaultValue?.billing_city}
                                />
                                {formError?.billing_city ? <FormHelperText>{formError?.billing_city[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_district}>
                                <FormLabel>{t("District")}</FormLabel>
                                <TextField
                                    name="billing_district"
                                    defaultValue={defaultValue?.billing_district}
                                />
                                {formError?.billing_district ? <FormHelperText>{formError?.billing_district[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_ward}>
                                <FormLabel>{t("Ward")}</FormLabel>
                                <TextField
                                    name="billing_ward"
                                    defaultValue={defaultValue?.billing_ward}
                                />
                                {formError?.billing_ward ? <FormHelperText>{formError?.billing_ward[0]}</FormHelperText> : ""}
                            </FormControl>
  
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_address}>
                                <FormLabel>{t("Address")}</FormLabel>
                                <TextField
                                    name="billing_address"
                                    defaultValue={defaultValue?.billing_address}
                                />
                                {formError?.billing_address ? <FormHelperText>{formError?.billing_address[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl>
                                <LoadingButton
                                    variant='contained'
                                    color='primary'
                                    size='lg'
                                    type='submit'
                                    loading={isLoading}
                                >
                                    {t("Update account")}
                                </LoadingButton>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
    )
}

export default UpdateAccountInfomation
