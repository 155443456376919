import { useState, Fragment } from 'react';
import { alpha } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import { Avatar, Button } from '@mui/material';
import { getInitials, numberWithCommas } from '@/utils/Hooks';


const headCells = [
    {
        id: 'trainer',
        numeric: false,
        disablePadding: false,
        label: 'Instructor',
    },
    {
        id: 'latest-seen',
        numeric: false,
        disablePadding: false,
        label: 'Sessions',
    },
    {
        id: 'latest-seen',
        numeric: false,
        disablePadding: false,
        label: 'Total Bonus',
    },
    {
        id: 'Total hours',
        numeric: false,
        disablePadding: false,
        label: 'Total hours',
    },
    // {
    //     id: 'action',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Action',
    // },
];

const APP_URL = process.env.REACT_APP_APP_URL;

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = props;
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <Typography sx={{ fontWeight: 600 }}>
                            {headCell.label}
                        </Typography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <>
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {numSelected} selected
                    </Typography>
                    <Tooltip title="Delete">
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ) : ""
            }
        </Toolbar>
    );
}

export default function EnhancedTable({ data }) {

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [deleteItem, setDeleteItem] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [pageStatus, setPageStatus] = useState('idle');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;


    const Row = ({ rowData }) => {

        const {
            id,
            full_name,
            total_hours,
            thumbnail_url,
            total_sessions,
            total_bonus,
            total_bonus_hours
        } = rowData;

        const [open, setOpen] = useState(false);

        const isItemSelected = isSelected(id);

        return (
            <Fragment>
                <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}>
                    <TableCell>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Avatar 
                                sx={{
                                    bgcolor: "#000",
                                }} 
                                src={APP_URL + "/" + thumbnail_url}
                                children={getInitials(full_name)}
                            />
                            <Typography sx={{fontWeight: 600}}>
                                {full_name}
                            </Typography>
                        </Stack>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {total_sessions}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>
                            {numberWithCommas(total_bonus)}
                        </Typography>
                    </TableCell>
                    <TableCell sx={{fontWeight: 600}}>
                        <Typography>
                            {total_hours}
                        </Typography>
                    </TableCell>
                    {/* <TableCell sx={{fontWeight: 600}}>
                        <Button size='sm' variant='contained' sx={{borderRadius: 0}}>
                            Detail
                        </Button>
                    </TableCell> */}
                </TableRow>
            </Fragment>
        )
    }

    return (
        <Box sx={{ width: '100%' }}>
            <TableContainer sx={{ maxHeight: 400 }}>
                <Table
                    sx={{ minWidth: 750 }}
                    stickyHeader
                    aria-labelledby="tableTitle"
                    size='medium'>
                    <EnhancedTableHead
                        numSelected={selected.length}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={data?.length}
                    />
                    <TableBody>
                        {
                            Array.isArray(data) && data.length ? data.sort((a, b) => b.total_hours - a.total_hours).map((row) => {
                                    return (
                                        <Row key={row.id} rowData={row} />
                                    );
                                }) : ""
                        }
                    </TableBody>
                </Table>
                {
                    selected.length ? (
                        <EnhancedTableToolbar
                            numSelected={selected.length}
                            isLoading={deleteLoading}
                            onSubmit={() => handleDeleteMultipeData(selected)}
                        />
                    ) : ""
                }
            </TableContainer>
        </Box>
    );
}