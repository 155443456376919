import React, { useEffect, useState, Fragment } from 'react'
import { useToasterContext } from '@/utils/ToasterContext';
import { findOneTrainer } from '@/utils/UserTrainers';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { validate } from 'validate.js';
import { Box, Typography, Modal, Select, MenuItem, FormControl, InputLabel, Button, Stack, TextField, IconButton, FormControlLabel, Switch  } from '@mui/material'
import { createUserPrivateSessions, findManyUserSubscriptions } from '@/utils/UserInfomations';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import NavHeader from '@/components/NavHeaderHome';
import classNames from 'classnames';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';

dayjs.extend(customParseFormat);
dayjs.extend(require("dayjs/plugin/isBetween"));

const divTrainer = () => {

    const { t } = useTranslation();
    const { token } = useSelector((state) => state.auth);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(false);
    const [pageStatus, setPageStatus] = useState('idle');
    const { id, date } = useParams();
    const [schedule, setSchedule] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [formError, setFormError] = useState(false);
    const [selectedSchedule, setSelectedSchedule] = useState(false);
    const [selectedClass, setSelectedClass] = useState(null);
    const [classes, setClasses] = useState([]);

    const [selectedSubscription, setSelectedSubscription] = useState(false);

    const [shared, setShared] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [isOnlyShared, setIsOnlyShared] = useState(0);

    const navigate = useNavigate();
    const selectedDate = date ? dayjs(date, "DD-MM-YYYY") : null;

    function handleChangeSubscription(e){
        setShared([]);
        const selectedSubscription = subscriptions.find((val) => val.id == e.target.value);
        setSelectedSubscription(selectedSubscription);
    }

    function handleChangeShared(value, index) {
        const updated = shared.map((_v, _i) => index == _i ? value : _v);
        setShared(updated);
    }

    function handleDeleteShared(index) {
        const updated = shared.filter((_v, _i) => _i != index);
        setShared(updated);
    }

    function handleAddShared() {
        setShared((shared) => [...shared, ""]);
    }

    const handleFindManyUserSubscriptions = async (token, filter) => {
        try {
            const response = await findManyUserSubscriptions(token, {
                is_private: 1
            });
            if (response && !response.error) {
                const { data, meta } = response;
                if (Array.isArray(data)) {
                    setSubscriptions(data);
                } else {
                    setSubscriptions([]);
                }
            }
        } catch (error) {

        }
    }

    const handleFindData = async () => {
        try {
            startProgress();
            const response = await findOneTrainer(id);
            if (response && !response.error) {
                const { data } = response;
                if(data){
                    const { schedule, academic_classes } = data;
                    if(Array.isArray(academic_classes) && academic_classes.length) {
                        setClasses(academic_classes.filter((val) => val?.is_private == 1));
                    }
                    if (schedule) {
                        const Trainerschedule = JSON.parse(schedule);
                        setSchedule(Trainerschedule);
                    }
                    setData(data)
                }
            }
            setPageStatus("complete");
        } catch (error) {
            console.log('error', error);
        } finally {
            completeProgress();
        }
    }

    async function handleBookSession(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const formData = {
            subscription_id: selectedSubscription?.id,
            trainer_id: id,
            class_id: selectedClass.id,
            start_date: date,
            start_time: selectedSchedule.start_time,
            end_time: selectedSchedule.end_time,
            shared: shared.filter((val) => val != ''),
        }
        const error = validate(formData, createSessionConstraint);
        if (!error) {
            try {
                setIsLoading(true)
                const response = await createUserPrivateSessions(token, formData);
                if (response && !response.error) {
                    const { session } = response;
                    if (session) {
                        navigate("/account/sessions/" + session?.id);
                    }
                } else {
                    showMessage('error', t("An error occurred, please try later"));
                }
            } catch (error) {
                showMessage('error', t("An error occurred, please try later"));
            } finally {
                setIsLoading(false);
            }
        }
        setFormError(error);
    }

    useEffect(() => {
        handleFindData();
    }, [])

    useEffect(() => {
        console.log('selectedClass', selectedClass);
    }, [selectedClass])

    useEffect(() => {
        if (token) {
            handleFindManyUserSubscriptions(token, {});
        }
    }, [token])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 540,
        bgcolor: 'background.paper',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const createSessionConstraint = {
        subscription_id: {
            presence: true,
        }
    }

        // Tính toán trước số lượng `shared` hợp lệ để tránh lặp lại
        const validSharedLength = shared.filter((val) => val !== '').length;

        // Điều kiện tính toán cho `disabledSharedButton`
        const sharedCountAdjustment = isOnlyShared ? 0 : 1;
        const hasReachedCapacity = selectedClass?.capacity < ((shared?.length + 1) + sharedCountAdjustment);
        const hasUnlimitedSessions = selectedSubscription.max_sessions === "-1";
        const hasAvailableSessions = (selectedSubscription?.sessions_used + (shared?.length + 1) + 1) < selectedSubscription?.max_sessions;
    
        const disabledSharedButton = hasReachedCapacity
            ? true
            : hasUnlimitedSessions
                ? false
                : !hasAvailableSessions;
    
        // Tính `usageCounter` dựa trên giá trị `validSharedLength`
        const usageCounter = isOnlyShared ? validSharedLength : validSharedLength + 1;

    return (
        <Fragment>
            <NavHeader
                title={t("Book private session")}
                breadcrumb={[
                    {
                        title: t('Book private session')
                    }
                ]}
                backLink={`/book-trainer/${id}/`}
            />
             <div>
                    <Typography className="font-bold uppercase mb-3">{t("Select class")}</Typography>
                    {classes.length ? (
                        classes.map((cls) => (
                            <div key={cls.id}>
                                <div
                                    onClick={() => setSelectedClass(selectedClass?.id === cls.id ? null : cls)}
                                    className={classNames("p-3 border border-black cursor-pointer", { "bg-black": selectedClass?.id === cls.id })}
                                    style={{ flex: 1, borderColor: "black", borderWidth: 1, marginBottom: 15, borderRadius: 0 }}
                                >
                                    <Typography className={classNames("font-bold uppercase", { "text-white": selectedClass?.id === cls.id })}>
                                        {cls.title}
                                    </Typography>
                                </div>
                                {selectedClass?.id === cls.id && (
                                    <div>
                                        {schedule.length ? (
                                            schedule.map((sch, index) => {
                                                const start = dayjs(sch.start_time, "HH:mm:ss");
                                                const end = dayjs(sch.end_time, "HH:mm:ss");
                                                const durationInMinutes = end.diff(start, 'minute');
                                                if (durationInMinutes <= 0) return null;
                                                return (
                                                    <div key={index} className="flex flex-row space-x-3 border border-slate-200 p-3 bg-white mb-3">
                                                        <div style={{ flex: 1 }}>
                                                            <Typography className="font-normal mb-1">{t('with')} <strong className="uppercase font-bold">{data?.full_name}</strong></Typography>
                                                            <Typography className="font-bold mb-1">{selectedDate.format('ddd, DD/MM/YYYY')}</Typography>
                                                            <Typography className="font-bold mb-1">{start.format("HH:mm")} - {end.format("HH:mm")}</Typography>
                                                            <Typography className="font-normal mb-3">
                                                                {t('Duration')}: <strong>{durationInMinutes} {t("minutes")}</strong>
                                                            </Typography>
                                                            {
                                                                selectedClass?.capacity ? (
                                                                    <Typography sx={{ mb: 2 }}>
                                                                        {t("Remaining slots")}: <strong>{selectedClass.capacity}</strong> 
                                                                    </Typography>
                                                                ) : ""
                                                            }
                                                        </div>
                                                        <div>
                                                            <button
                                                                className='border border-black px-2 py-2 uppercase hover:bg-black hover:text-white duration-300 ease-in-out'
                                                                onClick={() => setSelectedSchedule(sch)}>
                                                                {t("Select")}
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <Typography className="font-normal text-center">{t("Unavailable schedule!")}</Typography>
                                        )}
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        <Typography className="font-normal text-center">{t("Unavailable session")}</Typography>
                    )}
                </div>
            <Modal
                open={selectedSchedule != ""}
                onClose={() => setSelectedSchedule(false)}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description">
                <Box sx={{
                    ...style,
                    width: 540,
                    maxWidth: "90vw"
                }}>
                    <Typography sx={{
                        fontWeight: 600,
                        fontSize: 24,
                        mb: 2,
                        textTransform: "uppercase"
                    }}>
                        Book a session
                    </Typography>
                    <form onSubmit={handleBookSession}>
                        {
                            !confirm ? (
                                <FormControl fullWidth error={formError?.subscription_id}>
                                    <InputLabel id="demo-simple-select-label">
                                        {t("Select subscription")}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        label="Select subscription"
                                        name="subscription_id"
                                        value={selectedSubscription?.id}
                                        onChange={(e) => handleChangeSubscription(e)}
                                        sx={{
                                            mb: 2,
                                            width: "100%"
                                        }}>
                                        {
                                            subscriptions.map((val) => {
                                                const validateDate = dayjs().isBetween(dayjs(val.start_date).startOf('day'), dayjs(val.end_date).endOf('day'));
                                                const validateClass = val.membership.is_all_class === 1 || val.membership.academic_classes.some((cls) => cls.id === selectedClass?.id);
                                                const validateUsage = val.max_sessions === -1 || val.sessions_used < val.max_sessions;
                                                const validatePrivate = val?.membership?.is_private;
                                                if (!validateDate || !validateClass || !validateUsage || !validatePrivate) return null;
                                                return (
                                                    <MenuItem
                                                        key={val.id}
                                                        sx={{ fontSize: 14 }}
                                                        value={val.id}>
                                                        <Box>
                                                            <Typography sx={{ fontWeight: 600 }}>
                                                                {val.membership.title} { val.membership?.can_be_share ? <span className='font-normal text-sm'>({t('Can be shared')})</span> : ""}
                                                            </Typography>
                                                            <Typography sx={{ fontSize: 12 }}>
                                                                {val.max_sessions == -1 ? t("unlimited")  : `${val.max_sessions - val.sessions_used} / ${val.max_sessions}`}
                                                            </Typography>
                                                        </Box>
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                    {formError?.subscription_id ? <FormHelperText>{formError?.subscription_id[0]}</FormHelperText> : ""}
                                    {
                                            selectedSubscription ? (
                                                <Fragment>
                                                    {
                                                        selectedSubscription?.membership?.can_be_share ? (
                                                            <Box>
                                                                <FormControl sx={{ mb: 2}}>
                                                                    <FormControlLabel
                                                                        control={<Switch />}
                                                                        checked={isOnlyShared}
                                                                        onChange={() => {
                                                                            setShared([]);
                                                                            setIsOnlyShared(!isOnlyShared)
                                                                        }}
                                                                        label={t("Book this session for someone else")} />
                                                                        <p className='text-sm'>{t("Select this item you will not be in the session list, book this session only for others, you will receive the room card via email, please share it with the person who receives it.")}</p>
                                                                </FormControl>
                                                                <h3 className='font-bold'>{t("Add shared person")}</h3>
                                                                <p className='text-sm'>{t("Add your friends and relatives to register for this class, for each person shared, you will use one of your class registration cards")}</p>
                                                                <Box>
                                                                    {
                                                                        Array.isArray(shared) && shared.length ? 
                                                                            shared.map((val, index) => {
                                                                                return (
                                                                                    <Stack direction="row" spacing={1} sx={{my: 2,}} key={index}>
                                                                                        <TextField 
                                                                                            label={t("Your shared name") + " (" + (index + 1) + ")"}
                                                                                            sx={{flex: 1}} 
                                                                                            value={val} 
                                                                                            onChange={(e) => handleChangeShared(e.target.value, index)}
                                                                                        />
                                                                                        <IconButton 
                                                                                            variant='outlined' 
                                                                                            sx={{ borderColor: "#000", borderRadius: 0 }} 
                                                                                            onClick={() => handleDeleteShared(index)}>
                                                                                                <DeleteOutlineIcon />
                                                                                        </IconButton>
                                                                                    </Stack>
                                                                                )
                                                                            }) : ""
                                                                    }
                                                                </Box>
                                                            <div className="text-end">
                                                                    <Button 
                                                                        variant='outlined'
                                                                        sx={{ borderColor: "#000", borderRadius: 0, mt: 1 }} 
                                                                        startIcon={<AddIcon />}
                                                                        disabled={disabledSharedButton}
                                                                        onClick={() => handleAddShared()}>
                                                                        {t("Add shared")}
                                                                    </Button>
                                                            </div>
                                                            </Box>
                                                        ) : ""
                                                    }
                                                </Fragment>
                                            ) : ""
                                        }
                                </FormControl>
                            ) : (
                                <Box>
                                    <Box sx={{ mb: 3}}>
                                        <div className="border p-3 border-black">
                                            <Typography sx={{ fontWeight: 600 }}>
                                                {selectedSubscription.membership.title} { selectedSubscription.membership?.can_be_share ? <span className='font-normal text-sm'>({t('Can be shared')})</span> : ""}
                                            </Typography>
                                            <Typography sx={{ fontSize: 12 }}>
                                                    {selectedSubscription.max_sessions == -1 ? `${t("unlimited")}`  : `${selectedSubscription.max_sessions - selectedSubscription.sessions_used} / ${selectedSubscription.max_sessions}`}
                                                </Typography>
                                        </div>
                                        {
                                            Array.isArray(shared) && shared.length ? 
                                                <Box sx={{ mt: 2, maxHeight: 360, overflowY: "auto", mb: 2 }}>
                                                    <h3 className='font-bold mb-0'>{t("Shared with person")}</h3>
                                                    {
                                                        shared.filter((val) => val != '').map((val, index) => {
                                                            return (
                                                                <Stack direction="row" spacing={1} key={index}>
                                                                    <Typography>{(index + 1)}. <strong>{val}</strong></Typography>
                                                                </Stack>
                                                            )
                                                        })
                                                    }
                                                </Box> : ""
                                        }
                                        <p>{t("Subscriptions changed")}: <strong className='ml-2'>-{ usageCounter}</strong></p>
                                    </Box>
                                    {
                                        selectedSubscription?.id ? (
                                            <Stack direction="row" spacing={1}>
                                                <Button 
                                                    onClick={() => setConfirm(false)} 
                                                    sx={{
                                                        flex: 1,
                                                        padding: "10px 12px",
                                                        borderRadius: 0,
                                                        display: "block",
                                                        mt: 2,
                                                        mb: 2,
                                                        textAlign: 'center',
                                                        letterSpacing: 2
                                                    }}
                                                    variant='outlined'>
                                                    {t("Back")}
                                                </Button>
                                                <LoadingButton
                                                    loading={isLoading}
                                                    variant='contained'
                                                    type='submit'
                                                    sx={{
                                                        background: "#000",
                                                        padding: "10px 12px",
                                                        flex: 1,
                                                        borderRadius: 0,
                                                        display: "block",
                                                        mt: 2,
                                                        mb: 2,
                                                        textAlign: 'center',
                                                        letterSpacing: 2
                                                    }}>
                                                    {t("Book session")}
                                                </LoadingButton>
                                            </Stack>
                                        ) : ""
                                    }
                                </Box>
                            )
                        }
                         {
                            selectedSubscription?.id && !confirm ? (
                                <Button
                                    variant='contained'
                                    type='button'
                                    onClick={() => { setConfirm(true) }}
                                    sx={{
                                        background: "#000",
                                        padding: "10px 12px",
                                        width: "100%",
                                        borderRadius: 0,
                                        display: "block",
                                        mt: 2,
                                        mb: 2,
                                        textAlign: 'center',
                                        letterSpacing: 2
                                    }}>
                                    {t("Confirm")}
                                </Button>
                            ) : ""
                        }
                    </form>
                </Box>
            </Modal>
        </Fragment>
    )
}

export default divTrainer
