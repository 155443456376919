import { Fragment } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FolderIcon from '@mui/icons-material/Folder';
import EditNoteIcon from '@mui/icons-material/EditNote';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { Link } from 'react-router-dom';

export const secondaryListItems = (
    <Fragment>
        <ListItemButton as={Link} to="/trainer/">
            <ListItemIcon>
                <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
        </ListItemButton>
        <ListItemButton as={Link} to="/trainer/media">
            <ListItemIcon>
                <FolderIcon />
            </ListItemIcon>
            <ListItemText primary="Media" />
        </ListItemButton>
        <ListSubheader component="div" inset>
            Settings
        </ListSubheader>
        <ListItemButton as={Link} to="/trainer/schedule">
            <ListItemIcon>
                <EditCalendarIcon />
            </ListItemIcon>
            <ListItemText primary="Schedule" />
        </ListItemButton>
        <ListItemButton as={Link} to="/trainer/sessions">
            <ListItemIcon>
                <CalendarMonthIcon />
            </ListItemIcon>
            <ListItemText primary="Sessions" />
        </ListItemButton>
        <ListItemButton as={Link} to="/trainer/information">
            <ListItemIcon>
                <EditNoteIcon />
            </ListItemIcon>
            <ListItemText primary="Information" />
        </ListItemButton>
    </Fragment>
);