import { Fragment, useEffect, useState } from 'react';

import Chart from 'react-apexcharts'

export default function ClasssSession({chart}) {

    var colorPalette = ['#00D8B6','#008FFB',  '#FEB019', '#FF4560', '#775DD0'];

    const [series, setSeries] = useState([]);
    const [labels, setLabels] = useState(['1', '2']);

    const [options, setOptions] = useState({
      fontFamily: '"Inter", sans-serif',
      chart: {
          type: 'donut',
          width: '100%',
          height: 360
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          customScale: .8,
          donut: {
            size: '75%',
          },
          offsetY: 20,
        },
        stroke: {
          colors: undefined
        }
      },
      colors: colorPalette,
      title: {
        text: 'Session count',
        style: {
          fontSize: '18px'
        }
      },

      series: series,
      labels: labels,
      legend: {
        position: 'left',
        offsetY: 80
      }
    });

    useEffect(() => {
      console.log('chart', chart);
        if(Array.isArray(chart) && chart.length){
            let labels = chart.map((val) => val.title);
            let series =  chart.map((val) => val.session_count);
            setSeries(series);
            setOptions((options) => ({...options, labels: labels}));
        }
    }, [chart])
    
    return (
        <Fragment>
           { Array.isArray(chart) && chart.length ? 
           <Chart 
              options={options} 
              series={series} 
              labels={labels} 
              type="donut" 
              width="100%" 
              height={380} 
           /> : ""}
        </Fragment>
    );
}