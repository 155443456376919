import React, { Fragment, useEffect, useState } from 'react'
import { Grid, Typography, Box, Button } from '@mui/material'
import dayjs from 'dayjs';
import PeopleIcon from '@mui/icons-material/People';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import SchoolIcon from '@mui/icons-material/School';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import StarIcon from '@mui/icons-material/Star';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const WeekView = ({ data, step, calendar }) => {

    const [weekDays, setWeekDays] = useState([]);

    useEffect(() => {
        const startOfWeek = dayjs().add(step, 'week').startOf('isoweek');
        const days = [];

        for (let i = 0; i < 7; i++) {
            days.push(startOfWeek.add(i, 'day').format('MM/DD'));
        }

        setWeekDays(days);
    }, [step]);

    useEffect(() => {
        console.log(weekDays);
    }, [weekDays])

    const [weekData, setWeekData] = useState({
        mon: [],
        tue: [],
        web: [],
        thu: [],
        fri: [],
        sat: [],
        sun: []
    });

    useEffect(() => {
        const updatedWeekData = {
            mon: [],
            tue: [],
            wed: [],
            thu: [],
            fri: [],
            sat: [],
            sun: []
        };

        if (Array.isArray(data) && data.length) {
            data.forEach(item => {
                const day = dayjs(item.start_time).format('ddd').toLowerCase();
                if (updatedWeekData[day]) {
                    updatedWeekData[day].push(item);
                }
            });
            setWeekData(updatedWeekData);
        }

    }, [data]);

    const daysOfWeek = {
        mon: "Monday",
        tue: "Tuesday",
        wed: "Wednesday",
        thu: "Thursday",
        fri: "Friday",
        sat: "Saturday",
        sun: "Sunday"
    };

    return (
        <Grid container spacing={1}>
            {
                Object.keys(daysOfWeek).map((dayKey, index) => {
                    console.log('weekData[dayKey]', weekDays[index]);
                    const currentDayString = dayjs().format('MM/DD');
                    const currentDay = dayjs(currentDayString, 'MM/DD');
                    const compareDay = dayjs(weekDays[index], 'MM/DD');
                    const fullDate = dayjs(weekDays[index], 'MM/DD').format('YYYY-MM-DD');
                    let avaiableDay = {};
                    if (Array.isArray(calendar) && calendar.length) {
                        avaiableDay = calendar.find((_v) => _v.schedule == fullDate);
                    }


                    console.log('avaiableDay', avaiableDay);
                    let compare = false;

                    if (compareDay.isBefore(currentDay)) {
                        compare = "before";
                    } else if (compareDay.isAfter(currentDay)) {
                        compare = "after";
                    } else {
                        compare = "same";
                    }

                    return (
                        <Grid
                            key={dayKey}
                            sx={{ flex: 1 }}
                            className='group'
                            item>
                            <Box
                                className={{
                                    before: "group",
                                    same: "group border border-blue-900",
                                    after: "group"
                                }[compare]}>
                                <Box className={{
                                    before: "bg-slate-200 text-slate-900 py-3",
                                    same: "bg-blue-900 border border-blue-900 text-white py-3",
                                    after: "bg-slate-900 text-white py-3"
                                }[compare]}>
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            textTransform: "uppercase",
                                            textAlign: "center",
                                        }}>
                                        {daysOfWeek[dayKey]} {weekDays[index]}
                                    </Typography>
                                </Box>
                                {
                                    avaiableDay?.is_off ? (
                                        <Box className="py-3">
                                            <Typography sx={{ fontSize: 12, textAlign: "center" }}>
                                                Off
                                            </Typography>
                                        </Box>
                                    ) :
                                        <Fragment>
                                            {
                                                Array.isArray(weekData[dayKey]) && weekData[dayKey].length ?
                                                    weekData[dayKey].map((val) => {
                                                        const { id, title, trainer, users, room, start_time, end_time } = val;
                                                        const classInfo = val.class;
                                                        const start = dayjs(start_time);
                                                        const end = dayjs(end_time);
                                                        // Tính khoảng thời gian giữa hai thời điểm trong phút
                                                        const durationInMinutes = end.diff(start, 'minute');
                                                        return (
                                                            <div
                                                                variant='plain'
                                                                className={{
                                                                    before: "bg-white border border-slate-200 text-slate-900 py-3",
                                                                    same: "bg-white border border-slate-200 py-3",
                                                                    after: "bg-white border border-slate-300 py-3"
                                                                }[compare]}
                                                                sx={{ mb: 1, fontSize: 12 }}
                                                                key={id}>
                                                                <Link
                                                                    to={"/trainer/sessions/" + id}
                                                                    size="small">
                                                                    <Box sx={{ p: 1 }}>
                                                                        <Typography
                                                                            className='border-b border-slate-200 pb-3'
                                                                            sx={{ fontWeight: 600, mb: 1 }}>
                                                                            {title}
                                                                        </Typography>
                                                                        {
                                                                            classInfo ?
                                                                                <Typography sx={{ fontSize: 13 }}>
                                                                                    <SchoolIcon sx={{ width: 14, height: 14, mr: '3px' }} />
                                                                                    {classInfo?.title}
                                                                                </Typography>
                                                                                : ""
                                                                        }
                                                                        {
                                                                            room ?
                                                                                <Typography sx={{ fontSize: 13 }}>
                                                                                    <StarIcon sx={{ width: 14, height: 14, mr: '3px' }} />
                                                                                    {room?.title}
                                                                                </Typography>
                                                                                : ""
                                                                        }
                                                                        {
                                                                            trainer ?
                                                                                <Typography sx={{ fontSize: 13 }}>
                                                                                    <PermContactCalendarIcon sx={{ width: 14, height: 14, mr: '3px' }} />
                                                                                    {trainer?.full_name}
                                                                                </Typography>
                                                                                : ""
                                                                        }
                                                                        {
                                                                            users ?
                                                                                <Typography sx={{ fontSize: 13 }}>
                                                                                    <PeopleIcon sx={{ width: 14, height: 14, mr: '3px' }} />
                                                                                    {users?.length}
                                                                                </Typography>
                                                                                : ""
                                                                        }
                                                                        {
                                                                            start ?
                                                                                <Typography sx={{ fontSize: 13 }}>
                                                                                    <CalendarMonthIcon sx={{ width: 14, height: 14, mr: '3px' }} />
                                                                                    {start.format('HH:mm')} - {end.format('HH:mm')}
                                                                                </Typography>
                                                                                : ""
                                                                        }
                                                                        {
                                                                            durationInMinutes ?
                                                                                <Typography sx={{ fontSize: 13 }}>
                                                                                    <AccessTimeIcon sx={{ width: 14, height: 14, mr: '3px' }} />
                                                                                    {durationInMinutes} minutes
                                                                                </Typography>
                                                                                : ""
                                                                        }
                                                                    </Box>
                                                                </Link>
                                                            </div>
                                                        )
                                                    })
                                                    : (
                                                        <Box className="py-3">
                                                            <Typography sx={{ fontSize: 12, textAlign: "center" }}>
                                                                Empty
                                                            </Typography>
                                                        </Box>
                                                    )
                                            }
                                            <Box
                                                className="opacity-0 group-hover:opacity-100"
                                                sx={{ pt: 3, textAlign: "center" }}>
                                                <Button
                                                    startIcon={<AddIcon />}
                                                    block
                                                    className='text-center'
                                                    as={Link}
                                                    variant='contained'
                                                    to={`/trainer/sessions/create?schedule=${weekDays[index]}`} >
                                                    add session
                                                </Button>
                                            </Box>
                                        </Fragment>
                                }
                            </Box>
                        </Grid>
                    )
                })}
        </Grid >
    )
}

export default WeekView