import { Autocomplete, Box, CircularProgress, FormControl, FormLabel, TextField, Typography, FormHelperText } from '@mui/material'
import { useEffect, Fragment, useState } from 'react'
import { useDebouncedValue } from '../utils/useDebouncedValue';

const PostPicker = ({ label, name, defaultData, getApi, values, onChange, optionKey, previewKey, error, required }) => {

    const [search, setSearch] = useState("");
    const [options, setOptions] = useState([]);
    const [selectedData, setSelectedData] = useState(defaultData || false);
    const [loading, setLoading] = useState(false);

    const searchDebounce = useDebouncedValue(search, 800);

    async function handleGetData(params) {
        try {
            setLoading(true);
            const response = await getApi(params);
            if (response && !response.error) {
                const { data } = response;
                if (Array.isArray(data) && data.length) {
                    let options = data.map((val) => ({
                        value: val.id,
                        label: val[optionKey],
                        ...val
                    }))
                    handleSetOptions(options);
                }
            }
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    function handleSetOptions(options) {
        if (Array.isArray(values) && values.length) {
            let idValues = values.map((val) => val?.id);
            let filterOptions = options.filter((val) => !idValues.includes(val.value))
            setOptions(filterOptions)
        } else {
            setOptions(options)
        }
    }

    useEffect(() => {
        handleGetData({
            search: searchDebounce
        })
    }, [searchDebounce])

    return (
        <FormControl sx={{ width: "100%", mb: 3 }} error={error} required={required}>
            <FormLabel>{label}</FormLabel>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={options}
                name={name}
                defaultValue={selectedData[optionKey]}
                onChange={(e, value) => {
                    onChange(value);
                }}
                renderInput={(params) => <TextField
                    {...params}
                    onChange={(e) => {
                        setSearch(e.target.value)
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />}
                renderOption={(props, option) => (
                    <Box
                        component="li"
                        sx={{
                            display: "flex",
                            py: 1,
                            gap: 1,
                        }} {...props}>
                        <Typography>
                            {option.label} {previewKey && option[previewKey] ? `(${option[previewKey]})` : ""}
                        </Typography>
                     </Box>
                )}
            />
            {error ? <FormHelperText>{error[0]}</FormHelperText> : ""}
        </FormControl>
    )
}

export default PostPicker
