import { instance } from "./axiosInstance";

export async function userLogin(data) {
    const response = await instance
        .post("/auth/login", data)
        .then((res) => res.data)
        .catch(() => {
            return [];
        });

    return response;
}

export async function sentPasswordReset(data) {
    const response = await instance
        .post("/auth/reset-password", data)
        .then((res) => res.data)
        .catch(() => {
            return [];
        });

    return response;
}

export async function changeUserPassword(data) {
    const response = await instance
        .post("/auth/change-password", data)
        .then((res) => res.data)
        .catch(() => {
            return [];
        });

    return response;
}

export async function userRegister(data) {
    const response = await instance
        .post("/auth/register", data)
        .then((res) => res.data)
        .catch(() => {
            return [];
        });

    return response;
}

export async function getUserLoggedin(token) {

    if (!token) {
        return null;
    }

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
    };

    const response = await instance
        .get("/auth/me", config)
        .then((res) => {
            return res?.data;
        }).catch(function () {
            return null;
        });

    return response;
}


export async function userLogout(token) {

    if (!token) {
        return null;
    }

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
    };

    const response = await instance
        .post("/auth/logout", {}, config)
        .then((res) => res.data)
        .catch(() => {
            return [];
        });

    return response;
}