import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getUserLoggedin } from '../Auth';
import { getCookie, setCookie, deleteCookie } from '../Hooks';

const initialState = {
    token: null,
    user: null,
    status: "idle" || "loading" || "complete"
};

export const getDataUser = createAsyncThunk(
    'users/getUserInformation',
    async (token) => {
        try {
            const user = await getUserLoggedin(token);
            if (user) {
                return user
            }
            return null
        } catch (error) {
            console.error('error', error);
        }
    },
);

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setToken: (state, action) => {
            const { access_token, expires_in } = action.payload;
            setCookie("li_at", access_token, expires_in);
            state.token = access_token;
        },
        getToken: (state) => {
            const token = getCookie("li_at");
            state.token = token
        },
        deleteToken: (state) => {
            deleteCookie("li_at");
            state.token = null;
            state.user = null;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setStatus: (state, action) => {
            state.status = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDataUser.pending, (state, action) => {
            state.status = "loading";
        })
        builder.addCase(getDataUser.fulfilled, (state, action) => {
          state.user = action.payload;
          state.status = "complete";
        })
      },
})

export const { getToken, setToken, deleteToken, setStatus, setUser } = authSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.auth.value)`
export const token = (state) => state.auth.token
export const user = (state) => state.auth.user

export default authSlice.reducer
