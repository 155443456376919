import React from 'react'
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import validate from 'validate.js'
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';

import { Box, IconButton } from '@mui/material'
import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSelector } from 'react-redux';
import { createSessionNotify } from '@/utils/Sessions';
import { useParams } from 'react-router-dom';

const NotifySession = ({ open, setOpen, reload }) => {

    const { id } = useParams();
    const { token, user } = useSelector((state) => state.auth);
    const [formError, setFormError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    async function handleUpdateData(data) {
        try {
            setIsLoading(true);
            const response = await createSessionNotify(token, id, data);
            if (response && !response.error) {
                sanitizeData(response.data);
                setDate(false);
                reload();
            }
        } catch (error) {
            console.error('error', error);
        } finally {
            setIsLoading(false);
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const dataForm = {
            title: data.get('title'),
            content: data.get('content'),
        }
        const error = validate(dataForm, constraints);
        if (!error) {
            handleUpdateData(dataForm);
        }
        setFormError(error);
    }

    const constraints = {
        title: {
            presence: { allowEmpty: false }
        },
        content: {
            presence: { allowEmpty: false }
        },
    };


    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={() => setOpen(false)}
        >
            <Box sx={{
                paddingX: 3,
                paddingY: 1,
                borderBottomWidth: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                columnGap: 3
            }}>
                <p class="font-bold uppercase">Create a notify for this session</p>
                <IconButton onClick={() => setOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box sx={{
                width: 680,
                maxWidth: "80vw",
                padding: 3
            }}>
                <form onSubmit={handleSubmit}>
                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.start_time} required>
                        <FormLabel>Title</FormLabel>
                        <TextField name="title" />
                        {formError?.title ? <FormHelperText>{formError?.title[0]}</FormHelperText> : ""}
                    </FormControl>
                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.start_time} required>
                        <FormLabel>Content</FormLabel>
                        <TextField
                            multiline
                            rows={3}
                            name="content"
                            defaultValue={""}
                        />
                        {formError?.content ? <FormHelperText>{formError?.content[0]}</FormHelperText> : ""}
                    </FormControl>
                    <LoadingButton
                        variant='contained'
                        type='submit'
                        loading={isLoading}>
                        Save
                    </LoadingButton>
                </form>
            </Box>
        </Drawer>
    )
}

export default NotifySession
