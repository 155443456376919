import ReactDOM from 'react-dom';
import { store } from './utils/store'
import { Provider } from 'react-redux'
import App from './App';
import "./index.css";

const Document = () => {
    return (
        <Provider store={store}>
            <App />
        </Provider>
    )
};

ReactDOM.render(<Document />, document.getElementById('root'));
