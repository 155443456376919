import { instance } from "./axiosInstance";

export async function findOneTrainerInformation(token) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await instance
        .get(`/trainer/information`, config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}

export async function createTrainerInformation(token, data) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    };
    const response = await instance
        .post("/trainer/information", data, config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}

export async function updateTrainerInformation(token, id, data) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    };
    const response = await instance
        .patch("/trainer/information/" + id, data, config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}
