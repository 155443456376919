import React from 'react'
import { IconButton, Paper, Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const SelectedCell = ({ cellLabel, data, onChange }) => {

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    return (
        <Paper sx={{ width: "100%", mb: 3 }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: 60 }}>No</TableCell>
                        <TableCell>{cellLabel}</TableCell>
                        <TableCell align="right">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Array.isArray(data) && data.length ? data.map((row, index) => (
                            <StyledTableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    {(index + 1)}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.title}
                                </TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={() => {
                                        onChange(data.filter((_v) => _v?.id !== row.id))
                                    }}>
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </TableCell>
                            </StyledTableRow>
                        )) : ""
                    }
                </TableBody>
            </Table>
        </Paper>
    )
}

export default SelectedCell
