import { Fragment, useEffect, useState } from 'react';
import { numberWithCommas } from '@/utils/Hooks';

import Chart from 'react-apexcharts'

export default function Deposits({chart}) {

    const [series, setSeries] = useState([]);

    const [options, setOptions] = useState({
        chart: {
            fontFamily: '"Inter", sans-serif',
            fontWeight: 500,
            type: 'area',
            height: 160,
            sparkline: {
              enabled: true
            },
          },
          dataLabels: {
            enabled: false
          },
          markers: {
            size: 0,
          },
          stroke: {
            curve: 'smooth',
            colors: ['#000'],
            fill: "gradient",
            width: 4
          },
          fill: {
            opacity: 1,
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.9,
              stops: [0, 90, 1000]
            }
          },
          yaxis: {
            min: 0,
            show: false
          },
          subtitle: {
            text: 'Revenue',
            offsetX: 30,
            style: {
              fontSize: '14px',
              cssClass: 'apexcharts-yaxis-title'
            }
          },
          tooltip: {
            shared: true
          },
          legend: {
            position: 'top',
            horizontalAlign: 'right',
            offsetX: -10
          }
    });

    useEffect(() => {
        if(Array.isArray(chart) && chart.length){
            let dataTotal = chart.map((val) => val.total_revenue);
            let labels =  chart.map((val) => val.month_year);
            let total = chart.reduce((accumulator, val) => {
              return accumulator + parseInt(val.total_revenue)
            }, 0 );
            setSeries((series) => ([{
                name: 'Total',
                data: dataTotal
            }]))
             setOptions((options) => ({...options, 
              title: {
                text: total ? numberWithCommas(total) + "đ" : "",
                offsetX: 30,
                style: {
                  fontSize: '24px',
                  cssClass: 'apexcharts-yaxis-title'
                }
              },
              xaxis: {
                tickAmount: 8,
                type: 'date',
                categories: labels,
                labels: {
                    rotate: -15,
                    rotateAlways: true,
                }
              }
            }))
        }
    }, [chart])
    
    return (
        <Fragment>
           <Chart options={options} series={series} type="area" width="100%" height="100%" />
        </Fragment>
    );
}