import { Box, Typography, Button, Divider, Chip, Modal, Select, MenuItem, FormControl, InputLabel, Stack, TextField, IconButton, FormHelperText, FormControlLabel, Switch } from '@mui/material'
import { useEffect, useState, Fragment } from 'react'
import { findOneSession } from '@/utils/Sessions'
import { useSelector } from 'react-redux'
import { useToasterContext } from '@/utils/ToasterContext'
import { useNavigate, useParams } from 'react-router-dom';

import validate from 'validate.js'
import PageLoading from '@/components/PageLoading'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import NavHeader from '@/components/NavHeaderHome'

import { createUserSessions, findManyUserSubscriptions } from '@/utils/UserInfomations'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import ScrollContainer from 'react-indiana-drag-scroll'
import Info from '@/components/Info'

dayjs.extend(customParseFormat);
dayjs.extend(require("dayjs/plugin/isBetween"));

const BookSession = () => {

    const { token, user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const { t } = useTranslation();

    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);

    const [defaultValue, setDefaultValue] = useState(false);
    const [trainer, setTrainer] = useState([]);
    const [AcademicClass, setClass] = useState([]);
    const [users, setUsers] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [open, setOpen] = useState(false);
    const [formError, setFormError] = useState(false);
    const [pageInit, setPageInit] = useState(false);
    const [selectedClassType, setSelectedClassType] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(false);
    const [shared, setShared] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [capacity, setCapacity] = useState(0);
    
    const [isOnlyShared, setIsOnlyShared] = useState(0);
    const [personalSelect, setPersonalSelect] = useState(false);

    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
    };
    

    const handleFindManyUserSubscriptions = async (token, filter) => {
        try {
            const response = await findManyUserSubscriptions(token, {});
            if (response && !response.error) {
                const { data, meta } = response;
                if (Array.isArray(data)) {
                    setSubscriptions(data);
                } else {
                    setSubscriptions([]);
                }
            }
        } catch (error) {

        }
    }

    function handleSelectPersonalBook(value){
        setIsOnlyShared(value);
        setPersonalSelect(true);
    }

    async function handleBookSession(event) {
        event.preventDefault();
        const formData = {
            subscription_id: selectedSubscription?.id,
            shared: shared.filter((val) => val != ''),
            is_only_shared: isOnlyShared ? 1 : 0,
            session_id: id
        }
        const error = validate(formData, createSessionConstraint);
        if (!error) {
            try {
                setIsLoading(true)
                const response = await createUserSessions(token, formData);
                if (response && !response.error) {
                    const { session_id } = response;
                    showMessage('success', 'Data created successfully!');
                    if (id) {
                        navigate("/account/sessions/" + session_id);
                    }
                } else {
                    showMessage('error', 'An error occurred, please try later');
                }
            } catch (error) {
                console.error('Error: ', error);
                showMessage('error', 'An error occurred, please try later');
            } finally {
                setIsLoading(false);
            }
        }
        setFormError(error);
    }

    async function handleFindOneSession() {
        try {
            startProgress();
            const response = await findOneSession(token, id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const {
                        title,
                        description,
                        capacity,
                        trainer,
                        start_time,
                        end_time,
                        class_types,
                        is_free_session,
                        users
                    } = data;
                    const academicClass = data?.class;
                    setCapacity(capacity);
                    setClass(academicClass);
                    setTrainer(trainer);
                    setUsers(users);
                    setDefaultValue({
                        title: title,
                        class_types: class_types,
                        description: description,
                        capacity: capacity,
                        start_time: start_time,
                        end_time: end_time,
                        is_free_session: is_free_session,
                        class: academicClass
                    });
                }
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setPageInit("complete");
            completeProgress();
        }
    }

    useEffect(() => {
        if (token) {
            handleFindManyUserSubscriptions(token, {});
            handleFindOneSession();
        }
    }, [token])

    useEffect(() => {
        const class_types = AcademicClass?.class_types;
        let classTypeInfo = class_types ? JSON.parse(class_types) : null;
        if(Array.isArray(classTypeInfo) && classTypeInfo.length && defaultValue?.class_types){
            let selectedClass = classTypeInfo.find((val) => val?.title == defaultValue?.class_types);
            setSelectedClassType(selectedClass);
        }
    }, [defaultValue, AcademicClass]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 540,
        bgcolor: 'background.paper',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    let createSessionConstraint = {};

    if(!defaultValue?.is_free_session){
        createSessionConstraint = {
            subscription_id: {
                presence: true,
            }
        }
    }


    function handleChangeSubscription(e){
        setShared([]);
        const selectedSubscription = subscriptions.find((val) => val.id == e.target.value);
        setIsOnlyShared(0);
        setSelectedSubscription(selectedSubscription);
    }

    function handleChangeShared(value, index) {
        const updated = shared.map((_v, _i) => index == _i ? value : _v);
        setShared(updated);
    }

    function handleDeleteShared(index) {
        const updated = shared.filter((_v, _i) => _i != index);
        setShared(updated);
    }

    function handleAddShared() {
        setShared((shared) => [...shared, ""]);
    }

    if (pageInit !== 'complete') {
        return (
            <PageLoading />
        );
    }

    const start = dayjs(defaultValue.start_time);
    const end = dayjs(defaultValue.end_time);
    // Tính khoảng thời gian giữa hai thời điểm trong phút
    const durationInMinutes = end.diff(start, 'minute');

    const currentDayString = dayjs().format('MM/DD');
    const currentDay = dayjs(currentDayString, 'MM/DD');

    const sessionDateCompare = currentDay.isAfter(start);

    // Tính toán trước số lượng `shared` hợp lệ để tránh lặp lại
    const validSharedLength = shared.filter((val) => val !== '').length;

    // Điều kiện tính toán cho `disabledSharedButton`
    const sharedCountAdjustment = isOnlyShared ? 0 : 1;
    const hasReachedCapacity = (capacity - users?.length) < ((shared?.length + 1) + sharedCountAdjustment);
    const hasUnlimitedSessions = selectedSubscription.max_sessions === "-1";
    const hasAvailableSessions = (selectedSubscription?.sessions_used + (shared?.length + 1) + 1) < selectedSubscription?.max_sessions;

    const disabledSharedButton = hasReachedCapacity
        ? true
        : hasUnlimitedSessions
            ? false
            : !hasAvailableSessions;

    // Tính `usageCounter` dựa trên giá trị `validSharedLength`
    const usageCounter = isOnlyShared ? validSharedLength : validSharedLength + 1;

    return (
        <Fragment>
            <Box sx={{ width: '100%' }}>
                <NavHeader
                    title={t("Book a session")}
                    breadcrumb={[
                        {
                            title: t('Book a session')
                        }
                    ]}
                    backLink="/"
                />
                <div className="max-w-[520px] mx-auto">
                    <Box>
                            <Divider sx={{ mb: 2 }}>
                                <Chip label={t("Session information")} className='uppercase' sx={{borderRadius: 0}}/>
                            </Divider>
                            <Typography
                                sx={{ fontWeight: 600, mb: 1 }}>
                                {defaultValue?.title}
                            </Typography>
                            <Box sx={{ mb: 3 }}>
                                {defaultValue?.description}
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                {
                                    selectedClassType ? (
                                        <Fragment>
                                            <Typography>
                                                <span className='text-2xl font-bold uppercase'>{selectedClassType?.title}</span>
                                            </Typography>
                                            <Typography className="text-sm" sx={{mb: 3}}>
                                                {t(selectedClassType?.description)}
                                            </Typography>
                                        </Fragment>
                                    ) : (
                                        AcademicClass ?
                                            <Fragment>
                                                <Typography>
                                                    <span className='text-2xl font-bold upe'>{AcademicClass?.title}</span>
                                                </Typography>
                                                <Typography className="text-sm" sx={{mb: 3}}>
                                                    {AcademicClass?.description}
                                                </Typography>
                                            </Fragment>
                                            : ""
                                    )
                                }
                                {
                                    start ?
                                        <Fragment>
                                            <Typography>
                                                {t("on")} <span className='font-bold uppercase'> {start.format('ddd, DD/MM')}</span>
                                            </Typography>
                                            <Typography>
                                                {t("at")} <span className='font-bold uppercase'>{start.format('HH:mm')}</span>
                                            </Typography>
                                        </Fragment>
                                        : ""
                                }
                                {
                                    durationInMinutes ?
                                        <Typography>
                                            {t("Duration")}: <span className="font-bold">{durationInMinutes} {t("minutes")}</span>
                                        </Typography>
                                        : ""
                                }
                                <Typography>
                                    {t("Instructor")}: <strong>{trainer?.full_name || "-"}</strong> 
                                </Typography>

                                <Typography>
                                    {t("Remaining slots")}: <strong>{capacity - users?.length}</strong> 
                                </Typography>

                                {    defaultValue?.is_free_session ? (
                                        <Typography sx={{ mb: 2 }}>
                                            {t("Free to join")} <Info title={t("You can register for this session without having to sign up for any membership plans")}/>
                                        </Typography>
                                    ) : ""
                                }
                                
                            </Box>
                        </Box>
                        <div className="text-center">
                            <Button
                                variant='contained'
                                onClick={() => setOpen(true)}
                                disabled={sessionDateCompare}
                                color='secondary'
                                sx={{
                                    padding: "10px 12px",
                                    borderRadius: 0,
                                    display: "block",
                                    mt: 2,
                                    mb: 2,
                                    textAlign: 'center',
                                    letterSpacing: 2
                                }}>
                               {t("Book session")}
                            </Button>
                        </div>
                        
            </div>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{
                    ...style,
                    width: 680,
                    maxWidth: "90vw"
                }}>
                    <Typography sx={{
                        fontWeight: 600,
                        fontSize: 24,
                        mb: 2,
                        textTransform: "uppercase"
                    }}>
                       {confirm ? t("Your session detailed") : t("Book a session")}
                    </Typography>
                    <ScrollContainer hideScrollbars={false} className="scroll-container max-h-[80vh] py-2">
                        {
                            capacity - users?.length <= 0 ? (
                                <Box>
                                    <Typography>{t("This session is now full. Please try a different session.")}</Typography>
                                </Box>
                            ) : (
                                    <form onSubmit={handleBookSession}>
                                    {
                                        defaultValue?.is_free_session == 1 ? (
                                            <Box>
                                                <Typography>
                                                    {t("You can register for this session without having to sign up for any membership plans")}
                                                </Typography>
                                                <LoadingButton
                                                    loading={isLoading}
                                                    variant='contained'
                                                    type='submit'
                                                    disabled={sessionDateCompare || usageCounter <= 0}
                                                    sx={{
                                                        background: "#000",
                                                        padding: "10px 12px",
                                                        flex: 1,
                                                        borderRadius: 0,
                                                        display: "block",
                                                        mt: 2,
                                                        mb: 2,
                                                        textAlign: 'center',
                                                        letterSpacing: 2
                                                    }}>
                                                    {t("Book session")}
                                                </LoadingButton>
                                            </Box>
                                        ): !selectedSubscription ? (
                                           <Box>
                                                <InputLabel id="demo-simple-select-label">
                                                    {t("Select subscription")}
                                                </InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    label="Select subscription"
                                                    name="subscription_id"
                                                    value={selectedSubscription?.id}
                                                    onChange={(e) => handleChangeSubscription(e)}
                                                    sx={{
                                                        mb: 2,
                                                        width: "100%"
                                                    }}>
                                                    {
                                                        subscriptions.map((val) => {
                                                            const validateDate = dayjs().isBetween(dayjs(val.start_date).startOf('day'), dayjs(val.end_date).endOf('day'));
                                                            const validateClass = val.membership.is_all_class === 1 || val.membership.academic_classes.some((cls) => cls.id === defaultValue.class.id);
                                                            const validateUsage = val.max_sessions === -1 || val.sessions_used < val.max_sessions;
                                                            if (!validateDate || !validateClass || !validateUsage) return null;
                                                            return (
                                                                <MenuItem
                                                                    key={val.id}
                                                                    sx={{ fontSize: 14 }}
                                                                    value={val.id}>
                                                                    <Box>
                                                                        <Typography sx={{ fontWeight: 600 }}>
                                                                            {val.membership.title} { val.membership?.can_be_share ? <span className='font-normal text-sm'>({t('Can be shared')})</span> : ""}
                                                                        </Typography>
                                                                        <Typography sx={{ fontSize: 12 }}>
                                                                            {val.max_sessions == -1 ? t("unlimited")  : `${val.max_sessions - val.sessions_used} / ${val.max_sessions}`}
                                                                        </Typography>
                                                                    </Box>
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                                {formError?.subscription_id ? <FormHelperText>{formError?.subscription_id[0]}</FormHelperText> : ""}
                                           </Box>
                                        ) : (
                                            <FormControl fullWidth error={formError?.subscription_id}>
                                                {
                                                    !personalSelect && selectedSubscription?.membership?.can_be_share ? (
                                                        <div className="grid grid-cols-1 gap-3">
                                                            <div className="border p-3 border-black">
                                                                <h3 className='font-bold text-xl'>
                                                                    {t("Personal Booking and Shared with Others")}
                                                                </h3>
                                                                <p className='text-[14px]'>
                                                                    {t("Choose this option if you are booking for yourself and others. You will receive the check-in QR code via email, which you can use for yourself and forward to others.")}
                                                                </p>
                                                                <Button 
                                                                    variant='outlined' 
                                                                    sx={{borderRadius: 0, mt: 2, fontWeight: 600, borderColor: "#000", color: "#000"}}
                                                                    onClick={() => handleSelectPersonalBook(false)}>
                                                                        {t("Next")}
                                                                </Button>
                                                            </div>
                                                            <div className="border p-3 border-black">
                                                                <h3 className='font-bold text-xl'>
                                                                    {t("Booking for someone else")}
                                                                </h3>
                                                                <p className='text-[14px]'>
                                                                    {t("Select this option if you are booking on behalf of someone else and will not be attending. The check-in QR code will be sent to your email to share with the person attending.")}
                                                                </p>
                                                                <Button 
                                                                    variant='outlined' 
                                                                    sx={{borderRadius: 0, mt: 2, fontWeight: 600, borderColor: "#000", color: "#000"}}
                                                                    onClick={() => handleSelectPersonalBook(true)}>
                                                                        {t("Next")}
                                                                </Button>
                                                            </div>
                                                            <Button 
                                                                variant='plain' 
                                                                sx={{ borderRadius: 0}} 
                                                                onClick={() => setSelectedSubscription(false)}>
                                                                    Back to select subscription
                                                            </Button>
                                                        </div>
                                                    ) : (
                                                        <Box>
                                                           {
                                                            !confirm && selectedSubscription?.membership?.can_be_share ? (
                                                                <Fragment>
                                                            {
                                                                selectedSubscription?.membership?.can_be_share ? (
                                                                    <Box>
                                                                        <h3 className='font-bold'>{t("Add shared person")}</h3>
                                                                        <p className='text-sm'>{t("Add the people you want to register for this class. For each person shared, your class credit will be deducted.")}</p>
                                                                        <Box>
                                                                            {
                                                                                Array.isArray(shared) && shared.length ? 
                                                                                    shared.map((val, index) => {
                                                                                        return (
                                                                                            <Stack direction="row" spacing={1} sx={{my: 2,}} key={index}>
                                                                                                <TextField 
                                                                                                    label={t("Your shared name") + " (" + (index + 1) + ")"}
                                                                                                    sx={{flex: 1}} 
                                                                                                    value={val} 
                                                                                                    onChange={(e) => handleChangeShared(e.target.value, index)}
                                                                                                />
                                                                                                <IconButton 
                                                                                                    variant='outlined' 
                                                                                                    sx={{ borderColor: "#000", borderRadius: 0 }} 
                                                                                                    onClick={() => handleDeleteShared(index)}>
                                                                                                        <DeleteOutlineIcon />
                                                                                                </IconButton>
                                                                                            </Stack>
                                                                                        )
                                                                                    }) : ""
                                                                            }
                                                                        </Box>
                                                                        <div className="text-end">
                                                                                <Button 
                                                                                    variant='outlined'
                                                                                    sx={{ borderColor: "#000", borderRadius: 0, mt: 1 }} 
                                                                                    startIcon={<AddIcon />}
                                                                                    disabled={disabledSharedButton}
                                                                                    onClick={() => handleAddShared()}>
                                                                                    {t("Add shared")}
                                                                                </Button>
                                                                        </div>
                                                                        <Stack direction="row" spacing={1} sx={{mt: 3}}>
                                                                            <Button 
                                                                                onClick={() => setPersonalSelect(false)} 
                                                                                sx={{
                                                                                    flex: 1,
                                                                                    padding: "10px 12px",
                                                                                    borderRadius: 0,
                                                                                    display: "block",
                                                                                    mt: 2,
                                                                                    mb: 2,
                                                                                    textAlign: 'center',
                                                                                    letterSpacing: 2
                                                                                }}
                                                                                variant='outlined'>
                                                                                {t("Back")}
                                                                            </Button>
                                                                            <Button
                                                                                variant='contained'
                                                                                type='button'
                                                                                onClick={() => { setConfirm(true) }}
                                                                                disabled={sessionDateCompare || usageCounter <= 0}
                                                                                sx={{
                                                                                    background: "#000",
                                                                                    padding: "10px 12px",
                                                                                    width: "100%",
                                                                                    borderRadius: 0,
                                                                                    display: "block",
                                                                                    mt: 2,
                                                                                    mb: 2,
                                                                                    flex: 1,
                                                                                    textAlign: 'center',
                                                                                    letterSpacing: 2
                                                                                }}>
                                                                                {t("Next step")}
                                                                            </Button>
                                                                        </Stack>
                                                                    </Box>
                                                                ) :  
                                                                    selectedSubscription?.id && !confirm ? (
                                                                        <Stack direction="row" spacing={1}>
                                                                            <Button 
                                                                                onClick={() => setSelectedSubscription(false)} 
                                                                                sx={{
                                                                                    flex: 1,
                                                                                    padding: "10px 12px",
                                                                                    borderRadius: 0,
                                                                                    display: "block",
                                                                                    mt: 2,
                                                                                    mb: 2,
                                                                                    textAlign: 'center',
                                                                                    letterSpacing: 2
                                                                                }}
                                                                                variant='outlined'>
                                                                                {t("Back")}
                                                                            </Button>
                                                                            <Button
                                                                                variant='contained'
                                                                                type='button'
                                                                                onClick={() => { setConfirm(true) }}
                                                                                disabled={sessionDateCompare || usageCounter <= 0}
                                                                                sx={{
                                                                                    background: "#000",
                                                                                    padding: "10px 12px",
                                                                                    width: "100%",
                                                                                    borderRadius: 0,
                                                                                    display: "block",
                                                                                    mt: 2,
                                                                                    mb: 2,
                                                                                    flex: 1,
                                                                                    textAlign: 'center',
                                                                                    letterSpacing: 2
                                                                                }}>
                                                                                {t("Next step")}
                                                                            </Button>
                                                                        </Stack>
                                                                    ) : ""
                                                                }
                                                        </Fragment>
                                                            ): (
                                                                <Box sx={{ mb: 3}}>
                                                                <div className="border-black mb-3">
                                                                {
                                                                    selectedClassType ? (
                                                                        <Fragment>
                                                                            <Typography>
                                                                                <span className='text-2xl font-bold uppercase'>{selectedClassType?.title}</span>
                                                                            </Typography>
                                                                            <Typography className="text-sm" sx={{mb: 3}}>
                                                                                {t(selectedClassType?.description)}
                                                                            </Typography>
                                                                        </Fragment>
                                                                    ) : (
                                                                        AcademicClass ?
                                                                            <Fragment>
                                                                                <Typography>
                                                                                    <span className='text-2xl font-bold upe'>{AcademicClass?.title}</span>
                                                                                </Typography>
                                                                                <Typography className="text-sm" sx={{mb: 3}}>
                                                                                    {AcademicClass?.description}
                                                                                </Typography>
                                                                            </Fragment>
                                                                            : ""
                                                                    )
                                                                }
                                                                {
                                                                    start ?
                                                                        <Fragment>
                                                                            <Typography>
                                                                                {t("on")} <span className='font-bold uppercase'> {start.format('ddd, DD/MM')}</span>
                                                                            </Typography>
                                                                            <Typography>
                                                                                {t("at")} <span className='font-bold uppercase'>{start.format('HH:mm')}</span>
                                                                            </Typography>
                                                                        </Fragment>
                                                                        : ""
                                                                }
                                                                {
                                                                    durationInMinutes ?
                                                                        <Typography>
                                                                            {t("Duration")}: <span className="font-bold">{durationInMinutes} {t("minutes")}</span>
                                                                        </Typography>
                                                                        : ""
                                                                }
                                                                <Typography>
                                                                    {t("Instructor")}: <strong>{trainer?.full_name || "-"}</strong> 
                                                                </Typography>
                                                                <Typography>{t("Number of reservations")}: <strong className='ml-2'>{ usageCounter }</strong></Typography>
                                                                </div>
                                                                {
                                                                    Array.isArray(shared) && shared.length ? 
                                                                        <Box sx={{ mt: 2, maxHeight: 360, overflowY: "auto" }}>
                                                                            <h3 className='font-bold mb-0'>{t("Shared with person")}</h3>
                                                                            {
                                                                                shared.filter((val) => val != '').map((val, index) => {
                                                                                    return (
                                                                                        <Stack direction="row" spacing={1} key={index}>
                                                                                            <Typography>{(index + 1)}. <strong>{val}</strong></Typography>
                                                                                        </Stack>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Box> : ""
                                                                }
                                                                <Stack direction="row" spacing={1} sx={{mt: 3, mb: 2}}>
                                                                    {
                                                                         selectedSubscription?.membership?.can_be_share ? (
                                                                            <Button 
                                                                                onClick={() => setConfirm(false)} 
                                                                                sx={{
                                                                                    flex: 1,
                                                                                    padding: "10px 12px",
                                                                                    borderRadius: 0,
                                                                                    display: "block",
                                                                                    mt: 2,
                                                                                    mb: 2,
                                                                                    textAlign: 'center',
                                                                                    letterSpacing: 2
                                                                                }}
                                                                                variant='outlined'>
                                                                                {t("Back")}
                                                                            </Button>
                                                                         ) : (
                                                                            <Button 
                                                                                onClick={() => setSelectedSubscription(false)} 
                                                                                sx={{
                                                                                    flex: 1,
                                                                                    padding: "10px 12px",
                                                                                    borderRadius: 0,
                                                                                    display: "block",
                                                                                    mt: 2,
                                                                                    mb: 2,
                                                                                    textAlign: 'center',
                                                                                    letterSpacing: 2
                                                                                }}
                                                                                variant='outlined'>
                                                                                {t("Back")}
                                                                            </Button>
                                                                         )
                                                                        
                                                                    }
                                                                    <LoadingButton
                                                                        loading={isLoading}
                                                                        variant='contained'
                                                                        type='submit'
                                                                        disabled={sessionDateCompare || usageCounter <= 0}
                                                                        sx={{
                                                                            background: "#000",
                                                                            padding: "10px 12px",
                                                                            flex: 1,
                                                                            borderRadius: 0,
                                                                            display: "block",
                                                                            mt: 2,
                                                                            mb: 2,
                                                                            textAlign: 'center',
                                                                            letterSpacing: 2
                                                                        }}>
                                                                        {t("Book session")}
                                                                    </LoadingButton>
                                                                </Stack>
                                                                <p className="text-sm">
                                                                    <span className="text-red-600 mr-2">*</span>
                                                                    {t("We will send the appointment booking information to the email addres")} <strong>{user?.email}</strong>. {t("You will receive an email containing the appointment details and an attached event file. Please open the attachment and add it to your calendar.")}
                                                                </p>
                                                            </Box>
                                                            )
                                                           }
                                                        </Box>
                                                    )
                                                }
                                                {
                                                    sessionDateCompare ? (
                                                        <Typography sx={{ mb: 2 }}>
                                                            (<span className="text-red-600">*</span>)
                                                            {t("This session has expired!")}</Typography>
                                                    ) : ""
                                                }
                                            </FormControl>
                                        ) 
                                    }
                                </form>
                            )
                        }
                    </ScrollContainer>
                </Box>
            </Modal>
        </Fragment>
    )
}

export default BookSession