import { Autocomplete, Box, Card, CardContent, Grid, List, ListItem, Typography, Button, Modal, FormControlLabel, Switch, Select, MenuItem } from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import { createOrder } from '@/utils/Orders'
import { useSelector } from 'react-redux'
import { useToasterContext } from '@/utils/ToasterContext'

import NavHeader from '@/components/NavHeader'
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import validate from 'validate.js'
import PostPicker from '@/components/PostPicker'

import { LoadingButton } from '@mui/lab'
import { findManyUsers } from '@/utils/Users'
import { findManyMemberships } from '@/utils/Memberships'
import { Countries } from '@/utils/Countries';
import { numberWithCommas } from '@/utils/Hooks'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { findManyPromotions } from '@/utils/Promotions'
import classNames from 'classnames'
import ATM from "@/assets/64x64-atm.svg";
import CreditLine from "@/assets/176x24-credit.svg";
import Credit from "@/assets/64x64-atm.svg";
import Qrcode from "@/assets/64x64-vi-vnpay.svg";
import Bank from "@/assets/64x64-bank.svg";

const CreateOrder = () => {

    const { token } = useSelector((state) => state.auth);
    const { showMessage, startProgress, completeProgress, currentLanguage } = useToasterContext();
    const { t } = useTranslation(); 
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState([]);
    const [formError, setFormError] = useState(false);
    const [defaultData, setDefaultData] = useState(false);
    const [cart, setCart] = useState([]);
    const [cartTotal, setCartTotal] = useState(false);
    const [promotion, setPromotion] = useState(false);
    const [promotionAmount, setPromotionAmount] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('vietqr');
    const [paymentType, setPaymentType] = useState(false);

    const [dataCart, setDataCart] = useState({
        quantity: 0,
        memberships: false
    });

    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function handleCreate(data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await createOrder(token, data);
            if (response && !response.error) {
                const { data } = response;
                if (data?.id) {
                    showMessage('success', 'Data created successfully!');
                    navigate("/admin/orders/" + data?.id);
                }
            }
        } catch (error) {
            showMessage('error', 'An error occurred, please try again later');
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }


    useEffect(() => {
        let totalPrice = 0;
        let promotionAmount = 0;
        if (Array.isArray(cart) && cart?.length) {
            cart.map((value) => {
                let price = value?.memberships?.regular_price * value.quantity;
                if(promotion){
                    const { for_all_memberships, promotion_amount, memberships } = promotion;
                    let validatePromotion = false;
                    if (!for_all_memberships) {
                        if (Array.isArray(memberships) && memberships?.length) {
                            validatePromotion = memberships.find((val) => val.id == value?.memberships?.id);
                        }
                    } else {
                        validatePromotion = true;
                    }
                    if (validatePromotion) {
                        promotionAmount += price * promotion_amount / 100;
                    }
                }
                totalPrice += price;
                return value;
            });
        }
        setCartTotal(totalPrice);
        setPromotionAmount(promotionAmount);
    }, [cart, promotion])

    
    function handleChangePromotion(data){
        if (data) {
            const { memberships } = data;
            let validated = false;
            if(Array.isArray(cart) && cart?.length) {
                cart.map((val) => {
                    if(memberships.find((_v) => _v.id == val?.memberships?.id)){
                        validated = true;
                    }
                })
                if(validated){
                    showMessage("success", t("Promotion code successfully applied!"))
                    setPromotion(data)
                } else {
                    setPromotion(false);
                    showMessage("error", t("This code is not valid."));
                }
            } else {
                showMessage("error", t("This code is not valid."));
                setPromotion(false);
            }
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const notify = data.get('sent_notify');
        const dataForm = {
            billing_name: data.get('billing_name'),
            billing_phone: data.get('billing_phone'),
            billing_email: data.get('billing_email'),
            billing_country: data.get('billing_country'),
            billing_city: data.get('billing_city'),
            billing_district: data.get('billing_district'),
            billing_ward: data.get('billing_ward'),
            billing_address: data.get('billing_address'),
            payment_status: data.get('payment_status'),
            language: currentLanguage,
            payment_method: paymentMethod,
            payment_type: paymentType,
            billing_subtotal: cartTotal,
            promotion_amount: promotionAmount,
            promotion_id: promotion?.id,
            billing_total: cartTotal - promotionAmount,
            user_id: user?.id,
            send_email: notify == 'on' ? 1 : 0,
            cart: cart,
        }

        const error = validate(dataForm, constraints);
        if (!error) {
            handleCreate(dataForm);
        }
        setFormError(error);
    }

    function handleAddToCart(e) {
        e.preventDefault();
        setCart((cart) => ([...cart, dataCart]));
        setDataCart({
            quantity: 0,
            memberships: false,
        });
        handleClose();
    }


    const constraints = {
        billing_name: {
            presence: { allowEmpty: false },
        },
        billing_phone: {
            presence: { allowEmpty: false },
        },
        billing_email: {
            presence: { allowEmpty: false },
            email: true
        },
    };

    const CartItem = ({ data, promotion }) => {
        {

            let validatePromotion = false;
            let promotionAmount = 0;

            if(promotion){
                const { for_all_memberships, promotion_amount, memberships } = promotion;
                promotionAmount = promotion_amount;
                if(!for_all_memberships){
                    if(Array.isArray(memberships) && memberships?.length) {
                        validatePromotion = memberships.find((val) => val.id == data?.memberships?.id)
                    }
                } else {
                    validatePromotion = true;
                }
            }
            
            const { id, title, description, regular_price } = data.memberships;
            const { quantity } = data;

            return (
                <ListItem
                    key={id}
                    className={'border-b border-dashed border-slate-200'}>
                    <Box sx={{ flex: 1 }}>
                        <Typography sx={{ fontWeight: 600, mb: 1 }}>
                            {t(title)}
                        </Typography>
                        <Typography sx={{ mb: 1, fontSize: 14, whiteSpace: "pre-wrap" }}>
                            {t(description)}
                        </Typography>
                        <Typography>
                            {regular_price ? numberWithCommas(regular_price) + "₫" : "-"} x {quantity}
                        </Typography>
                        {
                            validatePromotion ? (
                               <Box>
                                    <Typography>
                                        <strong className='mr-2'>{t("Subtotal")}:</strong> { `${numberWithCommas((regular_price * quantity) - (regular_price * quantity) * promotionAmount / 100)}₫` } <span className="bg-black text-white px-1 text-xs">{`${t("-")} ${promotionAmount}%`}</span>
                                    </Typography>
                                </Box>
                            ) : (
                                <Typography>
                                    <strong className='mr-2'>{t("Subtotal")}:</strong>
                                    {numberWithCommas(regular_price * quantity)}₫
                                </Typography>
                            )
                        }
                    </Box>
                </ListItem>
            )

        }
    }

    return (
        <Fragment>
            <Box sx={{ width: '100%' }}>
                <NavHeader
                    title="Create Order"
                    breadcrumb={[
                        {
                            title: 'Create Order'
                        }
                    ]}
                    backLink="/admin/orders"
                />
                <Box sx={{ p: 3 }} component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={8}>
                            <Typography sx={{ textTransform: "uppercase", fontWeight: 600, mb: 2 }}>
                                Billing information
                            </Typography>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_name} required>
                                <FormLabel>Your full name</FormLabel>
                                <TextField
                                    name="billing_name"
                                    error={formError?.billing_name}
                                    defaultValue={defaultData?.billing_name}
                                />
                                {formError?.billing_name ? <FormHelperText>{formError?.billing_name[0]}</FormHelperText> : ""}
                            </FormControl>
                            <Grid container spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_phone} required>
                                        <FormLabel>Phone number</FormLabel>
                                        <TextField
                                            name="billing_phone"
                                            defaultValue={defaultData?.billing_phone}
                                        />
                                        {formError?.billing_phone ? <FormHelperText>{formError?.billing_phone[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_email} required>
                                        <FormLabel>Email address</FormLabel>
                                        <TextField
                                            name="billing_email"
                                            defaultValue={defaultData?.billing_email}
                                        />
                                        {formError?.billing_email ? <FormHelperText>{formError?.billing_email[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_country}>
                                <FormLabel>Country</FormLabel>
                                <Autocomplete
                                    options={Countries}
                                    name="billing_country"
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                {...params}
                                                name="billing_country"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )
                                    }}
                                />
                                {formError?.billing_country ? <FormHelperText>{formError?.billing_country[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_city}>
                                <FormLabel>Billing City</FormLabel>
                                <TextField
                                    name="billing_city"
                                    defaultValue={defaultData?.billing_city}
                                />
                                {formError?.billing_city ? <FormHelperText>{formError?.billing_city[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_district}>
                                <FormLabel>Billing District</FormLabel>
                                <TextField
                                    name="billing_district"
                                    defaultValue={defaultData?.billing_district}
                                />
                                {formError?.billing_district ? <FormHelperText>{formError?.billing_district[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_ward}>
                                <FormLabel>Billing Ward</FormLabel>
                                <TextField
                                    name="billing_ward"
                                    defaultValue={defaultData?.billing_ward}
                                />
                                {formError?.billing_ward ? <FormHelperText>{formError?.billing_ward[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.billing_address}>
                                <FormLabel>Billing address</FormLabel>
                                <TextField
                                    name="billing_address"
                                    defaultValue={defaultData?.billing_address}
                                />
                                {formError?.billing_address ? <FormHelperText>{formError?.billing_address[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                <FormControlLabel
                                    control={<Switch />}
                                    label="Send order confirmation emails to customers"
                                    name="sent_notify"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Typography sx={{ textTransform: "uppercase", fontWeight: 600, mb: 2 }}>
                                Cart
                            </Typography>
                            <Card variant='outlined' sx={{ mb: 3 }}>
                                <CardContent>
                                    <List>
                                        {
                                            Array.isArray(cart) && cart?.length ? (
                                                <Box>
                                                    <Box>
                                                        <List>
                                                            {
                                                                cart.map((val, index) => (
                                                                    <CartItem key={index} data={val} promotion={promotion}/>
                                                                ))
                                                            }
                                                        </List>
                                                    </Box>
                                                    {
                                                        promotion ?  (
                                                            <Fragment>
                                                                <Typography sx={{ mt: 2 }}>
                                                                    {t("Promotion code")}: <strong className='ml-2'>{promotion?.promotion_code}</strong>
                                                                </Typography>
                                                                <Typography>
                                                                    {t("Subtotal")}:
                                                                    <span className='ml-2'>{numberWithCommas(cartTotal)}₫</span>
                                                                </Typography>
                                                                <Typography>
                                                                    {t("Discount")}:
                                                                    <span className='ml-2'>{numberWithCommas(promotionAmount)}₫</span>
                                                                </Typography>
                                                                <Typography sx={{ mb: 3 }}>
                                                                    {t("Total")}: <strong className='ml-2'>{numberWithCommas(cartTotal - promotionAmount)}₫</strong>
                                                                </Typography>
                                                            </Fragment>
                                                        ) : 
                                                            <Typography sx={{ mt: 2 }}>
                                                                {t("Total")}: <strong className='ml-2'>{numberWithCommas(cartTotal)}₫</strong>
                                                            </Typography>
                                                    }
                                                </Box>
                                            ) : <Typography sx={{ mb: 2, textAlign: "center" }}>Empty cart</Typography>
                                        }
                                    </List>
                                </CardContent>
                            </Card>
                            <Box sx={{ textAlign: "right", mb: 2 }}>
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    sx={{ borderRadius: 0 }}
                                    onClick={handleOpen}>
                                    Add to Cart
                                </Button>
                            </Box>
                            <Box sx={{ mb: 1 }}>
                                <PostPicker
                                    label="Select promotion"
                                    name="promotion"
                                    defaultData={user}
                                    values={[promotion]}
                                    getApi={findManyPromotions}
                                    onChange={(value) => handleChangePromotion(value)}
                                    optionKey="promotion_code"
                                    previewKey="id"
                                />
                            </Box>
                            <Box sx={{ mb: 1 }}>
                                <PostPicker
                                    label="Select user"
                                    name="user"
                                    defaultData={user}
                                    values={[user]}
                                    getApi={findManyUsers}
                                    onChange={(value) => setUser((value))}
                                    required
                                    optionKey="email"
                                    previewKey="id"
                                />
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                    <Typography sx={{ textTransform: "uppercase", fontWeight: 600, mb: 2 }}>
                                        {t("Payment method")}
                                    </Typography>
                                    <div className='bg-white border border-slate-200 p-3'>
                                        <FormControl sx={{ width: "100%", mb: 3 }}>
                                            <div 
                                                onClick={() => {
                                                    setPaymentMethod("vietqr");
                                                }}
                                                className={classNames({
                                                'flex gap-3 cursor-pointer bg-white rounded border border-slate-200 mb-3 p-1 items-center': true,
                                                'bg-green-50 border !border-green-600 text-green-600 shadow': paymentMethod == 'vietqr'
                                            })}>
                                                <div className='flex-1'>
                                                    <p>{t("Bank transfer")}</p>
                                                </div>
                                                <div>
                                                    <img width={34} height={34} src={Bank}/>
                                                </div>
                                            </div>
                                            <div 
                                                onClick={() => {
                                                    setPaymentMethod("vnpay");
                                                    setPaymentType("VNPAYQR");
                                                }}
                                                className={classNames({
                                                'flex gap-3 cursor-pointer bg-white rounded border border-slate-200 mb-3 p-1 items-center': true,
                                                'bg-green-50 border !border-green-600 text-green-600 shadow': paymentMethod == 'vnpay' && paymentType == 'VNPAYQR'
                                            })}>
                                                <div className='flex-1'>
                                                <p>{t("Payment code scanning application supported by VNPAY")}</p>
                                                </div>
                                                <div>
                                                    <img width={34} height={34} src={Qrcode}/>
                                                </div>
                                            </div>
                                            <div 
                                                onClick={() => {
                                                    setPaymentMethod("vnpay");
                                                    setPaymentType("VNBANK");
                                                }}
                                                className={classNames({
                                                'flex gap-3 cursor-pointer bg-white rounded border border-slate-200 mb-3 p-1 items-center': true,
                                                'bg-green-50 border !border-green-600 text-green-600 shadow': paymentMethod == 'vnpay' && paymentType == 'VNBANK'
                                            })}>
                                                <div className='flex-1'>
                                                    <p>{t("Domestic card and bank account")}</p>
                                            </div>
                                            <div>
                                                    <img width={34} height={34} src={ATM}/>
                                                </div>
                                            </div>
                                            <div 
                                                onClick={() => {
                                                    setPaymentMethod("vnpay");
                                                    setPaymentType("INTCARD");
                                                }}
                                                className={classNames({
                                                'flex gap-3 cursor-pointer bg-white rounded border border-slate-200 mb-3 p-1 items-center': true,
                                                'bg-green-50 border !border-green-600 text-green-600 shadow': paymentMethod == 'vnpay' && paymentType == 'INTCARD'
                                            })}>
                                                <div className='flex-1'>
                                                    <p>{t("International payment card")}</p>
                                                    <img src={CreditLine}/>
                                            </div>
                                            <div>
                                                <img width={34} height={34} src={Credit}/>
                                            </div>
                                        </div>
                                    </FormControl>
                                </div>
                            </Box>
                            <Box sx={{ mb: 3 }}>
                                <Typography sx={{ textTransform: "uppercase", fontWeight: 600, mb: 2 }}>
                                    Payment status
                                </Typography>
                                <Select
                                    name="payment_status"
                                    fullWidth
                                >
                                    <MenuItem value="pending">Pending</MenuItem>
                                    <MenuItem value="success">Success</MenuItem>
                                    <MenuItem value="failed">Failed</MenuItem>
                                </Select>
                            </Box>
                            <FormControl>
                                <LoadingButton
                                    variant='contained'
                                    color='primary'
                                    size='lg'
                                    type='submit'
                                    loading={isLoading}
                                >
                                    Create order
                                </LoadingButton>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box sx={{ ...style, width: 400 }}>
                    <Typography sx={{
                        fontWeight: 600,
                        fontSize: 24,
                        textTransform: "uppsercase"
                    }}>
                        Add to cart
                    </Typography>
                    <form onSubmit={handleAddToCart}>
                        <Box sx={{ mb: 1 }}>
                            <PostPicker
                                label="Select membership"
                                name="membership"
                                defaultData
                                getApi={findManyMemberships}
                                onChange={(value) => {
                                    setDataCart({ ...dataCart, memberships: value })
                                }}
                                optionKey="title"
                            />
                        </Box>
                        <FormControl sx={{ width: "100%", mb: 1 }}>
                            <FormLabel>Quantity</FormLabel>
                            <TextField
                                name="quantity"
                                type='number'
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setDataCart({ ...dataCart, quantity: value })
                                }}
                                value={dataCart.quantity}
                            />
                        </FormControl>
                        <FormControl >
                            <Button type="submit">
                                Submit
                            </Button>
                        </FormControl>
                    </form>
                </Box>
            </Modal>
        </Fragment>
    )
}

export default CreateOrder
