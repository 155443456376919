import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Link } from 'react-router-dom';
import React, { Fragment } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const ActionCell = ({ rowData, editUrl, setDeleteItem }) => {
    return (
        <PopupState variant="popover" popupId="edit-popup-menu">
            {(popupState) => (
                <Fragment>
                    <IconButton variant="outlined" {...bindTrigger(popupState)}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                        <Link to={editUrl + rowData?.id}>
                            <MenuItem>
                                <ListItemIcon>
                                    <EditIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Edit</ListItemText>
                            </MenuItem>
                        </Link>
                        <MenuItem onClick={() => setDeleteItem(rowData?.id)}>
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                        </MenuItem>
                    </Menu>
                </Fragment>
            )}
        </PopupState>
    )
}

export default ActionCell
