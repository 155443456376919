import React, { useState, useEffect } from 'react'
import { TextField, InputAdornment, MenuItem, Select, Stack, FormLabel, FormControl } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useDebouncedValue } from '../utils/useDebouncedValue';

import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';

const FilterTable = ({ filter, setFilter }) => {

    const [search, setSearch] = useState(filter?.search);
    const [searchChanged, setSearchChanged] = useState(false);
    const searchDebounce = useDebouncedValue(search, 800);

    useEffect(() => {
        if (searchChanged) {
            setFilter({
                ...filter,
                paged: 1,
                search: searchDebounce
            })
        }
    }, [searchDebounce])

    const handleChange = (name, value) => {
        setFilter({
            ...filter,
            [name]: value
        })
    };

    const handleChangeDate = (name, value) => {
        setFilter({
            ...filter,
            [name]: value ? dayjs(value).format("DD/MM/YYYY") : null
        })
    }

    return (
            <form>
                <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
                    <FormControl sx={{ flex: 1 }}>
                        <FormLabel sx={{mb: 1}}>
                            Search keyword
                        </FormLabel>
                        <TextField
                            value={search}
                            onChange={(e) => {
                                setSearchChanged(true);
                                setSearch(e.target.value)
                            }}
                            sx={{
                                width: "100%",
                                background: "#fff",
                                paddingLeft: "0px !important"
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment sx={{ mr: 3 }} position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel sx={{mb: 1}}>
                            Order
                        </FormLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label=""
                            placeholder='Order'
                            value={filter?.order}
                            onChange={(e) => handleChange("order", e.target.value)}
                            sx={{ width: 180,  background: "#fff", }}
                        >
                            <MenuItem value={'desc'}>Desc</MenuItem>
                            <MenuItem value={'asc'}>Asc</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel sx={{mb: 1}}>
                            Start date
                        </FormLabel>
                        <DatePicker
                            value={filter?.start_date ? dayjs(filter.start_date, 'DD/MM/YYYY') : null}
                            onChange={(value) => handleChangeDate("start_date", value)}
                            sx={{ width: 220,  background: "#fff", }}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel sx={{mb: 1}}>
                            End date
                        </FormLabel>
                        <DatePicker
                            value={filter?.end_date ? dayjs(filter.end_date, 'DD/MM/YYYY') : null}
                            onChange={(value) => handleChangeDate("end_date", value)}
                            sx={{ width: 220,  background: "#fff" }}
                        />
                    </FormControl>
                </Stack>
            </form>
    )
}

export default FilterTable
