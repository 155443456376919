import NavHeader from '@/components/NavHeaderHome'
import React, { useState, useEffect } from 'react'
import { findManyTrainers } from '@/utils/UserTrainers'
import { useToasterContext } from '@/utils/ToasterContext'
import { Link, useSearchParams } from 'react-router-dom'
import { TablePagination } from '@mui/material'
import { useTranslation } from 'react-i18next'

import PageLoading from '@/components/PageLoading'

const APP_URL = process.env.REACT_APP_APP_URL;

const index = () => {

    const { t } = useTranslation();

    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [searchParams, setSearchParams] = useSearchParams();

    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [pageStatus, setPageStatus] = useState('idle');
    const [filter, setFilter] = useState({
        search: "",
        order: "asc",
        paged: 1,
        per_page: 12,
        is_have_private: 1
    })

    const handleFindManyData = async (filter) => {
        try {
            startProgress();
            setSearchParams(filter);
            const response = await findManyTrainers(filter);
            if (response && !response.error) {
                const { data, meta } = response;
                if (Array.isArray(data)) {
                    setData(data);
                    setTotal(meta?.total);
                } else {
                    setData([]);
                }
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setPageStatus("complete");
            completeProgress();
        }
    }

    const handleChangePage = (event, newPage) => {
        setFilter((filter) => (
            {
                ...filter,
                paged: newPage ? (newPage + 1) : 1
            }
        ));
    };

    const handleChangeRowsPerPage = (event) => {
        setFilter((filter) => ({
            ...filter,
            per_page: parseInt(event.target.value),
            paged: 1
        }));
    };

    useEffect(() => {
        handleFindManyData(filter);
    }, [])

    useEffect(() => {
        if (pageStatus == 'complete') {
            handleFindManyData(filter);
        }
    }, [filter])

    if (pageStatus != 'complete') {
        return (
            <PageLoading />
        )
    }

    return (
        <div>
            <NavHeader
                title={t("Book private session")}
                breadcrumb={[
                    {
                        title: t("Book private session")
                    }
                ]}
                backLink="/schedule"
            />
            {
                Array.isArray(data) && data.length ?
                    <>
                        <div className="grid grid-cols-2 lg:grid-cols-3 gap-5">
                            {
                                data.map((val) => {
                                    return (
                                        <div key={val.id} className='border bg-white border-slate-200'>
                                            <Link to={"/book-trainer/" + val.id}>
                                            <img
                                                className='h-[180px] w-full lg:h-[300px] object-cover object-top'
                                                src={val?.thumbnail?.file_path ? APP_URL + "/" + val.thumbnail.file_path : "https://placehold.co/400x300"}
                                                alt="" />
                                                    <div className="p-3">
                                                        <h3 className='!font-bold'>
                                                            {val?.full_name}
                                                        </h3>
                                                    </div>
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <TablePagination
                            rowsPerPageOptions={[12, 24, 36]}
                            component="div"
                            count={total || 0}
                            rowsPerPage={parseInt(filter?.per_page) || 12}
                            page={parseInt(filter?.paged - 1)}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                : ""
            }
        </div>
    )
}

export default index
