import { Box, FormControlLabel, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { updateClass, findOneClass } from '@/utils/Classes'
import { useSelector } from 'react-redux'
import { useToasterContext } from '@/utils/ToasterContext'
import { LoadingButton } from '@mui/lab'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';

import NavHeader from '@/components/NavHeader'
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput';
import AddIcon from '@mui/icons-material/Add';
import PageLoading from "@/components/PageLoading";
import validate from 'validate.js'
import ThumbnailPicker from '@/components/ThumbnailPicker'
import dayjs from 'dayjs'

import DeleteIcon from '@mui/icons-material/Delete';

const UpdateClassPage = () => {

    const { id } = useParams();
    const { token } = useSelector((state) => state.auth);
    const { showMessage, startProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [defaultValue, setDefaultValue] = useState(false);
    const [thumbnail, setThumbnail] = useState(false);
    const [isPrivate, setIsPrivate] = useState(0);
    const [classTypes, setClassTypes] = useState([]);

    const navigate = useNavigate();

    async function handleFindOne() {
        try {
            const response = await findOneClass(token, id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const {
                        title,
                        description,
                        start_time,
                        thumbnail,
                        end_time,
                        capacity,
                        class_types,
                        is_private,
                    } = data;
                    if(classTypes){
                        setClassTypes(JSON.parse(class_types) || []);
                    }
                    setThumbnail(thumbnail);
                    setIsPrivate(is_private);
                    setDefaultValue({
                        title,
                        description,
                        start_time,
                        end_time,
                        capacity,
                        is_private
                    });
                }
            }
        } catch (error) {
            console.log('error', error);
        }
    }


    console.log('classTypes', classTypes);

    async function handleUpdate(token, data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await updateClass(token, id, data);
            if (response && !response.error) {
                const { data } = response;
                showMessage('success', 'Data updated successfully!');
                if (data?.id) {
                    navigate("/admin/classes/" + data.id);
                }
            }
        } catch (error) {
            showMessage('error', 'An error occurred, please try again later!');
        } finally {
            setIsLoading(false);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const dataForm = {
            title: data.get('title'),
            description: data.get('description'),
            thumbnail: thumbnail?.id,
            capacity: data.get('capacity'),
            is_private: isPrivate ? 1 : 0,
            class_types: classTypes ? JSON.stringify(classTypes) : "",
        }

        const error = validate(dataForm, constraints);

        if (!error) {
            handleUpdate(token, dataForm)
        } else {
            console.log('error', error);
            showMessage('error', "One or more input fields are invalid!");
        }

        setFormError(error);
    }

    useEffect(() => {
        handleFindOne();
    }, [])

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    var daysOfWeek = [
        { label: 'Monday', value: 'mon' },
        { label: 'Tuesday', value: 'tue' },
        { label: 'Wednesday', value: 'web' },
        { label: 'Thursday', value: 'thur' },
        { label: 'Friday', value: 'fri' },
        { label: 'Saturday', value: 'sat' },
        { label: 'Sunday', value: 'sun' }
    ];

    // Custom validator to ensure end_time is after start_time
    validate.validators.timeAfter = function (value, options, key, attributes) {
        const startTime = parseTime(attributes.start_time);
        const endTime = parseTime(value);
        if (startTime && endTime && endTime <= startTime) {
            return options.message || "must be after start time";
        }
    };

    // Helper function to parse time in hh:mm AM/PM format to Date object
    function parseTime(timeString) {
        const [time, modifier] = timeString.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        if (modifier === 'PM' && hours < 12) {
            hours += 12;
        }
        if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }

        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
    }

    const constraints = {
        title: {
            presence: { allowEmpty: false, message: "is required" },
        },
        description: {
            presence: { allowEmpty: false, message: "is required" },
        }
    };

    function addClassTypes(){
        setClassTypes((classTypes) => ([...classTypes, {
            title: '',
            capacity: 0
        }]))
    }

    function updateClassTypes(index, value){
        let updateClassType = classTypes.map((_v, _i) => _i == index ? value : _v);
        setClassTypes(updateClassType);
    }

    function removeClassType(index){
        let filterClassType = classTypes.filter((_v, _i) => _i !== index);
        setClassTypes(filterClassType);
    }

    if (!defaultValue) {
        return (
            <PageLoading />
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <NavHeader
                title="Edit class"
                breadcrumb={[
                    {
                        title: 'Edit Class'
                    }
                ]}
                backLink="/admin/classes"
                endAction={(
                    <Link to="/classes/create">
                        <Button
                            startIcon={<AddIcon />}
                            variant='contained'
                            color='primary'>
                            Create Class
                        </Button>
                    </Link>
                )}
            />
            <Box  component="form" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={8}>
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.title}>
                            <FormLabel>Class Title</FormLabel>
                            <TextField
                                name="title"
                                defaultValue={defaultValue?.title}
                                error={formError?.title}
                            />
                            {formError?.title ? <FormHelperText>{formError?.title[0]}</FormHelperText> : ""}
                        </FormControl>
                        <FormControl sx={{ width: "100%", mb: 3 }}>
                            <FormLabel>Class description</FormLabel>
                            <TextField
                                multiline
                                rows={3}
                                defaultValue={defaultValue?.description}
                                name="description"
                            />
                        </FormControl>
                        <FormControl sx={{ width: "100%", mb: 3 }}>
                            <FormLabel>Class note</FormLabel>
                            <TextField
                                multiline
                                rows={3}
                                name="class_note"
                            />
                        </FormControl>
                        <Box sx={{ mb: 3}}>
                            <Typography sx={{
                                fontWeight: 600,
                                textTransform: 'uppercase',
                                mb: 1
                            }}>
                                Class Types
                            </Typography>
                            {
                                Array.isArray(classTypes) && classTypes.length ?
                                    <Grid container spacing={3}>
                                        {
                                            classTypes.map((val, index) => {
                                                return (
                                                    <Grid  key={index} item xs={12} lg={6}>
                                                        <Box className="border border-black mb-3 p-3">
                                                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                                                <FormLabel>Title</FormLabel>
                                                                <TextField value={val?.title} onChange={(e) => updateClassTypes(index, {...val, title: e.target.value})} type="text"/>
                                                            </FormControl>
                                                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                                                <FormLabel>Description</FormLabel>
                                                                <TextField
                                                                    multiline
                                                                    rows={3} 
                                                                    value={val?.description} 
                                                                    onChange={(e) => updateClassTypes(index, {...val, description: e.target.value})} type="text"
                                                                />
                                                            </FormControl>
                                                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                                                <FormLabel>Default capacity</FormLabel>
                                                                <TextField value={val?.capacity} onChange={(e) => updateClassTypes(index, {...val, capacity: e.target.value})} type="number" />
                                                            </FormControl>
                                                            <Box sx={{ textAlign: 'right' }}>
                                                                <Button
                                                                    onClick={() => removeClassType(index)}
                                                                    startIcon={<DeleteIcon />}>
                                                                    Delete
                                                                </Button>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                    : ""
                            }
                            <Button
                                variant='contained'
                                onClick={() => addClassTypes()}>
                                Add class type
                            </Button>
                        </Box>
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.Capacity}>
                            <FormLabel>Capacity</FormLabel>
                            <TextField
                                name="capacity"
                                type="number"
                                defaultValue={defaultValue?.capacity}
                                error={formError?.capacity}
                            />
                            {formError?.capacity ? <FormHelperText>{formError?.capacity[0]}</FormHelperText> : ""}
                        </FormControl>
                        <FormControl>
                            <FormControlLabel
                                control={<Switch />}
                                checked={isPrivate}
                                onChange={() => setIsPrivate(!isPrivate)}
                                label="Is private class" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormControl sx={{ mb: 3, width: "100%" }}>
                            <FormLabel id="demo-multiple-name-label">Thumbnail</FormLabel>
                            <ThumbnailPicker value={thumbnail} onChange={(Image) => setThumbnail(Image)} />
                        </FormControl>
                        <FormControl>
                            <LoadingButton
                                variant='contained'
                                color='primary'
                                size='lg'
                                type='submit'
                                loading={isLoading}
                            >
                                Update class
                            </LoadingButton>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default UpdateClassPage
