import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import Logo from "@/assets/logo.svg";
import PageLoading from '@/components/PageLoading';

import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { secondaryListItems } from './listItems';
import { Outlet } from 'react-router-dom';
import { getToken, getDataUser } from '@/utils/slice/AuthSlice';
import { ChevronRight } from '@mui/icons-material';
import { Link } from 'react-router-dom';


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: 9,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(4),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export default function DashboardLayout() {

    const [open, setOpen] = useState(true);
    const { token, user, status } = useSelector((state) => state.auth);
    const [notifications, setNotifications] = useState([]);

    const navigate = useNavigate();

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if (!token) {
            dispatch(getToken());
        }

        dispatch(getDataUser(token));
    }, [token]);

    if (status !== 'complete') {
        return (
            <PageLoading />
        )
    }

    if (!token) {
        navigate('/login');
    }

    if (user && user.role !== 'trainer') {
        navigate('/');
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Drawer variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        px: [1],
                    }}>

                    {
                        open ? (
                            <Link to={"/trainer"} >
                                <img src={Logo} width={120} alt="" />
                            </Link>
                        ) : ""
                    }
                    <IconButton onClick={toggleDrawer}>
                        {
                            open ? (
                                <ChevronLeftIcon />
                            ) : (
                                <ChevronRight />
                            )
                        }
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    {secondaryListItems}
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Outlet />
            </Box>
        </Box>
    );
}