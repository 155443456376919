import { Box, Button, Grid, MenuItem, Select, Typography, } from '@mui/material'
import { useState, useEffect } from 'react'
import { createTrainerInformation, findOneTrainerInformation } from '@/utils/TrainerInformation'
import { useSelector } from 'react-redux'
import { useToasterContext } from '@/utils/ToasterContext'

import NavHeader from '@/components/NavHeader'
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import validate from 'validate.js'
import ThumbnailPicker from '@/components/ThumbnailPicker'

import { LoadingButton } from '@mui/lab'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import DeleteIcon from '@mui/icons-material/Delete';
import PageLoading from '@/components/PageLoading';
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid';

const CreateClass = () => {

    const { token } = useSelector((state) => state.auth);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);
    const [thumbnail, setThumbnail] = useState(false);
    const [defaultValue, setDefaultValue] = useState(false);
    const [TrainerSessions, setTrainerSessions] = useState([]);
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    async function handleCreate(data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = createTrainerInformation(token, data)
            if (response && !response.error) {
                const { data } = response;
                showMessage('success', 'Data created successfully!');
            }
        } catch (error) {
            showMessage('error', 'An error occurred, please try again later');
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    async function handleFindOne() {
        try {
            const response = await findOneTrainerInformation(token);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const {
                        full_name,
                        birthday,
                        gender,
                        email,
                        phone_number,
                        address,
                        certifications,
                        start_date,
                        status,
                        salary,
                        avatar,
                        thumbnail,
                        schedule
                    } = data;
                    setTrainerSessions(schedule ? JSON.parse(schedule) : []);
                    setThumbnail(thumbnail);
                    setDefaultValue({
                        full_name,
                        birthday,
                        gender,
                        email,
                        phone_number,
                        address,
                        certifications,
                        start_date,
                        status,
                        salary,
                        avatar,
                    });
                }
            }
        } catch (error) {
            console.error("Error: ", error);
        } finally {

        }
    }


    function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        try {
            const dataForm = {
                full_name: data.get('full_name'),
                birthday: data.get('birthday'),
                gender: data.get('gender'),
                email: data.get('email'),
                phone_number: data.get('phone_number'),
                address: data.get('address'),
                certifications: data.get('certifications'),
                start_date: data.get('start_date'),
                schedule: JSON.stringify(TrainerSessions),
                thumbnail: thumbnail?.id,
            }

            const error = validate(dataForm, constraints);
            if (!error) {
                handleCreate(dataForm);
            }
            console.log('error', error);
            setFormError(error);
        } catch (error) {
            console.error('error', error);
        }
    }

    const constraints = {
        full_name: {
            presence: { allowEmpty: false }
        },
        gender: {
            inclusion: ["male", "female", "other"]
        },
        email: {
            email: true
        },
        phone_number: {
            presence: { allowEmpty: false }
        }
    };

    useEffect(() => {
        handleFindOne();
    }, [])

    function handleAddSchedule() {
        setTrainerSessions((TrainerSessions) => ([
            ...TrainerSessions,
            {
                id: uuidv4(),
                start_time: "00:00:00",
                end_time: "00:00:00"
            }
        ]))
    }

    function handleUpdateSchedule(data) {
        const updateSessions = TrainerSessions.map((val) => val.id == data.id ? data : val);
        setTrainerSessions(updateSessions);
    }

    function handleDeleteSchedule(id) {
        const removeSessions = TrainerSessions.filter((_v) => _v.id !== id);
        setTrainerSessions(removeSessions);
    }

    if (!defaultValue) {
        return (
            <PageLoading />
        );
    }


    return (
            <Box sx={{ width: '100%' }}>
                <NavHeader
                    title="Update trainer"
                    breadcrumb={[
                        {
                            title: 'Update Information'
                        }
                    ]}
                />
                <Box sx={{ p: 3 }} component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={8}>
                            <Typography sx={{
                                fontWeight: 600,
                                textTransform: 'uppercase',
                                mb: 2
                            }}>
                                Your Information
                            </Typography>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.full_name}>
                                <FormLabel>Full Name</FormLabel>
                                <TextField
                                    name="full_name"
                                    error={formError?.full_name}
                                    defaultValue={defaultValue?.full_name}
                                />
                                {formError?.full_name ? <FormHelperText>{formError?.full_name[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                <FormLabel>Experience</FormLabel>
                                <TextField
                                    multiline
                                    rows={3}
                                    name="experience"
                                    defaultValue={defaultValue?.experience}
                                />
                            </FormControl>
                            <Grid container spacing={3} >
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.email}>
                                        <FormLabel>Email</FormLabel>
                                        <TextField
                                            name="email"
                                            type="email"
                                            error={formError?.email}
                                            defaultValue={defaultValue?.email}
                                        />
                                        {formError?.email ? <FormHelperText>{formError?.email[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.phone_number}>
                                        <FormLabel>Phone number</FormLabel>
                                        <TextField
                                            name="phone_number"
                                            type="tel"
                                            error={formError?.phone_number}
                                            defaultValue={defaultValue?.phone_number}
                                        />
                                        {formError?.phone_number ? <FormHelperText>{formError?.phone_number[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <FormControl sx={{ width: "100%", mb: 3 }}>
                                <FormLabel id="demo-simple-select-label">Gender</FormLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={defaultValue?.gender}
                                    name="gender"
                                >
                                    <MenuItem value={''}>None</MenuItem>
                                    <MenuItem value={'male'}>Male</MenuItem>
                                    <MenuItem value={'female'}>Female</MenuItem>
                                    <MenuItem value={'other'}>Other</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.certifications}>
                                <FormLabel>Certifications</FormLabel>
                                <TextField
                                    name="certifications"
                                    error={formError?.certifications}
                                    defaultValue={defaultValue?.certifications}
                                />
                                {formError?.certifications ? <FormHelperText>{formError?.certifications[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.address}>
                                <FormLabel>Address</FormLabel>
                                <TextField
                                    name="address"
                                    error={formError?.address}
                                    defaultValue={defaultValue?.address}
                                />
                                {formError?.address ? <FormHelperText>{formError?.address[0]}</FormHelperText> : ""}
                            </FormControl>
                            <Grid container spacing={3} >
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }}>
                                        <FormLabel>Birthday</FormLabel>
                                        <DatePicker
                                            name="birthday"
                                            defaultValue={defaultValue?.birthday ? dayjs(defaultValue.birthday) : dayjs()}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }}>
                                        <FormLabel>Start date</FormLabel>
                                        <DatePicker
                                            name="start_date"
                                            defaultValue={defaultValue?.start_date ? dayjs(defaultValue.start_date) : dayjs()}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Box>
                                <Typography sx={{
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    mb: 2
                                }}>
                                    Your Schedule
                                </Typography>
                                {
                                    Array.isArray(TrainerSessions) && TrainerSessions.length ?
                                        TrainerSessions.map((val, index) => {
                                            return (
                                                <Box key={index}>
                                                    <Grid container spacing={3} >
                                                        <Grid item xs={6}>
                                                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.start_time} required>
                                                                <FormLabel>Start hour</FormLabel>
                                                                <TimePicker
                                                                    name="start_time"
                                                                    value={val?.start_time ? dayjs(val.start_time, "HH:mm:ss") : dayjs("00:00:00", "HH:mm:ss")}
                                                                    onChange={(value) => handleUpdateSchedule({ ...val, start_time: dayjs(value).format("HH:mm:ss") })}
                                                                />
                                                                {formError?.start_time ? <FormHelperText>{formError?.start_time[0]}</FormHelperText> : ""}
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.end_time} required>
                                                                <FormLabel>End hour</FormLabel>
                                                                <TimePicker
                                                                    name="end_time"
                                                                    value={val?.end_time ? dayjs(val.end_time, "HH:mm:ss") : dayjs("00:00:00", "HH:mm:ss")}
                                                                    onChange={(value) => handleUpdateSchedule({ ...val, end_time: dayjs(value).format("HH:mm:ss") })}
                                                                />
                                                                {formError?.end_time ? <FormHelperText>{formError?.end_time[0]}</FormHelperText> : ""}
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Box sx={{ textAlign: 'right' }}>
                                                        <Button
                                                            onClick={() => handleDeleteSchedule(data.id)}
                                                            startIcon={<DeleteIcon />}>
                                                            Delete schedule
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            )
                                        })
                                        : ""
                                }
                                <Button
                                    variant='contained'
                                    onClick={() => handleAddSchedule()}>
                                    Add schedule
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <FormControl sx={{ mb: 3, width: "100%" }}>
                                <FormLabel id="demo-multiple-name-label">Thumbnail</FormLabel>
                                <ThumbnailPicker value={thumbnail} onChange={(Image) => setThumbnail(Image)} />
                            </FormControl>
                            <FormControl>
                                <LoadingButton
                                    variant='contained'
                                    color='secondary'
                                    size='lg'
                                    type='submit'
                                    loading={isLoading}
                                >
                                    Update data
                                </LoadingButton>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
    )
}

export default CreateClass
