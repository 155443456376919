import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import validate from 'validate.js';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { userRegister } from '@/utils/Auth';
import { FormControl, FormHelperText } from '@mui/material';
import { useToasterContext } from "@/utils/ToasterContext";
import { getToken, getDataUser } from "@/utils/slice/AuthSlice";
import { useSelector, useDispatch } from 'react-redux'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import PageLoading from '@/components/PageLoading';
import dayjs from 'dayjs';

export default function SignIn() {

    const [formError, setFormError] = useState(false);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const { token, user, status } = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!token) {
            dispatch(getToken());
        }
        dispatch(getDataUser(token));
    }, [token]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const dataForm = {
            email: data.get('email'),
            first_name: data.get('first_name'),
            last_name: data.get('last_name'),
            date_of_birth: dayjs(data.get('date_of_birth'), "DD/MM/YYYY").format("YYYY-MM-DD"),
            password: data.get('password'),
            password_confirmation: data.get('password_confirmation'),
        }

        const error = validate(dataForm, constraints);
        if (!error) {
            try {
                startProgress();
                setIsLoading(true);
                const response = await userRegister(dataForm);
                console.log('response', response);
                if (response && !response.error) {
                    showMessage('success', 'Account registration successful');
                    navigate('/login');
                } else {
                    showMessage('error', 'Your account has been taken');
                }
            } catch (error) {
                showMessage('error', error);
            } finally {
                completeProgress();
                setIsLoading(false);
            }
        } else {
            showMessage('error', "One or more input fields are not valid");
        }

        setFormError(error);
    };

    const constraints = {
        first_name: {
            presence: {
                allowEmpty: false,
                message: t('^Your first name cannot be blank')
            },
        },
        last_name: {
            presence: {
                allowEmpty: false,
                message: t('^Your last name cannot be blank')
            },
        },
        date_of_birth: {
            presence: {
                allowEmpty: false,
                message: t('^Your birthday cannot be blank')
            },
        },
        email: {
            presence: {
                allowEmpty: false,
                message: t('^Email cannot be blank')
            },
            email: {
                message: t('^Invalid email')
            }
        },
        password: {
            presence: {
                allowEmpty: false,
                message: t('^Password cannot be blank')
            },
            length: {
                minimum: 6,
                message: t('^Password must have at least 6 characters')
            }
        },
        password_confirmation: {
            presence: {
                allowEmpty: false,
                message: t('^Password cannot be blank')
            },
            length: {
                minimum: 6,
                message: t('^Password must have at least 6 characters')
            },
            equality: {
                attribute: 'password',
                message: t('^The confirmation password must match the entered password')
            }
        }
    };

    if (status !== 'complete') {
        return (
            <PageLoading />
        )
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >

                <Box sx={{ p: 3 }} component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <Typography sx={{ fontSize: 24, fontWeight: 600 }}>
                        Register
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6}>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.first_name} required>
                                <TextField
                                    name="first_name"
                                    required
                                    label={t("First name")}
                                    error={formError?.first_name}
                                />
                                {formError?.first_name ? <FormHelperText>{formError?.first_name[0]}</FormHelperText> : ""}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.last_name} required>
                                <TextField
                                    name="last_name"
                                    label={t("Last name")}
                                    required
                                    error={formError?.last_name}
                                />
                                {formError?.last_name ? <FormHelperText>{formError?.last_name[0]}</FormHelperText> : ""}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <FormControl sx={{ width: "100%", mb: 1 }} error={formError?.date_of_birth} required>
                        <DatePicker
                            label={t("Date of birth")}
                            name="date_of_birth"
                            required
                            value={dayjs()}
                        />
                        {formError?.date_of_birth ? <FormHelperText>{formError?.date_of_birth[0]}</FormHelperText> : ""}
                    </FormControl>
                    <FormControl error={formError?.email} sx={{ width: "100%" }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            error={formError?.email}
                        />
                        {formError?.email ? <FormHelperText>{formError?.email[0]}</FormHelperText> : ""}
                    </FormControl>
                    <FormControl error={formError?.password} sx={{ width: "100%" }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            error={formError?.password}
                        />
                        {formError?.password ? <FormHelperText>{formError?.password[0]}</FormHelperText> : ""}
                    </FormControl>
                    <FormControl error={formError?.password} sx={{ width: "100%" }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password_confirmation"
                            label="Repeat password"
                            type="password"
                            id="password_confirmation"
                            autoComplete="password_confirmation"
                            error={formError?.password_confirmation}
                        />
                        {formError?.password_confirmation ? <FormHelperText>{formError?.password_confirmation[0]}</FormHelperText> : ""}
                    </FormControl>
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        loading={isLoading}
                        color='secondary'
                    >
                        Sign up
                    </LoadingButton>
                    <Grid container>
                        <Grid item xs>
                            <Link to="/reset-password" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to="/login" variant="body2">
                                {"Have an account? Sign In"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}