import { instance } from "./axiosInstance";

export async function getSetings(token, params) {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: params
    };
    const response = await instance
        .get("/settings", config)
        .then((res) => res.data)
        .catch(() => {
            return [];
        });
    return response;
}

export async function updateSettings(token, data) {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await instance
        .post("/settings", data, config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}