import { instance } from "./axiosInstance";

export async function refundTransaction(token, id) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    };
    const response = await instance
        .post("/transactions/" + id + "/refund", {}, config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}

export async function deleteTransaction(token, id) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    const response = await instance
        .delete("/transactions/" + id, config)
        .then((res) => {
            return res?.data;
        }).catch(function () {
            return null;
        });
    return response;
}