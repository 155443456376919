import dayjs from 'dayjs'
import React from 'react'


var utc = require("dayjs/plugin/utc");

dayjs.extend(utc);

const DateCell = ({ time }) => {
  return (
    <div>
      <p>{time ? dayjs.utc(time).format("HH:mm:ss, DD/MM/YYYY") : "-"}</p>
    </div>
  )
}

export default DateCell