import { instance } from "./axiosInstance";

export async function findManyCarts(token) {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await instance
        .get("/cart", config)
        .then((res) => res.data)
        .catch(() => {
            return [];
        });
    return response;
}

export async function findOneCart(id) {
    const response = await instance
        .get(`/cart/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}

export async function createCart(token, data) {
    if (!token) {
        return location.href = "/login";
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    const response = await instance
        .post("/cart", data, config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}

export async function deleteCart(token, id) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    const response = await instance
        .delete("/cart/" + id, config)
        .then((res) => {
            return res?.data;
        }).catch(function () {
            return null;
        });
    return response;
}

export async function deleteCarts(token, data) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: {
            ids: data
        }
    };
    const response = await instance
        .delete("/cart", config)
        .then((res) => {
            return res?.data;
        }).catch(function () {
            return null;
        });
    return response;
}