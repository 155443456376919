import React from 'react'
import { Box, Tooltip } from '@mui/material'
import { IoAlertCircle } from "react-icons/io5";

const Info = ({ title, placement = "top" }) => {
  return (
    <Tooltip title={title} placement="top">
        <Box style={{ display: "inline-block"}}>
            <IoAlertCircle color="#8d8d8d"/>
        </Box>
    </Tooltip>
  )
}

export default Info