import React, { Fragment, useEffect, useState } from 'react'
import { useToasterContext } from '@/utils/ToasterContext';
import { Link, useParams } from 'react-router-dom';
import { findOneUserOrder } from '@/utils/UserInfomations';
import { useSelector } from 'react-redux';
import { numberWithCommas } from '@/utils/Hooks';
import { FormControl, FormLabel } from '@mui/material';
import { Button, List, ListItem } from '@mui/material';
import { createTransaction } from '@/utils/Orders';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { getSetings } from '@/utils/Settings';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import PageLoading from '@/components/PageLoading';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import ATM from "@/assets/64x64-atm.svg";
import CreditLine from "@/assets/176x24-credit.svg";
import Credit from "@/assets/64x64-atm.svg";
import Qrcode from "@/assets/64x64-vi-vnpay.svg";
import Bank from "@/assets/64x64-bank.svg";
import classNames from 'classnames';

const UserOrders = ({ }) => {

    const { id } = useParams();
    const { t } = useTranslation();
    const { token } = useSelector((state) => state.auth);
    const [data, setData] = useState({});
    const [pageStatus, setPageStatus] = useState('idle');
    const [cartTotal, setCartTotal] = useState(false);
    const [cart, setCart] = useState(false);
    const { startProgress, completeProgress, currentLanguage } = useToasterContext();
    const [isLoaded, setIsLoaded] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('vietqr');
    const [paymentType, setPaymentType] = useState(false);
    const [loadingTransaction, setLoadingTransaction] = useState(false);

    const [promotion, setPromotion] = useState(false);
    const [promotionCode, setPromotionCode] = useState("");
    const [promotionAmount, setPromotionAmount] = useState(0);

    const [qrData, setQrData] = useState({
        bank_id: "",
        bank_account_name: "",
        bank_account_number: "",
        bank_account_description: "",
    });

    const handleImageLoad = () => {
        setIsLoaded(true);
    };

    async function handleGetSettings() {
        try {
            startProgress();
            const response = await getSetings(token, {
                settings: [
                    "bank_id",
                    "bank_account_name",
                    "bank_account_number",
                    "bank_account_description"
                ],
            });
            if (Array.isArray(response) && response.length) {
                let updateData = {};
                response.map((val) => {
                    updateData[val.setting_key] = val.setting_value
                });
                setQrData((qrData) => ({
                    ...qrData,
                    ...updateData
                }));
            }
        } catch (error) {
            console.error('error', error);
        } finally {
            completeProgress();
            setIsLoaded(true);
        }
    }

    const handleFindData = async () => {
        try {
            startProgress();
            const response = await findOneUserOrder(token, id);
            if (response && !response.error) {
                const { data } = response;
                if(data){
                    const { memberships, payment_method, payment_type, promotion } = data;
                    setPromotion(promotion)
                    setData(data);
                    setCart(memberships);
                    setPaymentMethod(payment_method);
                    setPaymentType(payment_type);
                } 
            }
            setPageStatus("complete");
        } catch (error) {

        } finally {
            completeProgress();
        }
    }

    const handleCreateTransaction = async () => {
        try {
            setLoadingTransaction(true);
            const data = {
                payment_method: paymentMethod,
                payment_type: paymentType,
            };
            const response = await createTransaction(id, data);
            if(response && !response.error){
                const { type, url } = response;
                if(url){
                   location.href = url
                }
            }
        } catch (error) {
            console.error('error', error);
        } finally {
            setLoadingTransaction(false)
        }
    }

    useEffect(() => {
        if (token) {
            handleGetSettings();
            handleFindData();
        }
    }, [token]);

    useEffect(() => {
        let totalPrice = 0;
        let promotionAmount = 0;
        if (Array.isArray(cart) && cart?.length) {
            cart.map((value) => {
                console.log('value', value);
                let price = value?.regular_price * value.quantity;
                if(promotion){
                    const { for_all_memberships, promotion_amount, memberships } = promotion;
                    let validatePromotion = false;
                    if (!for_all_memberships) {
                        if (Array.isArray(memberships) && memberships?.length) {
                            validatePromotion = memberships.find((_v) => _v.id == value?.pivot?.membership_id);
                        }
                    } else {
                        validatePromotion = true;
                    }
                
                    if (validatePromotion) {
                        promotionAmount += price * promotion_amount / 100;
                    }
                }
                totalPrice += price;
                return value;
            });
        }
        setCartTotal(totalPrice);
        setPromotionAmount(promotionAmount);
    }, [cart, promotion])

    const CartItem = ({ data, promotion }) => {
        {

            console.log('data', data);
            let validatePromotion = false;
            let promotionAmount = 0;

            if(promotion){
                const { for_all_memberships, promotion_amount, memberships } = promotion;
                promotionAmount = promotion_amount;
                if(!for_all_memberships){
                    if(Array.isArray(memberships) && memberships?.length) {
                        validatePromotion = memberships.find((val) => val?.id == data?.id)
                    }
                } else {
                    validatePromotion = true;
                }
            }
            
            const { id, title, description, regular_price } = data;
            const { quantity } = data;

            return (
                <ListItem
                    key={id}
                    className={'border-b border-dashed border-slate-200'}>
                    <Box sx={{ flex: 1 }}>
                        <Typography sx={{ fontWeight: 600, mb: 1 }}>
                            {t(title)}
                        </Typography>
                        <Typography sx={{ mb: 1, fontSize: 14, whiteSpace: "pre-wrap" }}>
                            {t(description)}
                        </Typography>
                        <Typography>
                            {regular_price ? numberWithCommas(regular_price) + "₫" : "-"} x {quantity}
                        </Typography>
                        {
                            validatePromotion ? (
                               <Box>
                                    <Typography>
                                        <strong className='mr-2'>{t("Subtotal")}:</strong> { `${numberWithCommas((regular_price * quantity) - (regular_price * quantity) * promotionAmount / 100)}₫` } <span className="bg-black text-white px-1 text-xs">{`${t("-")} ${promotionAmount}%`}</span>
                                    </Typography>
                                </Box>
                            ) : (
                                <Typography>
                                    <strong className='mr-2'>{t("Subtotal")}:</strong>
                                    {numberWithCommas(regular_price * quantity)}₫
                                </Typography>
                            )
                        }
                    </Box>
                </ListItem>
            )

        }
    }

    if (pageStatus != 'complete') {
        return (
            <PageLoading />
        )
    }

    function objectToQueryString(obj) {
        const params = new URLSearchParams();
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                params.append(key, obj[key]);
            }
        }
        return "https://api.vietqr.io/image/" + qrData?.bank_id + "-" + qrData?.bank_account_number + "-ZiYkjNj.jpg?" + params.toString();
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Grid container spacing={4} sx={{ width: '100%' }}>
                <Grid xs={12} lg={6}>
                <List>
                        {
                            Array.isArray(cart) && cart?.length ? (
                                <Box sx={{ borderColor: "#000", borderWidth: 1, px: 3}}>
                                    <List sx={{ mb: 2}}>
                                        {
                                            cart.map((val) => {
                                                return (
                                                    <CartItem id={val.id} data={val} promotion={promotion} />
                                                )
                                            })
                                        }
                                    </List>
                                    {
                                        promotion ? (
                                            <Fragment>
                                                <Typography sx={{ mt: 2 }}>
                                                    {t("Promotion")}:
                                                    <strong className='ml-2'>{promotion?.promotion_code}</strong>
                                                </Typography>
                                                <Typography>
                                                    {t("Subtotal")}:
                                                    <span className='ml-2'>{numberWithCommas(cartTotal)}₫</span>
                                                </Typography>
                                                <Typography>
                                                    {t("Discount")}:
                                                    <span className='ml-2'>{numberWithCommas(promotionAmount)}₫</span>
                                                </Typography>
                                                <Typography sx={{ mb: 3 }}>
                                                    {t("Total")}:
                                                    <strong className='ml-2'>{numberWithCommas((cartTotal - promotionAmount))}₫</strong>
                                                </Typography>
                                            </Fragment>
                                        ) : <Typography sx={{ mt: 2, mb: 3 }}>
                                                {t("Total")}:
                                                <strong className='ml-2'>{numberWithCommas(cartTotal)}₫</strong>
                                            </Typography>
                                    }
                                </Box>
                            ) : (
                                <Box sx={{ textAlign: "center", py: 3 }}>
                                    <AddShoppingCartIcon sx={{ width: 80, height: 80, mb: 1 }} />
                                    <Typography sx={{ mb: 3 }}>{t("Cart empty")}</Typography>
                                    <Link to="/memberships/">
                                        <Button variant='contained' color="secondary">
                                            {t("Return to memberships")}
                                        </Button>
                                    </Link>
                                </Box>
                            )
                        }
                    </List>
                </Grid>
                <Grid xs={12} lg={6}>
                    <List sx={{ mb: 3 }}>
                        <ListItem
                            className=''>
                            <Box className="flex-1">
                                <FormControl sx={{ width: "100%", mb: 3 }}>
                                    <FormLabel>{t("Payment method")}</FormLabel>
                                    <div 
                                        onClick={() => {
                                            setPaymentMethod("vietqr");
                                        }}
                                        className={classNames({
                                        'flex gap-3 cursor-pointer bg-white border border-slate-200 mb-3 p-1 items-center': true,
                                        'bg-green-50 border !border-green-600 text-green-600 shadow': paymentMethod == 'vietqr'
                                    })}>
                                        <div className='flex-1'>
                                            <p>{t("Bank transfer")}</p>
                                        </div>
                                        <div>
                                            <img width={34} height={34} src={Bank}/>
                                        </div>
                                    </div>
                                    <div 
                                        onClick={() => {
                                            setPaymentMethod("vnpay");
                                            setPaymentType("VNPAYQR");
                                        }}
                                        className={classNames({
                                        'flex gap-3 cursor-pointer bg-white border border-slate-200 mb-3 p-1 items-center': true,
                                        'bg-green-50 border !border-green-600 text-green-600 shadow': paymentMethod == 'vnpay' && paymentType == 'VNPAYQR'
                                    })}>
                                        <div className='flex-1'>
                                        <p>{t("Payment code scanning application supported by VNPAY")}</p>
                                        </div>
                                        <div>
                                            <img width={34} height={34} src={Qrcode}/>
                                        </div>
                                    </div>
                                    <div 
                                        onClick={() => {
                                            setPaymentMethod("vnpay");
                                            setPaymentType("VNBANK");
                                        }}
                                        className={classNames({
                                        'flex gap-3 cursor-pointer bg-white border border-slate-200 mb-3 p-1 items-center': true,
                                        'bg-green-50 border !border-green-600 text-green-600 shadow': paymentMethod == 'vnpay' && paymentType == 'VNBANK'
                                    })}>
                                        <div className='flex-1'>
                                            <p>{t("Domestic card and bank account")}</p>
                                    </div>
                                    <div>
                                        <img width={34} height={34} src={ATM}/>
                                    </div>
                                    </div>
                                    <div 
                                            onClick={() => {
                                                setPaymentMethod("vnpay");
                                                setPaymentType("INTCARD");
                                            }}
                                            className={classNames({
                                            'flex gap-3 cursor-pointer bg-white border border-slate-200 mb-3 p-1 items-center hidden': true,
                                            'bg-green-50 !border !border-green-600 text-green-600 shadow': paymentMethod == 'vnpay' && paymentType == 'INTCARD'
                                        })}>
                                        <div className='flex-1'>
                                            <p>{t("International payment card")}</p>
                                            <img src={CreditLine}/>
                                        </div>
                                        <div>
                                            <img width={34} height={34} src={Credit}/>
                                        </div>
                                    </div>
                                </FormControl>
                                {
                                    paymentMethod == 'vietqr' ? (
                                        <Box>
                                            <Typography sx={{ mb: 1 }}>
                                                {t("Use an e-wallet or banking app to scan the payment code for the order")}
                                            </Typography>
                                            <Box>
                                                {!isLoaded && <p>Loading...</p>}
                                                {
                                                    cartTotal ?
                                                        <img
                                                            style={{ display: isLoaded ? 'block' : 'none' }}
                                                            onLoad={handleImageLoad}
                                                            src={objectToQueryString({
                                                                accountName: "Cong Ty Move Fitness",
                                                                amount: cartTotal,
                                                                addInfo: `MOVE${data?.id}`
                                                            })} /> : ""
                                                }
                                                <div className="border border-slate-200 bg-slate-50 p-3 mb-3">
                                                    <p className='font-bold'>MOVE FITNESS COMPANY LIMITED</p>
                                                    <p>{t("Bank Name")}: <strong>Techcombank</strong></p>
                                                    <p>{t("Account Number")}: <strong>881122</strong></p>
                                                    <p>{t("Total")}: <strong>{numberWithCommas(cartTotal)}đ</strong></p>
                                                    <p>{t("Payment message")}: <strong>Move{id}</strong></p>
                                                </div>
                                                <Typography sx={{ fontSize: 14 }}>
                                                    <span className="text-red-600">*</span>
                                                    {t("Please use the provided payment details for a manual transfer. We will promptly update the payment status and send an order confirmation email to your sign-in email address. Thank you.")}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ) : ""
                                }
                                {
                                    paymentMethod == 'vnpay' ? (
                                        <Box>
                                            <LoadingButton 
                                                loading={loadingTransaction}
                                                onClick={handleCreateTransaction}
                                                variant='contained'
                                                color='secondary'>
                                                {t("Process to payment gateway")}
                                            </LoadingButton>
                                        </Box>
                                    ) : ""
                                }
                            </Box>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Box >
    );
}

export default UserOrders
