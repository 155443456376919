import React, { useEffect, useState } from 'react';
import { Typography, Box, Tabs, Tab, tabsClasses } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import classNames from 'classnames';
import Info from '@/components/Info';

const WeekView = ({ data, step }) => {
    const { t } = useTranslation();
    const [weekDays, setWeekDays] = useState([]);
    const [weekData, setWeekData] = useState({
        sun: [], mon: [], tue: [], wed: [], thu: [], fri: [], sat: []
    });
    const [value, setValue] = useState(dayjs().format('ddd').toLowerCase());
    const navigate = useNavigate();

    useEffect(() => {
        if(step > 0){
            setValue(dayjs().startOf('week').format('ddd').toLowerCase())
        }
        const startOfWeek = dayjs().startOf('week').add(step, 'week'); 
        setWeekDays(Array.from({ length: 7 }, (_, i) => 
        startOfWeek.add(i, 'day').format('DD/MM')
        ));
    }, [step]);

    useEffect(() => {
        const updatedWeekData = {
            sun: [], mon: [], tue: [], wed: [], thu: [], fri: [], sat: []
        };

        data?.forEach(item => {
            const dayKey = dayjs(item.start_time).format('ddd').toLowerCase();
            updatedWeekData[dayKey]?.push(item);
        });

        setWeekData(updatedWeekData);
    }, [data]);

    const handleChange = (event, newValue) => setValue(newValue);

    return (
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                aria-label="visible arrows tabs example"
                sx={{
                    mb: 3,
                    '.MuiTabs-scrollButtons': { display: "flex !important" },
                    '.Mui-selected': { color: "#000 !important" },
                    '.MuiTabs-indicator': { background: "#000 !important" },
                }}>
                {Object.keys(weekData).map((dayKey, index) => (
                    <Tab
                        key={dayKey}
                        label={<Typography sx={{ fontWeight: 600 }}>{t(dayKey)}</Typography>}
                        icon={<Typography sx={{ fontSize: 14 }}>{weekDays[index]}</Typography>}
                        color='secondary'
                        iconPosition='bottom'
                        value={dayKey}
                        sx={{ flex: 1 }}
                    />
                ))}
            </Tabs>
            {weekData[value]?.length ? weekData[value].sort((a, b) => dayjs(a.start_time).unix() - dayjs(b.start_time).unix()).map(({ id, trainer, start_time, is_free_session, duration_minutes, class: classInfo, class_types, users, is_private }) => {
                const start = dayjs(start_time);
                const isActive = dayjs().isBefore(start);

                return (
                    <div
                        key={id}
                        className={classNames({
                            'border relative border-black mb-3 hover:bg-neutral-200 hover:shadow duration-200 group ease-in-out': true,
                            'cursor-not-allowed': !isActive,
                            'cursor-pointer hover:!bg-black hover:text-white': isActive
                        })}
                        onClick={() => isActive && navigate(`/sessions/${id}`)}
                        sx={{ mb: 2, fontSize: 13 }}>
                        
                        <Box sx={{ p: 2 }}>
                            <div className='absolute flex gap-1 items-center top-3 right-3'>
                                {!isActive ? <span className='border group-hover:border-white border-black text-xs px-2 font-bold uppercase'>{t("Passed")}</span> : ""}
                            </div>
                            {
                                classInfo ?
                                    <Typography sx={{ fontSize: 24 }} className='font-bold  uppercase'>
                                        <strong>{class_types ? class_types : classInfo?.title}</strong>
                                    </Typography>
                                    : ""
                            }
                            {trainer && <Typography>{t("with")} <span className='font-bold uppercase'>{trainer.full_name}</span></Typography>}
                            {start && (
                                <Typography>
                                    {t("on")} <span className='font-bold uppercase'>{start.format('ddd, DD/MM')}</span> {t("at")} <span className='font-bold uppercase'>{start.format('HH:mm')}</span>
                                </Typography>
                            )}
                            {duration_minutes && (
                                <Typography>
                                    {t("Duration")}: <span className='font-bold uppercase'>{duration_minutes} {t("minutes")}</span>
                                </Typography>
                            )}
                            {    is_free_session ? (
                                    <Typography>
                                        {t("Free to join")} <Info title={t("You can register for this session without having to sign up for any membership plans")}/>
                                    </Typography>
                                ) : ""
                            }
                        </Box>
                    </div>
                );
            }) : (
                <Box className="py-3">
                    <Typography sx={{ textAlign: "center" }}>{t("Empty session")}</Typography>
                </Box>
            )}
        </Box>
    );
};

export default WeekView;