import React, { useState } from 'react'
import MediaPicker from './MediaPicker'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import { Box, Button, IconButton, Typography } from '@mui/material';

const APP_URL = process.env.REACT_APP_APP_URL;

const ThumbnailPicker = ({ value, onChange }) => {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <Box
                className="flex bg-blue-100 p-3 justify-center text-center items-center border-2 border-dashed border-slate-300 text-slate-400 rounded py-[35px]">
                {
                    value ? (
                        <Box className="relative max-w-[260px] mx-auto">
                            <div className="bg-white mb-3">
                                <img
                                    src={APP_URL + "/" + value?.file_path}
                                    className='h-auto w-full object-cover border border-slate-200'
                                />
                                <Typography className='line-clamp-1 py-2 !text-[12px]'>
                                    {value?.file_name}
                                </Typography>
                            </div>
                            <IconButton
                                variant='contained'
                                color='error'
                                className='!absolute !bg-white !top-0 !right-0 !z-10'
                                onClick={() => onChange(false)}>
                                <DeleteForeverIcon />
                            </IconButton>
                        </Box>
                    ) : (
                        <Box>
                            <Button
                                variant='contained'
                                sx={{ borderRadius: 0 }}
                                startIcon={<AddPhotoAlternateIcon />}
                                onClick={() => setOpen(true)}>
                                Select image
                            </Button>
                        </Box>
                    )
                }
            </Box>
            <MediaPicker open={open} setOpen={setOpen} onSelect={(Image) => onChange(Image)} />
        </div >
    )
}

export default ThumbnailPicker
