import { instance } from "./axiosInstance";

export async function findManyMedia(token, params = {}) {

    const config = {
        params: params,
        headers: {
            Authorization: `Bearer ${token}`
        }
    };

    const response = await instance
        .get("/trainer/media", config)
        .then((res) => res.data)
        .catch(() => {
            return [];
        });

    return response;
}

export async function findOneMedia(id) {
    const response = await instance
        .get(`/trainer/media/${id}`)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}

export async function uploadMedia(token, data, onProgress) {
    if (!token) {
        return null;
    }
    let config = {
        timeout: 60 * 4 * 1000,
        maxContentLength: Infinity,
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data"
        }
    };

    if (onProgress) {
        config = {
            ...config,
            onUploadProgress: (progressEvent) => {
                onProgress(progressEvent);
            }
        };
    }
    const response = await instance
        .post("/trainer/media/upload", data, config);
    return response.data;
}

export async function updateMedia(token, id, data) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
    };

    const response = await instance
        .post(`/trainer/media/${id}`, data, config)
        .then((res) => {
            return res?.data;
        }).catch(function () {
            return null;
        });
    return response;
}

export async function deleteMedia(token, id) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
    };
    const response = await instance.delete(`/trainer/media/${id}`, config)
        .then((res) => {
            return res?.data;
        }).catch(function () {
            return null;
        });
    return response;
}

