
import { useState, useEffect, Fragment } from 'react';
import { updateMembership, findOneMembership } from '@/utils/Memberships';
import { findManyMembershipCategories } from '@/utils/MembershipCategories';
import { findManyClasses } from '@/utils/Classes';
import { useSelector } from 'react-redux';
import { useToasterContext } from '@/utils/ToasterContext'
import { LoadingButton } from '@mui/lab'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, FormControlLabel, Stack, Switch } from '@mui/material';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import NavHeader from '@/components/NavHeader';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import validate from 'validate.js'
import PostPicker from '@/components/PostPicker'
import PageLoading from '@/components/PageLoading'
import SelectedCell from '@/components/SelectedCell'
import Drawer from '@mui/material/Drawer';

const CreateMemberShipPage = () => {

    const { id } = useParams();
    const { token } = useSelector((state) => state.auth);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [classes, setClasses] = useState([]);
    const [categories, setCategories] = useState([]);
    const [defaultValue, setDefaultValue] = useState(false);
    const [formError, setFormError] = useState(false);
    const [isPrivate, setIsPrivate] = useState(0);
    const [canBeShared, setCanBeShared] = useState(0);
    const [translations, setTranslations] = useState({
        'title': '',
        'description': '',
        'note': ''
    });

    const [openDrawer, setOpenDrawer] = useState(false);

    const navigate = useNavigate();

    async function handleFindOne() {
        try {
            const response = await findOneMembership(token, id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const {
                        title,
                        description,
                        note,
                        regular_price,
                        sale_price,
                        duration_days,
                        sessions,
                        max_sessions_per_month,
                        academic_classes,
                        membership_categories,
                        translations,
                        is_private,
                        can_be_share
                    } = data;
                    setTranslations(translations ? JSON.parse(translations): {})
                    setClasses(academic_classes);
                    setCategories(membership_categories);
                    setIsPrivate(is_private);
                    setCanBeShared(can_be_share);
                    setDefaultValue({
                        title,
                        description,
                        note,
                        regular_price,
                        sale_price,
                        duration_days,
                        sessions,
                        max_sessions_per_month
                    });
                }
            }
        } catch (error) {

        } finally {

        }
    }

    function handleChangeTranslation(name, value){
        setTranslations((translations) => ({
            ...translations,
            [name]: value
        }))
    }

    async function handleUpdate(token, id, data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await updateMembership(token, id, data);
            if (response && !response.error) {
                const { data } = response;
                showMessage('success', 'Data updated successfully!');
                if (data?.id) {
                    navigate("/admin/memberships/" + data.id);
                }
            }
        } catch (error) {
            showMessage('error', 'An error occurred, please try again later!');
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let classArray = classes.map((val) => val?.id);
        let categoryAttr = categories.map(val => val.id);

        const dataForm = {
            title: data.get('title'),
            description: data.get('description'),
            note: data.get('note'),
            regular_price: data.get('regular_price'),
            sale_price: data.get('sale_price'),
            duration_days: data.get('duration_days'),
            sessions: data.get('sessions'),
            max_sessions_per_month: data.get('max_sessions_per_month'),
            is_private: isPrivate ? 1 : 0,
            can_be_share: canBeShared ? 1 : 0,
            translations: JSON.stringify(translations),
            academic_classes: classArray,
            categories: categoryAttr
        }

        const error = validate(dataForm, constraints);

        if (!error) {
            handleUpdate(token, id, dataForm)
        } else {
            console.log('error', error);
            showMessage('error', "One or more input fields are invalid!");
        }

        setFormError(error);
    }

    const constraints = {
        title: {
            presence: true,
        },
        description: {
            presence: true,
        }
    };

    useEffect(() => {
        handleFindOne();
    }, [])


    if (!defaultValue) {
        return (
            <PageLoading />
        );
    }

    return (
        <Fragment>
            <Box sx={{ width: '100%' }}>
                <NavHeader
                    title="Update Membership"
                    breadcrumb={[
                        {
                            title: 'Memberships',
                            link: '/admin/memberships/'
                        },
                        {
                            title: 'Update Membership'
                        }
                    ]}
                    backLink="/admin/memberships"
                />
                <Box component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={8}>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.title}>
                                <FormLabel>Membership Title</FormLabel>
                                <TextField
                                    name="title"
                                    error={formError?.title}
                                    defaultValue={defaultValue?.title}
                                />
                                {formError?.title ? <FormHelperText>{formError?.title[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.description}>
                                <FormLabel>Membership description</FormLabel>
                                <TextField
                                    multiline
                                    rows={3}
                                    name="description"
                                    defaultValue={defaultValue?.description}
                                />
                                {formError?.description ? <FormHelperText>{formError?.description[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl>
                                <FormControlLabel
                                    control={<Switch />}
                                    checked={canBeShared}
                                    onChange={() => setCanBeShared(!canBeShared)}
                                    label="Can be shared (Allow user book multiple seats for 1 session)" 
                                />
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.note}>
                                <FormLabel>Membership note</FormLabel>
                                <TextField
                                    multiline
                                    rows={3}
                                    name="note"
                                    defaultValue={defaultValue?.note}
                                />
                                {formError?.note ? <FormHelperText>{formError?.note[0]}</FormHelperText> : ""}
                            </FormControl>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.regular_price}>
                                        <FormLabel>Regular price</FormLabel>
                                        <TextField
                                            type="number"
                                            name="regular_price"
                                            defaultValue={defaultValue?.regular_price}
                                        />
                                        {formError?.regular_price ? <FormHelperText>{formError?.regular_price[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.sale_price}>
                                        <FormLabel>Sale price</FormLabel>
                                        <TextField
                                            type="number"
                                            name="sale_price"
                                            defaultValue={defaultValue?.sale_price}
                                        />
                                        {formError?.sale_price ? <FormHelperText>{formError?.sale_price[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.duration_days}>
                                <FormLabel>Duration days</FormLabel>
                                <TextField
                                    name="duration_days"
                                    type="number"
                                    error={formError?.duration_days}
                                    defaultValue={defaultValue?.duration_days}
                                />
                                {formError?.duration_days ? <FormHelperText>{formError?.duration_days[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.sessions}>
                                <FormLabel>Sessions</FormLabel>
                                <TextField
                                    name="sessions"
                                    type="number"
                                    error={formError?.sessions}
                                    defaultValue={defaultValue?.sessions}
                                />
                                {formError?.sessions ? <FormHelperText>{formError?.sessions[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.max_sessions_per_month}>
                                <FormLabel>Max session booked per month</FormLabel>
                                <TextField
                                    name="max_sessions_per_month"
                                    type="number"
                                    error={formError?.max_sessions_per_month}
                                    defaultValue={defaultValue?.max_sessions_per_month}
                                />
                                {formError?.max_sessions_per_month ? <FormHelperText>{formError?.max_sessions_per_month[0]}</FormHelperText> : ""}
                            </FormControl>
                            <Box sx={{ mb: 1 }}>
                                <PostPicker
                                    label="Select Academic classes"
                                    name="academic_classes"
                                    defaultData
                                    getApi={findManyClasses}
                                    values={classes}
                                    onChange={(value) => {
                                        if (value) {
                                            setClasses((classes) => {
                                                let filterClasses = classes.filter((_v) => _v?.id !== value.id);
                                                filterClasses = [...filterClasses, value];
                                                return filterClasses;
                                            })
                                        }
                                    }}
                                    optionKey="title"
                                />
                            </Box>
                            <SelectedCell
                                cellLabel="Classes"
                                data={classes}
                                onChange={(value) => setClasses(value)}
                            />
                            <FormControl>
                                <FormControlLabel
                                    control={<Switch />}
                                    checked={isPrivate}
                                    onChange={() => setIsPrivate(!isPrivate)}
                                    label="Is private membership" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Box sx={{ mb: 1 }}>
                                <PostPicker
                                    label="Membership categories"
                                    name="membership_categories"
                                    defaultData
                                    getApi={findManyMembershipCategories}
                                    values={categories}
                                    onChange={(value) => {
                                        if (value) {
                                            setCategories((categories) => {
                                                let filterCategories = categories.filter((_v) => _v?.id !== value.id);
                                                filterCategories = [...filterCategories, value];
                                                return filterCategories;
                                            })
                                        }
                                    }}
                                    optionKey="title"
                                />
                            </Box>
                            <SelectedCell
                                cellLabel="Categories"
                                data={categories}
                                onChange={(value) => setCategories(value)}
                            />
                            <FormControl>
                                <Stack direction="row" spacing={1}>
                                    <Button variant='outlined' onClick={() => setOpenDrawer(true)}>
                                        Edit translation
                                    </Button>
                                    <LoadingButton
                                        variant='contained'
                                        color='primary'
                                        size='lg'
                                        type='submit'
                                        loading={isLoading}
                                    >
                                        Update membership
                                    </LoadingButton>
                                </Stack>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <Box sx={{ p: 3}}>
                    <FormControl sx={{ width: "100%", mb: 3 }}>
                        <FormLabel>Membership Title</FormLabel>
                        <TextField
                            name="title"
                            value={translations?.title}
                            onChange={(e) => handleChangeTranslation("title", e.target.value)}
                        />
                    </FormControl>
                    <FormControl sx={{ width: "100%", mb: 3 }}>
                        <FormLabel>Membership description</FormLabel>
                        <TextField
                            multiline
                            rows={3}
                            value={translations?.description}
                            onChange={(e) => handleChangeTranslation("description", e.target.value)}
                        />
                    </FormControl>
                    <FormControl sx={{ width: "100%", mb: 3 }}>
                        <FormLabel>Membership note</FormLabel>
                        <TextField
                            multiline
                            rows={3}
                            value={translations?.note}
                            onChange={(e) => handleChangeTranslation("note", e.target.value)}
                        />
                    </FormControl>
                </Box>
            </Drawer>
        </Fragment>
    )
}

export default CreateMemberShipPage
