import { Box, FormControlLabel, Grid, Switch, Typography, Button } from '@mui/material'
import { useState } from 'react'
import { createClass } from '@/utils/Classes'
import { useSelector } from 'react-redux'
import { useToasterContext } from '@/utils/ToasterContext'

import validate from 'validate.js'
import ThumbnailPicker from '@/components/ThumbnailPicker'
import NavHeader from '@/components/NavHeader'
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput';

import { LoadingButton } from '@mui/lab'
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';

const CreateClass = () => {

    const { token } = useSelector((state) => state.auth);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [personName, setPersonName] = useState([]);
    const [formError, setFormError] = useState(false);
    const [thumbnail, setThumbnail] = useState(false);
    const [isPrivate, setIsPrivate] = useState(0);
    const [classTypes, setClassTypes] = useState([]);

    const navigate = useNavigate();
    const theme = useTheme();

    async function handleCreate(token, data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await createClass(token, data);
            if (response && !response.error) {
                const { data } = response;
                showMessage('success', 'Data created successfully!');
                if (data?.id) {
                    navigate("/admin/classes/" + data.id);
                }
            }
        } catch (error) {
            showMessage('error', 'An error occurred, please try again later!');
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const dataForm = {
            title: data.get('title'),
            description: data.get('description'),
            thumbnail: thumbnail?.id,
            capacity: data.get('capacity'),
            is_private: isPrivate ? 1 : 0,
            class_types: classTypes ? JSON.stringify(classTypes) : "",
        }

        const error = validate(dataForm, constraints);

        if (!error) {
            handleCreate(token, dataForm)
        } else {
            console.log('error', error);
            showMessage('error', "One or more input fields are invalid!");
        }

        setFormError(error);
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    var daysOfWeek = [
        { label: 'Monday', value: 'mon' },
        { label: 'Tuesday', value: 'tue' },
        { label: 'Wednesday', value: 'web' },
        { label: 'Thursday', value: 'thur' },
        { label: 'Friday', value: 'fri' },
        { label: 'Saturday', value: 'sat' },
        { label: 'Sunday', value: 'sun' }
    ];

    // Custom validator to ensure end_time is after start_time
    validate.validators.timeAfter = function (value, options, key, attributes) {
        const startTime = parseTime(attributes.start_time);
        const endTime = parseTime(value);
        if (startTime && endTime && endTime <= startTime) {
            return options.message || "must be after start time";
        }
    };

    // Helper function to parse time in hh:mm AM/PM format to Date object
    function parseTime(timeString) {
        const [time, modifier] = timeString.split(' ');
        let [hours, minutes] = time.split(':').map(Number);

        if (modifier === 'PM' && hours < 12) {
            hours += 12;
        }
        if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }

        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
    }

    const constraints = {
        title: {
            presence: { allowEmpty: false, message: "is required" },
        },
        description: {
            presence: { allowEmpty: false, message: "is required" },
        }
    };

    function addClassTypes(){
        setClassTypes((classTypes) => ([...classTypes, {
            title: '',
            capacity: 0
        }]))
    }

    function updateClassTypes(index, value){
        let updateClassType = classTypes.map((_v, _i) => _i == index ? value : _v);
        setClassTypes(updateClassType);
    }

    function removeClassType(index){
        let filterClassType = classTypes.filter((_v, _i) => _i !== index);
        setClassTypes(filterClassType);
    }

    return (
        <Box sx={{ width: '100%' }}>
            <NavHeader
                title="Create class"
                breadcrumb={[
                    {
                        title: 'classes'
                    }
                ]}
                backLink="/admin/classes"
            />
            <Box  component="form" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={8}>
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.title}>
                            <FormLabel>Class Title</FormLabel>
                            <TextField
                                name="title"
                                error={formError?.title}
                            />
                            {formError?.title ? <FormHelperText>{formError?.title[0]}</FormHelperText> : ""}
                        </FormControl>
                        <FormControl sx={{ width: "100%", mb: 3 }}>
                            <FormLabel>Class description</FormLabel>
                            <TextField
                                multiline
                                rows={3}
                                name="description"
                            />
                        </FormControl>
                        <FormControl sx={{ width: "100%", mb: 3 }}>
                            <FormLabel>Class note</FormLabel>
                            <TextField
                                multiline
                                rows={3}
                                name="class_note"
                            />
                        </FormControl>
                        <Box sx={{ mb: 3}}>
                            <Typography sx={{
                                fontWeight: 600,
                                textTransform: 'uppercase',
                                mb: 1
                            }}>
                                Class Types
                            </Typography>
                            {
                                Array.isArray(classTypes) && classTypes.length ?
                                    classTypes.map((val, index) => {
                                        return (
                                            <Box key={index}>
                                                <Grid container spacing={3} >
                                                    <Grid item xs={6}>
                                                        <FormControl sx={{ width: "100%", mb: 3 }}>
                                                            <FormLabel>Title</FormLabel>
                                                            <TextField value={val?.title} onChange={(e) => updateClassTypes(index, {...val, title: e.target.value})} type="text"/>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <FormControl sx={{ width: "100%", mb: 3 }}>
                                                            <FormLabel>Default capacity</FormLabel>
                                                            <TextField value={val?.capacity} onChange={(e) => updateClassTypes(index, {...val, capacity: e.target.value})} type="number" />
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                                <Box sx={{ textAlign: 'right' }}>
                                                    <Button
                                                        onClick={() => removeClassType(index)}
                                                        startIcon={<DeleteIcon />}>
                                                        Delete
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )
                                    })
                                    : ""
                            }
                            <Button
                                variant='contained'
                                onClick={() => addClassTypes()}>
                                Add class type
                            </Button>
                        </Box>
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.Capacity}>
                            <FormLabel>Capacity</FormLabel>
                            <TextField
                                name="capacity"
                                type="number"
                                error={formError?.capacity}
                            />
                            {formError?.capacity ? <FormHelperText>{formError?.capacity[0]}</FormHelperText> : ""}
                        </FormControl>
                        <FormControl>
                            <FormControlLabel
                                control={<Switch />}
                                checked={isPrivate}
                                onChange={() => setIsPrivate(!isPrivate)}
                                label="Is private class" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormControl sx={{ mb: 3, width: "100%" }}>
                            <FormLabel id="demo-multiple-name-label">Thumbnail</FormLabel>
                            <ThumbnailPicker value={thumbnail} onChange={(Image) => setThumbnail(Image)} />
                        </FormControl>
                        <FormControl>
                            <LoadingButton
                                variant='contained'
                                color='primary'
                                size='lg'
                                type='submit'
                                loading={isLoading}
                            >
                                Create
                            </LoadingButton>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default CreateClass
