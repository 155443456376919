export function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function numberWithCommas(x) {
    if(!x){ return 0};
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
        x = x.replace(pattern, "$1,$2");
    return x;
}

export function setCookie(name, value, expires_in) {
    document.cookie = `${name}=${value}; max-age=${expires_in}; path=/`;
}

export function deleteCookie(name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function getCookie(name) {
    let cookieArray = document.cookie.split(';');
    for (let cookie of cookieArray) {
        let [cookieName, cookieValue] = cookie.split('=');
        cookieName = cookieName.trim();
        if (cookieName === name) {
            return cookieValue;
        }
    }
    return null;
}

export function getInitials(fullName) {
    // Kiểm tra nếu fullName không phải là chuỗi hoặc là chuỗi rỗng
    if (typeof fullName !== 'string' || fullName.trim().length === 0) {
        return 'MV'; // Trả về chuỗi rỗng nếu không hợp lệ
    }

    // Loại bỏ khoảng trắng thừa và tách chuỗi thành các từ
    const words = fullName.trim().split(/\s+/);

    // Nếu có nhiều từ, lấy ký tự đầu tiên của từ đầu tiên và từ thứ hai
    if (words.length > 1) {
        const firstInitial = words[0][0].toUpperCase();
        const secondInitial = words[1][0].toUpperCase();
        return firstInitial + secondInitial;
    }

    // Nếu chỉ có một từ, lấy hai chữ cái đầu tiên của từ đó
    return words[0].slice(0, 2).toUpperCase();
}