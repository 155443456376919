import { Fragment } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { 
    IoHomeOutline, 
    IoImagesOutline, 
    IoCalendarOutline, 
    IoFolderOpenOutline, 
    IoCubeOutline, 
    IoGitMergeOutline, 
    IoPersonOutline, 
    IoCartOutline, 
    IoPeopleOutline, 
    IoStarOutline,
    IoPricetagsOutline, 
    IoLanguageOutline
} from "react-icons/io5";

import { Link, useLocation } from 'react-router-dom';
import { Divider } from '@mui/material';

import classNames from 'classnames';

const ListItems = () => {
    const { pathname } = useLocation();

    return (
        <Fragment>
            <Link to="/admin">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname == "/admin"})}>
                    <ListItemIcon>
                        <IoHomeOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItemButton>
            </Link>
            <Link to="/admin/media">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/media")})}>
                    <ListItemIcon>
                        <IoImagesOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary="Media" />
                </ListItemButton>
            </Link>
            <Link to="/admin/trainers">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/trainers")})}>
                    <ListItemIcon>
                        <IoPersonOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary="Instructors" />
                </ListItemButton>
            </Link>
            <Link to="/admin/classes">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/classes")})}>
                    <ListItemIcon>
                        <IoFolderOpenOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary="Classes" />
                </ListItemButton>
            </Link>
            <Link to="/admin/memberships">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/memberships")})}>
                    <ListItemIcon>
                        <IoCubeOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary="Memberships" />
                </ListItemButton>
            </Link>
            <Link to="/admin/integrations">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/integrations")})}>
                    <ListItemIcon>
                        <IoGitMergeOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary="Integrations" />
                </ListItemButton>
            </Link>
            <Divider sx={{ my: 1 }} classes="border-slate-200"/>
            <Link to="/admin/sessions">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/sessions")})}>
                    <ListItemIcon>
                        <IoCalendarOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary="Sessions" />
                </ListItemButton>
            </Link>
            <Link to="/admin/promotions">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/promotions")})}>
                    <ListItemIcon>
                        <IoPricetagsOutline  size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary="Promotions" />
                </ListItemButton>
            </Link>
            <Link to="/admin/orders">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/orders")})}>
                    <ListItemIcon>
                        <IoCartOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary="Orders" />
                </ListItemButton>
            </Link>
            <Link to="/admin/subscriptions">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/subscriptions")})}>
                    <ListItemIcon>
                        <IoStarOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary="Subscriptions" />
                </ListItemButton>
            </Link>
            <Link to="/admin/users">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/users")})}>
                    <ListItemIcon>
                        <IoPeopleOutline size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                </ListItemButton>
            </Link>
            <Link to="/admin/translations">
                <ListItemButton className={classNames({"!bg-slate-700 !rounded": pathname.startsWith("/admin/translations")})}>
                    <ListItemIcon>
                        <IoLanguageOutline  size={22} color="#fff"/>
                    </ListItemIcon>
                    <ListItemText primary="Translations" />
                </ListItemButton>
            </Link>
        </Fragment>
    )
};

export default ListItems;
