import { useState, useEffect } from 'react';
import { findManyMembershipCategories } from '@/utils/MembershipCategories';
import { createCart, findManyCarts, deleteCart } from '@/utils/Cart';
import { useDispatch, useSelector } from 'react-redux';
import { useToasterContext } from '@/utils/ToasterContext';
import { Button, Card, CardContent, IconButton, List, ListItem } from '@mui/material';

import NavHeader from '@/components/NavHeaderHome';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { Link } from 'react-router-dom';
import { numberWithCommas } from "@/utils/Hooks";
import { LoadingButton } from '@mui/lab';
import { updateCartData } from '@/utils/slice/cartSlice';
import { useTranslation } from 'react-i18next';

export default function EnhancedTable() {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [data, setData] = useState([]);
    const [activeKey, setActiveKey] = useState(false);
    const [deleteItem, setDeleteItem] = useState(false);
    const { showMessage, startProgress, completeProgress, currentLanguage } = useToasterContext();
    const [categories, setCategories] = useState([]);
    const [cartTotal, setCartTotal] = useState(false);

    const [addItem, setAddItem] = useState(false);
    const [open, setOpen] = useState(false);

    const { token } = useSelector((state) => state.auth);
    const { cart } = useSelector((state) => state.cart);

    async function handleFindCart() {
        try {
            const response = await findManyCarts(token);
            if (response && !response.error) {
                const { data } = response;
                dispatch(updateCartData(data));
            } else {
                showMessage("error", t("An error occurred, please try later"))
            }
        } catch (error) {
            showMessage("error", t("An error occurred, please try later"))
        }
    }

    async function handleAddToCart(id) {
        try {
            setAddItem(id);
            const response = await createCart(token, {
                membership_id: id
            })
            if (response && !response.error) {
                const { id } = response;
                if (id) {
                    await handleFindCart();
                    setOpen(true);
                }
            } else {
                showMessage("error", t("An error occurred, please try later"))
            }
        } catch (error) {
            showMessage("error", t("An error occurred, please try later"))
        } finally {
            setAddItem(false);
        }
    }

    async function handleDeleteFromCart(id) {
        try {
            setDeleteItem(id);
            const response = await deleteCart(token, id)
            if (response && !response.error) {
                const { id } = response;
                if (id) {
                    await handleFindCart();
                    setOpen(true);
                }
            } else {
                showMessage("error", t("An error occurred, please try later"))
            }
        } catch (error) {
            showMessage("error", t("An error occurred, please try later"))
        } finally {
            setDeleteItem(false);
        }
    }

    async function handleFindManyData() {
        try {
            startProgress();
            const categories = await findManyMembershipCategories({
                is_hidden: 0
            })
            if (categories) {
                const { data } = categories;
                const { id, memberships } = data[0];
                if (Array.isArray(memberships) && memberships.length) {
                    setActiveKey(id);
                    setData(memberships);
                }
                setCategories(data);
            }
        } catch (error) {

        } finally {
            completeProgress();
        }
    }

    useEffect(() => {
        if (Array.isArray(cart) && cart?.length) {
            let totalPrice = cart.reduce((acc, value) => acc + (value?.memberships?.regular_price * value?.quantity), 0);
            setCartTotal(totalPrice);
        }
    }, [cart])

    useEffect(() => {
        handleFindManyData({});
    }, [])

    const MemberShipItem = ({ data, addItem, handleAddToCart }) => {

        const { id, title, description, note, regular_price } = data;

        return (
            <Grid2 xs={12} lg={6}>
                <Card
                    variant='outlined'
                    sx={{
                        borderColor: "#000",
                        cursor: 'pointer',
                        borderRadius: 0,
                        height: '100%'
                    }}>
                    <CardContent>
                        <Typography sx={{ textTransform: "uppercase", fontWeight: 600, fontSize: 16 }}>
                            {t(title)}
                        </Typography>
                        <div className='h-[50px] mb-3'>
                            <Typography sx={{ fontWeight: 600, fontSize: 22 }}>
                                {numberWithCommas(regular_price)}₫
                            </Typography>
                            {
                                note && (
                                    <Typography sx={{ mb: 1, fontSize: 14 }}>
                                        {t(note)}
                                    </Typography>
                                )
                            }
                        </div>
                        <div className='h-[85px]'>
                            <Typography sx={{ fontSize: 14, mb: 1, whiteSpace: "pre-wrap" }}>
                                {t(description)}
                            </Typography>
                        </div>
                        <LoadingButton
                            loading={addItem == id}
                            onClick={() => handleAddToCart(id)}
                            className='border'
                            sx={{ borderRadius: 0, background: "#000" }}
                            variant='contained'>
                            {t("Buy now")}
                        </LoadingButton>
                    </CardContent>
                </Card>
            </Grid2>
        );
    };

    return (
        <Box sx={{ width: '100%' }}>
            <NavHeader
                title={t("Memberships")}
                breadcrumb={[
                    {
                        title: t('Memberships')
                    }
                ]}
            />
            <Tabs
                value={activeKey}
                onChange={(e, value) => setActiveKey(value)}
                variant="scrollable"
                sx={{
                    mb: 3,
                    '.MuiTabs-scrollButtons': {
                        display: "flex !important"
                    },
                    '.Mui-selected': {
                        color: "#000 !important"
                    },
                    '.MuiTabs-indicator': {
                        background: "#000 !important"
                    },
                    [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                    },
                }}>
                {
                    Array.isArray(categories) && categories.length ?
                        categories.map((val) => {
                            return (
                                <Tab
                                    label={t(val.title)}
                                    onClick={() => setData(val.memberships)}
                                    iconPosition='bottom'
                                    value={val.id}
                                    key={val.id}
                                    sx={{ minWidth: 180 }}
                                />
                            )
                        })
                        : ""
                }
            </Tabs>
            <Grid2 container spacing={3}>
                {
                    Array.isArray(data) && data.length ? 
                        data.sort((a, b) => a.regular_price - b.regular_price).map((item) => {
                            return (
                                <MemberShipItem 
                                    key={item.id} 
                                    currentLanguage={currentLanguage} 
                                    addItem={addItem} 
                                    handleAddToCart={handleAddToCart} 
                                    data={item}
                                />
                            );
                    }): ""
                }
            </Grid2>
            <Drawer
                anchor="right"
                open={open}>
                <Box
                    sx={{ width: 480 }}
                    role="presentation">
                    <Box
                        className='bg-black text-white'
                        sx={{
                            p: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}>
                        <Typography sx={{
                            fontWeight: 600,
                            fontSize: 24,
                            textTransform: "uppsercase"
                        }}>
                            {t("Cart")}
                        </Typography>
                        <IconButton onClick={() => setOpen(false)}>
                            <CloseIcon sx={{ color: "#fff" }} />
                        </IconButton>
                    </Box>
                    <List>
                        {
                            Array.isArray(cart) && cart?.length ? cart.map((val) => {
                                const { id, quantity, memberships } = val;
                                return (
                                    <ListItem
                                        key={id}
                                        sx={{ p: 3 }}
                                        className='border-b border-dashed border-slate-200 h-full'>
                                        <Box sx={{ flex: 1 }}>
                                            <Typography sx={{ fontWeight: 600, mb: 1 }}>
                                                {t(memberships.title)}
                                            </Typography>
                                            <Typography>
                                                {memberships?.regular_price ? numberWithCommas(memberships.regular_price) + "₫" : "-"}
                                                x {quantity}
                                            </Typography>
                                            <Typography>
                                                <strong className='mr-2'>{t("Subtotal")}:</strong>
                                                {numberWithCommas(memberships?.regular_price * quantity)}₫
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <LoadingButton
                                                loading={deleteItem == memberships.id}
                                                onClick={() => handleDeleteFromCart(memberships?.id)}>
                                                <DeleteOutlineIcon />
                                            </LoadingButton>
                                        </Box>
                                    </ListItem>
                                )

                            }) : ""
                        }
                    </List>
                    <Box sx={{ p: 2 }}>
                        <Typography sx={{ mb: 2 }}>
                            <strong className='mr-2'>{t("Total")}:</strong>
                            {numberWithCommas(cartTotal)}₫
                        </Typography>
                        <Button
                            as={Link}
                            to="/checkout"
                            color='secondary'
                            sx={{
                                padding: "10px 8px",
                                width: '100%',
                                borderRadius: 0,
                                display: "block",
                                mt: 2,
                                mb: 2,
                                textAlign: 'center',
                                letterSpacing: 2
                            }}
                            variant='contained'>
                            {t("Proceed to Checkout")}
                        </Button>
                    </Box>
                </Box>
            </Drawer>
        </Box>
    );
}