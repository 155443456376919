
import { useState } from 'react';
import { createTranslation } from '@/utils/Translations';
import { useSelector } from 'react-redux';
import { useToasterContext } from '@/utils/ToasterContext'
import { LoadingButton } from '@mui/lab'
import { useNavigate, useParams } from 'react-router-dom'

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import NavHeader from '@/components/NavHeader';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import validate from 'validate.js';

const CreateTranslationPage = () => {

    const { id } = useParams();
    const { token } = useSelector((state) => state.auth);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);

    const navigate = useNavigate();

    async function handleCreate(token, data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await createTranslation(token, data);
            if (response && !response.error) {
                const { data } = response;
                showMessage('success', 'Data created successfully!');
                if (data?.id) {
                    navigate("/admin/translations/" + data.id);
                }
            }
        } catch (error) {
            showMessage('error', 'An error occurred, please try again later!');
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const dataForm = {
            text_value: data.get('text_value'),
            text_translated: data.get('text_translated')
        }

        const error = validate(dataForm, constraints);

        if (!error) {
            handleCreate(token, dataForm)
        } else {
            console.log('error', error);
            showMessage('error', "One or more input fields are invalid!");
        }

        setFormError(error);
    }

    const constraints = {
        text_value: {
            presence: true,
        },
        text_translated: {
        }
    };

    return (
            <Box sx={{ width: '100%' }}>
                <NavHeader
                    title="Create Translation"
                    breadcrumb={[
                        {
                            title: 'Translations',
                            link: '/admin/translations/'
                        },
                        {
                            title: 'Create Translation'
                        }
                    ]}
                    backLink="/admin/translations"
                />
                <Box component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={8}>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.text_value} required>
                                <FormLabel>Text value</FormLabel>
                                <TextField
                                    name="text_value"
                                    rows={5}
                                    multiline
                                    type="text"
                                    error={formError?.text_value}
                                />
                                {formError?.text_value ? <FormHelperText>{formError?.text_value[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.promotion_amount} required>
                                <FormLabel>Translated</FormLabel>
                                <TextField
                                    name="text_translated"
                                    rows={5}
                                    multiline
                                    type="text"
                                    error={formError?.text_translated}
                                />
                                {formError?.promotion_amount ? <FormHelperText>{formError?.promotion_amount[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl>
                                <LoadingButton
                                    variant='contained'
                                    color='primary'
                                    size='lg'
                                    type='submit'
                                    loading={isLoading}>
                                    Create translation
                                </LoadingButton>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
    )
}

export default CreateTranslationPage
