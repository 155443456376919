import { Box, Grid } from '@mui/material'
import { useState } from 'react'
import { createTrainer } from '@/utils/Trainers'
import { useSelector } from 'react-redux'
import { useToasterContext } from '@/utils/ToasterContext'

import NavHeader from '@/components/NavHeader'
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput';
import validate from 'validate.js'

import { LoadingButton } from '@mui/lab'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useTheme } from '@mui/material/styles';
import { createUser } from '@/utils/Users'
import { useNavigate } from 'react-router-dom'
import PageLoading from '@/components/PageLoading'
import { useTranslation } from 'react-i18next'

const CreateClass = () => {

    const { t } = useTranslation();
    const { token } = useSelector((state) => state.auth);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(false);

    const navigate = useNavigate();

    async function handleCreate(data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await createUser(token, data);
            if (response && !response.error) {
                const { data } = response;
                showMessage('success', 'Data created successfully!');
                if (data?.id) {
                    navigate("/admin/users/" + data.id);
                }
            } else {
                showMessage('error', 'An error occurred, please try again later');
            }
        } catch (error) {
            showMessage('error', 'An error occurred, please try again later');
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }


    function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const dataForm = {
            first_name: data.get("first_name"),
            last_name: data.get("last_name"),
            email: data.get("email"),
            role: data.get("role"),
            password: data.get("password")
        }
        const error = validate(dataForm, constraints);
        if (!error) {
            handleCreate(dataForm)
        }
        setFormError(error);
    }

    var roles = [
        { "label": "Administrator", "value": "admin" },
        { "label": "Trainer", "value": "trainer" },
        { "label": "Receptionist", "value": "receptionist" },
        { "label": "User", "value": "user" },
    ];

    const constraints = {
        first_name: {
            presence: { allowEmpty: false },
        },
        last_name: {
            presence: { allowEmpty: false },
        },
        email: {
            email: true
        },
        role: {
            presence: { allowEmpty: false }
        },
        password: {
            presence: { allowEmpty: false }
        }
    };

    return (
            <Box sx={{ width: '100%' }}>
                <NavHeader
                    title="Create user"
                    breadcrumb={[
                        {
                            title: 'Create User'
                        }
                    ]}
                    backLink="/admin/users"
                />
                <Box  component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={8}>
                        <Grid container spacing={3}>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.first_name} required>
                                        <FormLabel>{t("First name")}</FormLabel>
                                        <TextField
                                            name="first_name"
                                            error={formError?.first_name}
                                        />
                                        {formError?.first_name ? <FormHelperText>{formError?.first_name[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.last_name} required>
                                        <FormLabel>{t("Last name")}</FormLabel>
                                        <TextField
                                            name="last_name"
                                            error={formError?.last_name}
                                        />
                                        {formError?.last_name ? <FormHelperText>{formError?.last_name[0]}</FormHelperText> : ""}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.email} required>
                                <FormLabel>Email</FormLabel>
                                <TextField
                                    name="email"
                                    type="email"
                                    error={formError?.email}
                                />
                                {formError?.email ? <FormHelperText>{formError?.email[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ mb: 3, width: "100%" }} error={formError?.role} required>
                                <FormLabel id="demo-multiple-name-label">User role</FormLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    name="role"
                                    input={<OutlinedInput label="" />}>
                                    {roles.map((val, index) => (
                                        <MenuItem
                                            key={index}
                                            value={val.value}>
                                            {val.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formError?.role ? <FormHelperText>{formError?.role[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.password} required>
                                <FormLabel>User password</FormLabel>
                                <TextField
                                    name="password"
                                    type="text"
                                    error={formError?.password}
                                />
                                {formError?.password ? <FormHelperText>{formError?.password[0]}</FormHelperText> : ""}
                            </FormControl>
                            <FormControl>
                                <LoadingButton
                                    variant='contained'
                                    color='primary'
                                    size='lg'
                                    type='submit'
                                    loading={isLoading}
                                >
                                    Create user
                                </LoadingButton>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
    )
}

export default CreateClass
