import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { getToken, getDataUser } from '@/utils/slice/AuthSlice';

import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Logo from "@/assets/logo-light.svg";
import ListItems from './listItems';
import classNames from 'classnames';
import PageLoading from '@/components/PageLoading';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(4),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export default function DashboardLayout() {

    const [open, setOpen] = useState(true);
    const { token, user, status } = useSelector((state) => state.auth);

    const navigate = useNavigate();

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if (!token) {
            dispatch(getToken());
        }
        dispatch(getDataUser(token));
    }, [token]);

    if (status !== 'complete') {
        return (
            <PageLoading />
        )
    }

    if (!token) {
        navigate('/login');
    }

    if (user && user.role !== 'administrator') {
        navigate('/');
    }

    return (
        <Box id="dashboard" sx={{ display: 'flex' }}>
            <Drawer
                variant="permanent"
                open={open}
                sx={{
                    border: "unset",
                }}>
                    <Box className='!bg-slate-900 h-full text-white'>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                px: [1],
                            }}>
                                <IconButton 
                                    className={classNames({'!flex-1': !open})} 
                                    onClick={toggleDrawer} color='#fff'>
                                    {
                                        open ? (
                                            <IoChevronBack color='#fff'/>
                                        ) : (
                                            <IoChevronForward color='#fff'/>
                                        )
                                    }
                                </IconButton>
                                {
                                    open ? (
                                        <Link className='flex-1 ps-5' to={"/admin"} >
                                            <img src={Logo} width={120} alt="" />
                                        </Link>
                                    ) : ""
                                }
                            </Toolbar>
                            <Divider />
                            <List
                                sx={{
                                    p: 1,
                                    border: "unset"
                                }}
                                component="nav">
                                <ListItems />
                        </List>
                    </Box>
                
            </Drawer>
            <Box
                component="main"
                className='bg-neutral-100 h-screen w-full overflow-y-scroll'>
                <Box sx={{p: 4}}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}