import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { IoInformationCircle } from "react-icons/io5";
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PaymentFail = () => {
    const { t } = useTranslation();
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    alignItems: 'center',
                }}
            >
                <IoInformationCircle size={55} style={{marginBottom: 35}}/>
                <Typography sx={{ fontSize: 24, marginBottom: 3 }}>
                    {t("Payment failed, please check your order again in your Orders section to re-pay the order, or contact support to help resolve the error order.")}
                </Typography>
                <Button as={Link} to="/account/orders" variant='contained'>
                    {t("View all orders")}
                </Button>
            </Box>
        </Container>
    )
}

export default PaymentFail