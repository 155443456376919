import { useState, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, FormControl, FormLabel, Stack } from '@mui/material';
import { getOrderAnalytics, getClassSessionAnalytics , getTrainerAnalytics, getSessionAnalytics } from '@/utils/Analytics';
import { userLogout } from '@/utils/Auth';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { deleteToken } from '@/utils/slice/AuthSlice';
import { useToasterContext } from '@/utils/ToasterContext';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Grid from '@mui/material/Grid';
import BillingTotal from './BillingTotal';
import OrderTotal from './OrderTotal';
import ClasssSession from './ClassSession';
import Orders from './Orders';
import TrainerTable from './TrainerTable';
import NavHeader from '@/components/NavHeader';
import SessionChart from "./SessionChart";

import dayjs from 'dayjs';

export default function Dashboard() {

    const dispatch = useDispatch();

    const { token, status } = useSelector((state) => state.auth);
    const { showMessage } = useToasterContext();
    const [dataOrder, setDataOrder] = useState(false);
    const [sessionClassChart, setSessionClassChart] = useState(false);
    const [trainerChart, setTrainerChart] = useState(false);
    const [sessionChartData, setSessionChartData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    
    const [filter, setFilter] = useState({
        start_date: dayjs().startOf('year').format("DD/MM/YYYY"),
        end_date: dayjs().endOf('year').format("DD/MM/YYYY"),
    })

    async function handleGetOrderChart(token, filter) {
        try {
            const response = await getOrderAnalytics(token, filter);
            if(response && !response.error){
                setDataOrder(response);
            }
        } catch (error) {
            console.error('error', error);
        }
    } 

    async function handleGetTrainerChart(token, filter) {
        try {
            const response = await getTrainerAnalytics(token, { ...filter });
            if(response && !response.error){
                setTrainerChart(response);
            }
        } catch (error) {
            console.error('error', error);
        }
    }

    async function handleGetClassSessionChart(token, filter) {
        try {
            const response = await getClassSessionAnalytics(token, filter);
            if(response && !response.error){
                setSessionClassChart(response);
            }
        } catch (error) {
            console.error('error', error);
        }
    }

    async function handleGetSessionChart(token, filter) {
        try {
            const response = await getSessionAnalytics(token, filter);
            if(response && !response.error){
                setSessionChartData(response);
            }
        } catch (error) {
            console.error('error', error);
        }
    }

    async function handleGetOrderChart(token, filter) {
        try {
            const response = await getOrderAnalytics(token, filter);
            if(response && !response.error){
                setDataOrder(response);
            }
        } catch (error) {
            console.error('error', error);
        }
    }

    async function handleUserLogout() {
        try {
            setIsLoading('logout');
            const response = await userLogout(token);
            if (response && !response.error) {
                showMessage("success", "You have successfully logged out!");
                navigate('/login');
                dispatch(deleteToken());
            } else {
                showMessage("error", "An error occurred during delete item!")
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }

    function handleFilter(){
        handleGetSessionChart(token, filter);
        handleGetOrderChart(token, filter);
        handleGetClassSessionChart(token, filter);
        handleGetTrainerChart(token, filter);
    }

    useEffect(() => {
        if(token){
            handleGetSessionChart(token, filter);
            handleGetOrderChart(token, filter);
            handleGetClassSessionChart(token, filter);
            handleGetTrainerChart(token, filter);
        }
    }, [token])

    const handleChangeDate = (name, value) => {
        setFilter((filter) => (
            {
                ...filter,
                [name]: value ? dayjs(value).format("DD/MM/YYYY") : null
            }
        ))
    }

    useEffect(() => {
        if(status == 'complete'){
            if(!token){
                navigate("/login")
            }
        }
    }, [status])

    return (
        <Fragment>
            <NavHeader
                title="Dashboard"
                breadcrumb={[
                    {
                        title: 'Home'
                    }
                ]}
                endAction={(
                    <>
                        <LoadingButton loading={isLoading} onClick={handleUserLogout}>
                            Logout
                        </LoadingButton>
                    </>
                )}
            />
            <Stack direction="row" justifyContent="flex-end" sx={{ display: "flex", columnGap: 3, mb: 3 }}>
                <FormControl>
                    <DatePicker
                        label="Start date"
                        value={filter?.start_date ? dayjs(filter.start_date, 'DD/MM/YYYY') : null}
                        onChange={(value) => handleChangeDate("start_date", value)}
                        sx={{ width: 220,  background: "#fff", }}
                    />
                </FormControl>
                <FormControl>
                    <DatePicker
                        label="End date"
                        value={filter?.end_date ? dayjs(filter.end_date, 'DD/MM/YYYY') : null}
                        onChange={(value) => handleChangeDate("end_date", value)}
                        sx={{ width: 220,  background: "#fff" }}
                    />
                </FormControl>
                <FormControl>
                    <LoadingButton 
                        variant='contained' 
                        sx={{borderRadius: 0, height: "100%"}} 
                        onClick={() => handleFilter()}>
                        Filter
                    </LoadingButton>
                </FormControl>
            </Stack>
            <Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <div className='bg-white p-3 rounded border border-slate-200 shadow-0'>
                            <BillingTotal chart={dataOrder}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <div className='bg-white p-3 rounded border border-slate-200 shadow-0'>
                            <OrderTotal chart={dataOrder}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <div className='bg-white p-3 rounded border border-slate-200 shadow-0'>
                            <SessionChart chart={sessionChartData}/>
                        </div>
                    </Grid>
                    {/* Chart */}
                    <Grid item xs={12} lg={4}>
                        <div className='bg-white p-3 rounded border border-slate-200 shadow-0 h-full'>
                            <ClasssSession chart={sessionClassChart}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                         <div className='bg-white p-3 rounded border border-slate-200 shadow-0 h-full'>
                            <h3 className='font-[900] px-3'>Trainer analytics</h3>
                            <TrainerTable data={trainerChart}/>
                        </div>
                    </Grid>
                    {/* Recent Orders */}
                    <Grid item xs={12} md={8} lg={12}>
                        <div className='bg-white p-3 rounded border border-slate-200 shadow-0'>
                            <h3 className='font-[900] px-3'>Lastest orders</h3>
                            <Orders />
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    );
}