import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IoBagCheckOutline } from "react-icons/io5";


const PaymentComplete = () => {
    const { t } = useTranslation();
    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    alignItems: 'center',
                }}>
                <IoBagCheckOutline size={55} style={{marginBottom: 35}}/>
                <Typography sx={{ fontSize: 24, marginBottom: 3 }}>
                    {t("Successful online payment, we will update your service package information as soon as possible, please wait for a moment!")}
                </Typography>
                <Button as={Link} to="/account/orders" variant='contained'>
                    {t("View all orders")}
                </Button>
            </Box>
        </Container>
    )
}

export default PaymentComplete