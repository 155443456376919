import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import PageLoading from '@/components/PageLoading';
import NavHeader from '@/components/NavHeader';

import { findManySessions } from '@/utils/Sessions';
import { findManyClasses } from "@/utils/Classes";
import { findManyTrainers } from "@/utils/Trainers";
import { Select, Stack, MenuItem, FormControl, InputLabel } from '@mui/material';

import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekday from 'dayjs/plugin/weekday';

import dayjs from 'dayjs';
import DayView from './Components/DayView';
import WeekView from './Components/WeekView';

// Extend dayjs with plugins
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(weekday);

export default function EnhancedTable() {

    const [data, setData] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);

    const [step, setStep] = useState( isNumeric(searchParams.get("step")) ? parseInt(searchParams.get("step")) :  0);
    const [trainers, setTrainers] = useState(false);
    const [classes, setClasses] = useState(false);
    const [pageInit, setPageInit] = useState(false);

    function isNumeric(str) {
        return !isNaN(str) && !isNaN(parseFloat(str));
    }

    const ViewComponent = {
        day: <DayView data={data} step={step} />,
        week: <WeekView data={data} step={step} reload={() => handleFindManyData(filter)}/>,
    }

    const [filter, setFilter] = useState({
        trainer_id: null,
        class_id: null,
        start_time: null,
        end_time: null,
        step: step
    });

    useEffect(() => {
        let trainer_id = searchParams.get("trainer_id");
        let class_id = searchParams.get("class_id");
        setFilter({
            trainer_id: trainer_id,
            class_id: class_id
        })
    }, [])

    useEffect(() => {
        if (filter?.start_time) {
            handleFindManyData(filter);
        }
    }, [filter])

    useEffect(() => {
        const start_time = dayjs().add(step, "week").startOf("week").format('YYYY-MM-DD HH:mm:ss');
        const end_time = dayjs().add(step, "week").endOf("week").format('YYYY-MM-DD HH:mm:ss');
        setSearchParams({
            trainer_id: filter?.trainer_id,
            class_id: filter?.class_id,
            step: step
        })
        setFilter({
            ...filter,
            start_time: start_time,
            end_time: end_time
        });
    }, [step])

    const handleFindManyData = async (params) => {
        try {
            setIsLoading(true);
            const response = await findManySessions(params);
            if (response && !response.error) {
                const { data } = response;
                setData(data);
            }
        } catch (error) {

        } finally {
            setIsLoading(false);
            setPageInit(true);
        }
    }

    async function handleFindManyTrainers() {
        try {
            const response = await findManyTrainers({});
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    setTrainers(data);
                }
            }
        } catch (error) {

        }
    }

    async function handleFindManyClasses() {
        try {
            const response = await findManyClasses({});
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    setClasses(data);
                }
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        handleFindManyClasses();
        handleFindManyTrainers();
    }, [])

    if (!pageInit) {
        return (
            <PageLoading />
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <NavHeader
                title="Sessions"
                breadcrumb={[
                    {
                        title: 'Sessions'
                    }
                ]}
                endAction={(
                    <Link to={"/admin/sessions/create" + "?step=" + step} >
                        <Button
                            startIcon={<AddIcon />}
                            variant='contained'
                            color='primary'>
                            Create Session
                        </Button>
                    </Link>
                )}
            />
            <Box>
                <Stack direction="row" spacing={3} sx={{ mb: 3 }}>
                    <FormControl sx={{ width: 220 }}>
                        <InputLabel>Select Trainer</InputLabel>
                        <Select
                            onChange={(e) => setFilter({ ...filter, trainer_id: e.target.value })}
                            label="Select Trainer" placeholder='View'>
                            <MenuItem>All</MenuItem>
                            {
                                Array.isArray(trainers) && trainers.length ?
                                    trainers.map((val) => {
                                        return (
                                            <MenuItem value={val.id} key={val.id}>
                                                {val.full_name}
                                            </MenuItem>
                                        )
                                    })
                                    : ""
                            }
                        </Select>
                    </FormControl>
                    <FormControl sx={{ width: 220 }}>
                        <InputLabel>Select Class</InputLabel>
                        <Select
                            onChange={(e) => setFilter({ ...filter, class_id: e.target.value })}
                            label="Select Class"
                            placeholder='View'>
                            <MenuItem>All</MenuItem>
                            {
                                Array.isArray(classes) && classes.length ?
                                    classes.map((val) => {
                                        return (
                                            <MenuItem value={val.id} key={val.id}>
                                                {val.title}
                                            </MenuItem>
                                        )
                                    })
                                    : ""
                            }
                        </Select>
                    </FormControl>
                    <Button onClick={() => setStep((step) => step - 1)} variant='outlined'>
                        Prev
                    </Button>
                    <Button onClick={() => setStep((step) => step + 1)} variant='outlined'>
                        Next
                    </Button>
                </Stack>
                {isLoading ? <PageLoading height={320} /> : ViewComponent["week"]}
            </Box>
        </Box>
    );
}