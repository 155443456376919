
import { useState, useEffect } from 'react';
import { findOnePromotion, updatePromotion } from '@/utils/Promotions';
import { useSelector } from 'react-redux';
import { useToasterContext } from '@/utils/ToasterContext'
import { LoadingButton } from '@mui/lab'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate, useParams } from 'react-router-dom'

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import NavHeader from '@/components/NavHeader';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import validate from 'validate.js'
import PostPicker from '@/components/PostPicker'
import PageLoading from '@/components/PageLoading'

import { findManyMemberships } from '@/utils/Memberships';
import { FormControlLabel, InputAdornment, Switch } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import dayjs from 'dayjs'
import SelectedCell from '@/components/SelectedCell';

const UpdatePromotionPage = () => {

    const { id } = useParams();
    const { token } = useSelector((state) => state.auth);
    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);
    const [defaultValue, setDefaultValue] = useState(false);
    const [formError, setFormError] = useState(false);
    const [membership, setMembership] = useState([]);
    const [forAllMemberShip, setForAllMemberShip] = useState(0);
    const [doesNotExpired, setDoesNotExpired] = useState(0);

    const navigate = useNavigate();

    async function handleFindOne() {
        try {
            const response = await findOnePromotion(token, id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const {
                        promotion_code ,
                        promotion_amount,
                        start_date,
                        end_date,
                        does_not_expire,
                        for_all_memberships,
                        memberships
                    } = data;
                    setForAllMemberShip(for_all_memberships);
                    setDoesNotExpired(does_not_expire);
                    setDefaultValue({
                        promotion_code ,
                        promotion_amount,
                        start_date,
                        end_date
                    })
                    setMembership(memberships);
                }
            }
        } catch (error) {

        } finally {

        }
    }

    async function handleUpdate(token, id, data) {
        try {
            setIsLoading(true);
            startProgress();
            const response = await updatePromotion(token, id, data);
            if (response && !response.error) {
                const { data } = response;
                showMessage('success', 'Data updated successfully!');
                if (data?.id) {
                    navigate("/admin/promotions/" + data.id);
                }
            }
        } catch (error) {
            showMessage('error', 'An error occurred, please try again later!');
        } finally {
            setIsLoading(false);
            completeProgress(true);
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const memberships = Array.isArray(membership) && membership?.length ? membership.map((val) => val.id) : [];
        const dataForm = {
            memberships: memberships,
            start_date: dayjs(data.get('start_date'), "DD/MM/YYYY").format("YYYY-MM-DD"),
            end_date: dayjs(data.get('end_date'), "DD/MM/YYYY").format("YYYY-MM-DD"),
            for_all_memberships: forAllMemberShip ? 1 : 0,
            does_not_expire: doesNotExpired ? 1 : 0,
            promotion_code: data.get('promotion_code'),
            promotion_amount: data.get('promotion_amount')
        }

        const error = validate(dataForm, constraints);

        if (!error) {
            handleUpdate(token, id, dataForm)
        } else {
            console.log('error', error);
            showMessage('error', "One or more input fields are invalid!");
        }

        setFormError(error);
    }

    // Custom validator to ensure end_time is after start_time
    validate.validators.timeAfter = function (value, options, key, attributes) {
        const startDate = dayjs(attributes.start_date, 'YYYY-MM-DD');
        const endDate = dayjs(value, 'YYYY-MM-DD');;
        if (endDate.isBefore(startDate)) {
            return options.message || "must be after start time";
        }
    };

    const constraints = {
        promotion_code: {
            presence: true,
        },
        start_date: {
        },
        end_date: {
            timeAfter: {
                message: "must be after start time"
            }
        },
        promotion_amount: {
            presence: true,
        }
    };

    useEffect(() => {
        handleFindOne();
    }, [])


    if (!defaultValue) {
        return (
            <PageLoading />
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <NavHeader
                title="Update Promotion"
                breadcrumb={[
                    {
                        title: 'Promotions',
                        link: '/admin/promotions/'
                    },
                    {
                        title: 'Update Promotion'
                    }
                ]}
                backLink="/admin/promotions"
            />
            <Box  component="form" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={8}>
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.promotion_code} required>
                            <FormLabel>Promotion code</FormLabel>
                            <TextField
                                name="promotion_code"
                                type="text"
                                error={formError?.promotion_code}
                                defaultValue={defaultValue?.promotion_code}
                            />
                            {formError?.promotion_code ? <FormHelperText>{formError?.promotion_code[0]}</FormHelperText> : ""}
                        </FormControl>
                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.promotion_amount} required>
                            <FormLabel>Discount amount</FormLabel>
                            <TextField
                                name="promotion_amount"
                                type="number"
                                slotProps={{
                                    input: {
                                        startAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    },
                                    }}
                                min={0}
                                error={formError?.promotion_amount}
                                defaultValue={defaultValue?.promotion_amount}
                                
                            />
                            {formError?.promotion_amount ? <FormHelperText>{formError?.promotion_amount[0]}</FormHelperText> : ""}
                        </FormControl>
                        <FormControl sx={{ width: "100%", mb: 3 }}>
                            <FormControlLabel
                                control={<Switch />}
                                label="Use promotion code for all memberships"
                                checked={forAllMemberShip}
                                onChange={() => setForAllMemberShip(!forAllMemberShip)}
                                name="for_all_memberships"
                            />
                        </FormControl>
                        {
                            !forAllMemberShip ? (
                                <Box sx={{ mb: 1 }}>
                                    <PostPicker
                                        label="Select memberships"
                                        name="memberships"
                                        defaultData
                                        getApi={findManyMemberships}
                                        values={membership}
                                        onChange={(value) => {
                                            if (value) {
                                                setMembership((membership) => {
                                                    let filterMemberships = [];
                                                    filterMemberships = membership.filter((_v) => _v?.id !== value.id);
                                                    filterMemberships = [...filterMemberships, value];
                                                    return filterMemberships;
                                                })
                                            }
                                        }}
                                        optionKey="title"
                                    />
                                    <SelectedCell
                                        cellLabel="Selected memberships"
                                        data={membership}
                                        onChange={(value) => setMembership(value)}
                                    />
                                </Box>
                            ): ""
                        }
                        <FormControl sx={{ width: "100%", mb: 3 }}>
                            <FormControlLabel
                                control={<Switch />}
                                label="Does not expire this promotion"
                                checked={doesNotExpired}
                                onChange={() => setDoesNotExpired(!doesNotExpired)}
                                name="does_not_expire"
                            />
                        </FormControl>
                        {
                            !doesNotExpired ? (
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.start_date} required>
                                            <FormLabel>Start date</FormLabel>
                                            <DatePicker
                                                name="start_date"
                                                value={dayjs(defaultValue?.start_date, "YYYY-MM-DD")}
                                            />
                                            {formError?.start_date ? <FormHelperText>{formError?.start_date[0]}</FormHelperText> : ""}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: "100%", mb: 3 }} error={formError?.end_date} required>
                                            <FormLabel>End date</FormLabel>
                                            <DatePicker
                                                name="end_date"
                                                value={dayjs(defaultValue?.end_date, "YYYY-MM-DD")}
                                            />
                                            {formError?.end_date ? <FormHelperText>{formError?.end_date[0]}</FormHelperText> : ""}
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            ) : ""
                        }
                        <FormControl>
                            <LoadingButton
                                variant='contained'
                                color='primary'
                                size='lg'
                                type='submit'
                                loading={isLoading}>
                                Update promotion
                            </LoadingButton>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default UpdatePromotionPage
