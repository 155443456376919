import { instance } from "./axiosInstance";

export async function getSessionAnalytics(token, params = {}) {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: params
    };
    const response = await instance
        .get("/analytics/session-analytics", config)
        .then((res) => res.data)
        .catch(() => {
            return [];
        });
    return response;
}

export async function getClassSessionAnalytics(token, params = {}) {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: params
    };
    const response = await instance
        .get("/analytics/class-sessions", config)
        .then((res) => res.data)
        .catch(() => {
            return [];
        });
    return response;
}

export async function getTrainerAnalytics(token, params = {}) {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: params
    };
    const response = await instance
        .get("/analytics/trainers", config)
        .then((res) => res.data)
        .catch(() => {
            return [];
        });
    return response;
}

export async function getOrderAnalytics(token, params = {}) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: params
    };
    const response = await instance
        .get("/analytics/order-analytics", config)
        .then((res) => res.data)
        .catch((err) => {
            return { 
                error: true,
                data: err
            };
        });
    return response;
}

export async function getOrderByMonth(token) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    };
    const response = await instance
        .get("/analytics/orders/month", config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}

export async function deleteanalytics(token, data) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
        data: data
    };
    const response = await instance
        .delete("/analytics", config)
        .then((res) => {
            return res?.data;
        }).catch(function () {
            return null;
        });
    return response;
}

export async function updateClass(token, id, data) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    };
    const response = await instance
        .patch("/analytics/" + id, data, config)
        .then((res) => res.data)
        .catch((err) => {
            return {
                error: true,
                data: err
            };
        });
    return response;
}

export async function deleteClass(token, id) {
    if (!token) {
        return null;
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    const response = await instance
        .delete("/analytics/" + id, config)
        .then((res) => {
            return res?.data;
        }).catch(function () {
            return null;
        });
    return response;
}