import { Box, Typography, TableCell, Stack, Paper, TableContainer, Table, TableBody, TableRow, Avatar, TablePagination, TableHead, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { updateSession, findOneSession, TrainerCheckInUser, TrainerCheckOutUser } from '@/utils/TrainerSessions'
import { useSelector } from 'react-redux'
import { useToasterContext } from '@/utils/ToasterContext'
import { Link, useNavigate, useParams } from 'react-router-dom';

import validate from 'validate.js'
import PageLoading from '@/components/PageLoading'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import NavHeader from '@/components/NavHeader'
import { createUserSessions } from '@/utils/UserInfomations'
import { LoadingButton } from '@mui/lab'

dayjs.extend(customParseFormat);

const APP_URL = process.env.REACT_APP_APP_URL;

const CheckinSession = () => {

    const { token } = useSelector((state) => state.auth);
    const { id } = useParams();

    const { showMessage, startProgress, completeProgress } = useToasterContext();
    const [isLoading, setIsLoading] = useState(false);

    const [defaultValue, setDefaultValue] = useState(false);
    const [trainer, setTrainer] = useState([]);
    const [AcademicClass, setClass] = useState([]);
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [formError, setFormError] = useState(false);
    const [filter, setFilter] = useState({
        search: "",
        order: "desc",
        paged: 1,
        per_page: 12,
        start_date: null,
        end_date: null
    })

    const navigate = useNavigate();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function handleBookSession(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const formData = {
            subscription_id: data.get('subscription_id'),
            session_id: id
        }
        const error = validate(formData, createSessionConstraint);
        if (!error) {
            try {
                setIsLoading(true)
                const response = await createUserSessions(token, formData);
                if (response && !response.error) {
                    const { id } = response;
                    showMessage('success', 'Data created successfully!');
                    if (id) {
                        navigate("/account/sessions/" + id);
                    }
                } else {
                    showMessage('error', 'Data created successfully!');
                }
            } catch (error) {
                console.error('Error: ', error);
                showMessage('error', 'Data created successfully!');
            } finally {
                setIsLoading(false);
            }
        }
        setFormError(error);
    }

    async function handleFindOneSession() {
        try {
            startProgress();
            const response = await findOneSession(token, id);
            if (response && !response.error) {
                const { data } = response;
                if (data) {
                    const {
                        title,
                        description,
                        capacity,
                        trainer,
                        start_time,
                        end_time,
                        users
                    } = data;
                    const academicClass = data?.class;
                    setClass(academicClass);
                    setTrainer(trainer);
                    setUsers(users);
                    setDefaultValue({
                        title: title,
                        description: description,
                        capacity: capacity,
                        start_time: start_time,
                        end_time: end_time,
                        class: academicClass
                    });
                }
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            completeProgress();
        }
    }

    const handleCheckInUser = async (id) => {
        try {
            setIsLoading(true);
            const response = await TrainerCheckInUser(token, id);
            if (response && !response.error) {
                showMessage('success', 'Successfully checkin the session!');
                handleFindOneSession();
            }
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    }

    const handleCheckOutUser = async (id) => {
        try {
            setIsLoading(true);
            const response = await TrainerCheckOutUser(token, id);
            if (response && !response.error) {
                showMessage('success', 'Successfully checkout the session!');
                handleFindOneSession();
            }
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (token) {
            handleFindOneSession();
        }
    }, [token])


    const createSessionConstraint = {
        subscription_id: {
            presence: true,
        }
    }


    if (!defaultValue) {
        return (
            <PageLoading />
        );
    }

    const headCells = [
        {
            id: 'user_info',
            numeric: false,
            disablePadding: false,
            label: 'User Info',
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: false,
            label: 'Email',
        },
        {
            id: 'phone_number',
            numeric: false,
            disablePadding: false,
            label: 'Phone number',
        },
        {
            id: 'check_in',
            numeric: false,
            disablePadding: false,
            label: 'Check in',
        },
        {
            id: 'check_out',
            numeric: false,
            disablePadding: false,
            label: 'Check out',
        },
        {
            id: 'action',
            numeric: false,
            disablePadding: false,
            label: 'Action',
        },
    ];

    function EnhancedTableHead(props) {
        const { order, orderBy } = props;
        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <Typography sx={{ fontWeight: 600 }}>
                                {headCell.label}
                            </Typography>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    return (
            <Box sx={{ width: '100%' }}>
                <NavHeader
                    title="Session checkin"
                    breadcrumb={[
                        {
                            title: 'Session checkin'
                        }
                    ]}
                    backLink={`/trainer/sessions/${id}`}
                />
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size='medium'>
                            <EnhancedTableHead
                                rowCount={users?.length}
                            />
                            <TableBody>
                                {users.map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    const pivot = row?.pivot;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row?.id}
                                            sx={{ cursor: 'pointer' }}>
                                            <TableCell>
                                                <Link to={"/users/" + row?.id}>
                                                    <Stack
                                                        direction="row"
                                                        spacing={2}
                                                        alignItems="center">
                                                        <Avatar src={row?.avatar} />
                                                        <Typography>
                                                            {row?.display_name}
                                                        </Typography>
                                                    </Stack>
                                                </Link>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    {row?.email || "-"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <a href={`tel:${row?.phone}`}>
                                                    <Typography>
                                                        {row?.phone || "-"}
                                                    </Typography>
                                                </a>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    {pivot?.check_in_time || "-"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    {pivot?.check_out_time || "-"}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Stack direction="row" spacing={1}>
                                                    <LoadingButton
                                                        disabled={pivot?.check_in_time}
                                                        loading={isLoading == `check_in_${row.id}`}
                                                        onClick={() => {
                                                            if (pivot?.check_in_time) {
                                                                'This user has checked out!'
                                                            } else {
                                                                handleCheckInUser(pivot.id)
                                                            }
                                                        }} variant='contained' color="secondary">
                                                        Checkin
                                                    </LoadingButton>
                                                    <LoadingButton
                                                        disabled={pivot?.check_out_time}
                                                        loading={isLoading == `check_out_${row.id}`}
                                                        onClick={() => {
                                                            if (pivot?.check_out_time) {
                                                                showMessage('error', 'This user has checked out!')
                                                            } else {
                                                                handleCheckOutUser(pivot.id)
                                                            }
                                                        }} variant='outlined' color="secondary">
                                                        CheckOut
                                                    </LoadingButton>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
    )
}

export default CheckinSession